/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UnespCoreUsuario} from '../../interfaces';
import {UnespCoreEnvironment} from '../../interfaces';

/**
 * @description
 *
 * Serviço responsável por manter e disponibilizar o usuário para uso na aplicação
 *
 */
@Injectable({
  providedIn: 'root'
})
export class UnespCoreUserService {
  constructor(
    @Inject('environment') protected environment: UnespCoreEnvironment
  ) {

    this.userKey = this.getLocalStoragePrefix() + '_user';
  }

  readonly userKey: string;
  private user: UnespCoreUsuario | null = null;
  private _user$ = new BehaviorSubject<UnespCoreUsuario | null>(null);

  /**
   * Obtém o usuário da memória ou local storage
   */
  getUser(): UnespCoreUsuario | null {
    if (!this.user) {
      this.user = this.getUserLocalStorage();
      this._user$.next(this.user);
    }
    return this.user;
  }

  getLocalStoragePrefix(): string {
    if (!this.environment.localStoragePrefix) {
      console.error('Atenção desenvolvedor: Definir variável de ambiente localStoragePrefix (Angular Unesp)');
    }

    return this.environment.localStoragePrefix ?? 'app';
  }

  get user$(): Observable<UnespCoreUsuario | null> {
    return this._user$;
  }

  setUser(usuario: UnespCoreUsuario) {
    this.user = usuario;
    this._user$.next(this.user);
    this.setUserLocalStorage(this.user);
  }

  /**
   * Obtém o usuário diretamente do local storage.
   * Recomendamos utilizar o getUser() para obter usuário diretamente da memória quando possível
   */
  protected getUserLocalStorage(): UnespCoreUsuario | null {
    const usuario = localStorage.getItem(this.userKey);

    if (usuario) {
      this.user = JSON.parse(usuario);
      return this.user;
    }

    return null;
  }

  /**
   * Armazena o usuário no local storage do browser
   */
  protected setUserLocalStorage(usuario: UnespCoreUsuario): void {
    localStorage.setItem(this.userKey, JSON.stringify(usuario));
  }

  /**
   * Remove o usuário do local storage do browser
   */
  removeUser(): void {
    this.user = null;
    this._user$.next(null);
    localStorage.removeItem(this.userKey);
  }

}
