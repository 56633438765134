<h2 mat-dialog-title>{{ data.tipoConcurso }}</h2>
<hr style="border: 0; border-top: solid 1px #0000003f" />
<mat-dialog-content class="mat-typography">
  <!-- <app-formulario-complementar-input-grupo
    *ngFor="let g of mapGrupos | keyvalue"
      [grupo]="g.key" [formularios]="g.value">
  </app-formulario-complementar-input-grupo> -->
  <div class="list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="box" *ngFor="let formulario of formularios" cdkDrag>
      <div class="custom-placeholder" *cdkDragPlaceholder></div>
      {{ formulario.grupo }}<br />{{ formulario.explicacao }}
      <span
        ><b>{{ etapaTexto(formulario.etapa) }}</b
        ><br />{{ formulario.requerido ? 'Obrigatório' : 'Opcional' }}<br />{{
          tipoComplementoTexto(formulario.tipoComplemento)
        }}</span
      >
    </div>
  </div>
</mat-dialog-content>
<div
  mat-dialog-actions
  fxLayoutAlign.gt-md="space-between center"
  fxLayoutAlign.lt-md="center stretch"
  fxLayout.lt-md="column"
  fxLayoutGap="20px">
  <button mat-raised-button color="primary" (click)="salvar()"><mat-icon>save</mat-icon> Salvar</button>
  <button mat-button mat-dialog-close>Fechar</button>
</div>
