<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign="space-between center">
    <h1>Status da Documentação de Inscrição</h1>

    <div>
      <button mat-raised-button color="primary" (click)="verificaQtdRegistrosParaExportar()">
        <mat-icon>save_alt</mat-icon> Exportar
      </button>
    </div>
  </div>
  <div>
    <button mat-raised-button color="secundary" (click)="downloadAnexo()">
      <mat-icon>download</mat-icon> Baixar Edital
    </button>
  </div>
  <h1>{{ this.idConcurso + '-' + this.campus }}</h1>
  <h1>{{ this.tituloConcurso }}</h1>

  <mat-form-field appearance="outline" fxLayoutAlign="start start">
    <mat-label>Filtrar Pagamento</mat-label>
    <mat-select name="pago" [(value)]="filtroPago" (selectionChange)="getData()">
      <mat-option value="TODOS">Todos</mat-option>
      <mat-option value="PAGO">Pago</mat-option>
      <mat-option value="AGUARDANDO_PAGAMENTO">Aguardando Pagamento</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="inscricao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.idInscricao }}</td>
      </ng-container>

      <ng-container matColumnDef="candidato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidato</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.nome }}</p>
          <p><b>CPF</b> {{ row.cpf }}</p>
          <p><b>RG</b> {{ row.rg }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="contato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contato</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.email }}</p>
          <p>{{ row.celular }}</p>
          <p>{{ row.telefone }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="statusFase1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Documentos Pendentes 1ª Fase</th>
        <td mat-cell *matCellDef="let row">
          <p>
            <b>Situação:{{ row.situacaoFase1 }}</b>
          </p>
          <p>{{ row.totalObrigatorioPendente }} Obrigatórios</p>
          <p>{{ row.totalOpcionalNaoEnviado }} Opcionais</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="statusFase2" *ngIf="mostrarColuna">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Documentos Pendentes 2ª Fase</th>
        <td mat-cell *matCellDef="let row">
          <p>
            <b>Situação:{{ row.situacaoFase2 }} </b>
          </p>
          <p>{{ row.totalObrigatorioPendenteSegundaFase }} Obrigatórios</p>
          <p>{{ row.totalOpcionalNaoEnviadoFase2 }} Opcionais</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="pago">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pago</th>
        <td mat-cell *matCellDef="let row">
          <p>Pago: {{ row.pago ? 'Sim' : 'Aguardando Pagamento' }}</p>
          <p>Autor: {{ row.pagoUsuario }}</p>
          <p>Data: {{ row.pagoData | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
          <p>Concluído: {{ row.pagoConcluida ? 'Sim' : 'Não' }}</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <ng-container *ngIf="mostraColunaSegundaFase(this.tipoConcurso); then colspanIgual; else alteraColspan">
      </ng-container>
      <ng-template #colspanIgual>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">
            <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
            <span *ngIf="!input.value">Nenhum registro disponível</span>
          </td>
        </tr>
      </ng-template>
      <ng-template #alteraColspan>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">
            <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
            <span *ngIf="!input.value">Nenhum registro disponível</span>
          </td>
        </tr>
      </ng-template>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
