/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InscricaoReducaoService } from 'src/app/services/inscricao-reducao.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute } from '@angular/router'
import { InscricaoReducao } from 'src/app/models/inscricao-reducao'
import { PermitirAlteracaoService } from 'src/app/services/permitir-alteracao.service'
import { ArquivoSelecionado, arquivoUploadValidator } from '../../arquivo-upload/arquivo-upload.component'

@Component({
  selector: 'app-inscricao-reducao',
  templateUrl: './inscricao-reducao.component.html',
  styleUrls: ['./inscricao-reducao.component.scss'],
})
export class InscricaoReducaoComponent implements OnInit {
  isPermitirAlteracao: boolean
  isAtualizado: boolean
  id?: number
  inscricaoReducao?: InscricaoReducao

  anexoEstudanteFormControl: FormControl<ArquivoSelecionado> = new FormControl()
  anexoRendaFormControl: FormControl<ArquivoSelecionado> = new FormControl()

  form = new FormGroup({
    reducaoAprovado: new FormControl('', Validators.required),
    reducaoMotivo: new FormControl(''),
  })

  constructor(
    private inscricaoReducaoService: InscricaoReducaoService,
    private permitirAlteracaoService: PermitirAlteracaoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute
  ) {
    this.isPermitirAlteracao = this.route.snapshot.url[0].toString() == 'permitir-alteracao'
    this.isAtualizado = false
  }

  ngOnInit(): void {
    const anexoEstudante: ArquivoSelecionado = new ArquivoSelecionado()
    this.anexoEstudanteFormControl.setValue(anexoEstudante)
    const anexoRenda: ArquivoSelecionado = new ArquivoSelecionado()
    this.anexoRendaFormControl.setValue(anexoRenda)

    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.inscricaoReducaoService.get(this.id).subscribe(obj => {
          this.inscricaoReducao = obj
          if (obj.reducaoAprovado !== null) this.form.get('reducaoAprovado')?.setValue(obj.reducaoAprovado.toString())
          this.form.get('reducaoMotivo')?.setValue(obj.reducaoMotivo)
          this.isAtualizado = true

          anexoEstudante.setNameAndSize(
            obj.arquivoComprovanteReducaoEstudante,
            obj.arquivoComprovanteReducaoEstudanteTamanho
          )
          this.anexoEstudanteFormControl.setValue(anexoEstudante)
          this.anexoEstudanteFormControl.addValidators(arquivoUploadValidator(false))

          anexoRenda.setNameAndSize(obj.arquivoComprovanteReducaoRenda, obj.arquivoComprovanteReducaoRendaTamanho)
          this.anexoRendaFormControl.setValue(anexoRenda)
          this.anexoRendaFormControl.addValidators(arquivoUploadValidator(false))

          this.form
            .get('reducaoAprovado')
            ?.valueChanges.subscribe(
              value =>
                (this.isAtualizado =
                  (this.inscricaoReducao?.reducaoAprovado === null ||
                    this.inscricaoReducao?.reducaoAprovado.toString() == value) &&
                  this.inscricaoReducao?.reducaoMotivo == this.form.get('reducaoMotivo')?.value)
            )

          this.form
            .get('reducaoMotivo')
            ?.valueChanges.subscribe(
              value =>
                (this.isAtualizado =
                  this.inscricaoReducao?.reducaoMotivo == value &&
                  this.inscricaoReducao?.reducaoAprovado.toString() == this.form.get('reducaoAprovado')?.value)
            )

          if (this.inscricaoReducao.reducaoConcluida || this.isPermitirAlteracao) {
            this.anexoEstudanteFormControl.disable()
            this.anexoRendaFormControl.disable()
            this.form.disable()
          }
        })
      }
    })
  }

  gravar(): void {
    if (this.id && !this.inscricaoReducao?.reducaoConcluida) {
      let opcao = this.form.controls['reducaoAprovado'].value == 'true' ? true : false
      let formulario = <InscricaoReducao>this.form.value
      if (opcao) {
        this.inscricaoReducaoService.confirmar(this.id, formulario).subscribe(inscricaoReducao => {
          this.inscricaoReducao = inscricaoReducao
          this.unespCoreMessageService.showMessageSuccess(`Redução #${this.id} confirmado com sucesso`)
          this.updateIsAtualizado()
        })
      } else {
        if (formulario.reducaoMotivo == null || formulario.reducaoMotivo == '') {
          this.unespCoreMessageService.showMessageError(`É preciso informar o Motivo`)
        } else {
          this.inscricaoReducaoService.cancelar(this.id, formulario).subscribe(inscricaoReducao => {
            this.inscricaoReducao = inscricaoReducao
            this.unespCoreMessageService.showMessageSuccess(`Redução #${this.id} cancelado com sucesso`)
            this.updateIsAtualizado()
          })
        }
      }
    }
  }

  private updateIsAtualizado() {
    this.isAtualizado =
      this.inscricaoReducao?.reducaoAprovado.toString() == this.form.get('reducaoAprovado')?.value &&
      this.inscricaoReducao?.reducaoMotivo == this.form.get('reducaoMotivo')?.value
  }

  concluir(): void {
    if (this.id && !this.inscricaoReducao?.reducaoConcluida) {
      this.inscricaoReducaoService.concluir(this.id).subscribe(inscricaoReducao => {
        this.inscricaoReducao = inscricaoReducao
        this.unespCoreMessageService.showMessageSuccess(`Redução #${this.id} concluído com sucesso`)
        this.anexoEstudanteFormControl.disable()
        this.anexoRendaFormControl.disable()
        this.form.disable()
      })
    }
  }

  permitirAlteracao() {
    if (this.id && this.isPermitirAlteracao && this.inscricaoReducao) {
      this.permitirAlteracaoService.liberarRegistro('reducao', this.id).subscribe(registro => {
        this.inscricaoReducao!.reducaoConcluida = registro.reducao.concluido
        this.unespCoreMessageService.showMessageSuccess(`Redução #${this.id} revertido para não concluído`)
      })
    }
  }
}
