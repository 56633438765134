/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { environment } from '../environments/environment'
import { SwUpdate } from '@angular/service-worker'
import { Data, RouterOutlet } from '@angular/router'
import { routeAnimations } from 'src/libs/unesp-core'
import packageJson from '../../package.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  version: string = packageJson.version
  env = environment
  fullTitle = `Sistema de Inscrição em Concurso v${this.version}`
  shortTitle = `SINSC v${this.version}`
  footerText = 'Coordenadoria de Gestão de Pessoas'

  constructor(private swUpdate: SwUpdate) {}

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('Nova versão do sistema disponível. Deseja atualizar?')) {
          window.location.reload()
        }
      })
    }
    if (this.env.production == false) {
      this.footerText = this.env.name
    }
  }

  prepareRoute(outlet: RouterOutlet): Data {
    return outlet && outlet.activatedRouteData
  }
}
