/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { MatSidenav } from '@angular/material/sidenav';
import {
  ROLE_WILDCARD_PUBLIC,
  ROLE_WILDCARD_ONLYUSERS,
} from '../../interfaces';
import { Inject, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UnespCoreMenuItem } from '../../interfaces';
import { UnespCoreEnvironment } from '../../interfaces';
import { UnespCoreUserService } from '../unesp-core-user';

/**
 * @description
 *
 * Serviço responsável pelo controle do menu lateral da aplicação, providenciando os métodos
 * de carregamento das opções de menu, abertura, fechamento e refresh.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class UnespCoreMenuNavigationService {
  public appDrawer: MatSidenav | undefined;
  public appDrawerEnabled: boolean = false;
  public publicMenu: boolean = false;
  public currentUrl = new BehaviorSubject<string>('');

  public processedMenu: UnespCoreMenuItem[] = [];
  public itensMenuSource = new BehaviorSubject(this.processedMenu);
  public currentItensMenu = this.itensMenuSource.asObservable();

  constructor(
    protected router: Router,
    protected unespCoreUserService: UnespCoreUserService,
    @Inject('environment') protected environment: UnespCoreEnvironment,
    @Inject('menu') protected envMenu: UnespCoreMenuItem[]
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });

    this.refreshMenu();
  }

  /**
   * Fecha o menu lateral da aplicação
   */
  public closeNav(): void {
    this.appDrawer?.close();
  }

  /**
   * Abre o menu lateral da aplicação
   */
  public openNav(): void {
    this.appDrawer?.open();
  }

  /**
   * Abre/fecha o menu lateral da aplicação
   */
  public toggle(): void {
    this.appDrawer?.toggle();
  }

  /**
   * Atualiza itens do menu baseado nas roles do usuário
   */
  refreshMenu(): void {
    this.itensMenuSource.next(this.loadMenu());
  }

  /**
   * Carrega itens do menu baseado nas roles do usuário
   * @returns
   */
  protected loadMenu(): UnespCoreMenuItem[] {
    this.processedMenu = [];

    // Usuario logado
    const usuario = this.unespCoreUserService.getUser();

    // Remove menu raiz que não possuem roles
    this.envMenu = this.envMenu.filter((item) => item.roles.length > 0);

    if(this.publicMenu) {
      // Adiciona todas entradas públicas
      this.envMenu.forEach((item) => {
        if (item.roles.includes(ROLE_WILDCARD_PUBLIC)) {
          item.roles = [ROLE_WILDCARD_PUBLIC];
          this.normalizeRolesPublicItem(item);
          this.processedMenu.push(item);
        }
      });
    }

    if (usuario) {
      // Roles do usuário
      const userRoles = usuario.roles;

      // Habilita o menu para usuários
      this.appDrawerEnabled = true;

      this.envMenu.forEach((item) => {
        // Adiciona todas entradas livres
        if (item.roles.includes(ROLE_WILDCARD_ONLYUSERS)) {
          item.roles = [ROLE_WILDCARD_ONLYUSERS];
          this.normalizeRolesUnrestrictedItem(item);
          this.processedMenu.push(item);
        } else {
          if (
            !item.roles.includes(ROLE_WILDCARD_PUBLIC) &&
            !item.roles.includes(ROLE_WILDCARD_ONLYUSERS)
          ) {
            // Adiciona itens (apenas raizes) que possuem roles
            if (item.roles.some((role) => userRoles.includes(role))) {
              this.processedMenu.push(item);

              // Normaliza roles para os filhos
              this.normalizeRolesRestrictedItem(item, item.roles, true);
            }
          }
        }
      });

      // Varre o menu inteiro para:
      // - agrupar itens que são considerados duplicados
      // - remover itens que não possuem roles do usuário
      this.groupAndDeleteMenuItems(this.processedMenu, userRoles);

    } else {
      // Habilita o menu para o público (sem usuário logado)
      this.appDrawerEnabled = this.publicMenu;
    }

    // Order the menu.
    this.sortMenuItems(this.processedMenu);

    if(!this.appDrawerEnabled) {
      this.appDrawer?.close();
    } else {

    }

    return this.processedMenu;
  }

  /**
   * Normaliza roles ROLE_WILDCARD_PUBLIC recursivamente na arvore de menus.
   * @param item
   */
  private normalizeRolesPublicItem(item: UnespCoreMenuItem): void {
    // Recursividade para os filhos
    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        this.normalizeRolesPublicItem(child);
      });
    }
    // Regras de normalização
    item.roles = [ROLE_WILDCARD_PUBLIC];
  }

  /**
   * Normaliza roles ROLE_WILDCARD_UNRESTRICTED recursivamente na arvore de menus.
   * @param item
   */
  private normalizeRolesUnrestrictedItem(item: UnespCoreMenuItem): void {
    // Recursividade para os filhos
    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        this.normalizeRolesUnrestrictedItem(child);
      });
    }
    // Regras de normalização
    item.roles = [ROLE_WILDCARD_ONLYUSERS];
  }

  /**
   * Normaliza roles recursivamente na arvore de menus.
   * @param item
   * @param fatherRoles
   * @param isRoot
   */
  private normalizeRolesRestrictedItem(
    item: UnespCoreMenuItem,
    fatherRoles: string[],
    isRoot: boolean
  ): void {
    // Regras de normalização para filhos
    if (!isRoot) {
      // Remove roles que não existem no pai
      item.roles = item.roles.filter((role) => fatherRoles.includes(role));
      // Adiciona todas roles do pai se for vazio.
      if (item.roles.length === 0) {
        item.roles = fatherRoles;
      }
    }
    // Recursividade para os filhos
    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        this.normalizeRolesRestrictedItem(child, item.roles, false);
      });
    }
  }

  /**
   * Irá remover os itens que não possuem alguma role do usuário
   * E irá agrupar itens que são considerados duplicados
   *
   * @param rootRef - Referencia para o menu raiz
   * @param userRoles - Roles do usuário
   */
  private groupAndDeleteMenuItems(
    rootRef: UnespCoreMenuItem[] = [],
    userRoles: string[]
  ) {
    // Itera sobre os itens do menu
    let i = 0;
    for (i = 0; i < rootRef.length; i++) {
      let itemBeingChecked = rootRef[i];

      // Remove item se ele o usuário não possuir roles
      if (
        !itemBeingChecked.roles.includes(ROLE_WILDCARD_ONLYUSERS) &&
        !itemBeingChecked.roles.includes(ROLE_WILDCARD_PUBLIC)
      ) {
        if (!userRoles.some((role) => itemBeingChecked.roles.includes(role))) {
          rootRef.splice(i, 1);
          i--; // Como o item foi removido, sua posição foi assumida pelo próximo item.
          continue;
        }
      }

      // Itera sobre os próximos itens partindo do item anterior
      let j = 0;
      for (j = i + 1; j < rootRef.length; j++) {
        let item = rootRef[j];
        // Se o item sendo checado for igual ao item analisado, remove.
        if (
          itemBeingChecked.displayName === item.displayName &&
          itemBeingChecked.iconName === item.iconName &&
          itemBeingChecked.route === item.route
        ) {
          // Agrupa os itens
          this.mergeMenuItem(itemBeingChecked, item);
          // Remove a referencia duplicada
          rootRef.splice(j, 1);
          // Como o item foi removido, sua posição foi assumida pelo próximo item.
          j--;
        }
      }

      // Se está no final da lista, não irá haver próxima iteração, faz a checagem dos filhos.
      if (j === rootRef.length) {
        this.groupAndDeleteMenuItems(itemBeingChecked.children, userRoles);
      }
    }
  }

  /**
   * Critério e Ação de agrupamento de dois menuItem
   * @param base
   * @param itemToBeMerged
   */
  private mergeMenuItem(
    base: UnespCoreMenuItem,
    itemToBeMerged: UnespCoreMenuItem
  ) {
    // concatena as roles
    base.roles = base.roles.concat(itemToBeMerged.roles);
    base.roles = [...new Set(base.roles)]; // remove roles duplicadas.
    // concatena os filhos
    if (
      base.children &&
      itemToBeMerged.children &&
      itemToBeMerged.children.length > 0
    ) {
      base.children = base.children.concat(itemToBeMerged.children);
    }
  }

  private sortMenuItems(siblings?: UnespCoreMenuItem[]) {
    if(siblings && siblings.length > 1) {
      siblings.sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
        }
        return a.displayName < b.displayName ? -1 : 1;
      });
      siblings.forEach((item) => {
        this.sortMenuItems(item.children);
      });
    }
  }
}
