/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, AfterViewInit } from '@angular/core';
import { UnespCoreAuthService } from '../../services/unesp-core-auth/unesp-core-auth.service';
import { UnespCoreUsuario } from '../../interfaces/unesp-core-usuario';
import { UnespCoreMenuNavigationService } from '../../services/unesp-core-menu-navigation/unesp-core-menu-navigation.service';
import { UnespCoreUserService } from '../../services/unesp-core-user/unesp-core-user.service';
import { Observable } from 'rxjs';

/**
 * @description
 *
 * Este componente representa o cabeçalho da aplicação, disponibilizando o menu, login e logout.
 *
 */
@Component({
  selector: 'unesp-core-header',
  templateUrl: './unesp-core-header.component.html',
  styleUrls: ['./unesp-core-header.component.css'],
})
export class UnespCoreHeaderComponent implements AfterViewInit {
  /**
   * @description
   *
   * Nome completo da aplicação a ser exibido em telas maiores.
   *
   */
  @Input() fullTitle!: string;

  /**
   * @description
   *
   * Nome abreviado da aplicação a ser exibido em telas menores.
   *
   */
  @Input() shortTitle!: string;

  /**
   * @description
   *
   * Exibe username no cabeçalho ao lado do botão logout.
   *
   */
  @Input() usernameOnHeader: boolean = true;

  /**
   * @description
   *
   * Caminho do logotipo que será exibido
   *
   */
  @Input() logo: string = 'assets/img/logotipo.png';


  /**
   * @description
   *
   * Caminho do logotipo customizado que será exibido
   *
   */
   @Input() logoCustom: string | undefined;

  /**
   * @description
   *
   * Define se o botão do menu irá aparecer a todo momento.
   * Mesmo sem um usuário logado.
   * 
   */
   @Input() publicMenu: boolean = true;

   /**
    * @description
    * 
    * Define se adicionará e exibirá as funcionalidades do componente
    * de countdown timer no cabeçalho.
    */
    @Input() showCountdownTimer: boolean = true;

   /**
    * @description
    * 
    * Define se exibirá o botão de login no header
    */
    @Input() loginOnHeader: boolean = false;

   /**
    * @description
    * 
    * Define se exibirá o botão de logout no header
    */
    @Input() logoutOnHeader: boolean = true;

    constructor(
    public unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
    private unespCoreAuthService: UnespCoreAuthService,
    private unespCoreUserService: UnespCoreUserService
  ) {}

  ngAfterViewInit(): void {
    this.unespCoreMenuNavigationService.publicMenu = this.publicMenu;
    this.unespCoreMenuNavigationService.refreshMenu();
  }

  logout(): void {
    this.unespCoreAuthService.logout('Usuário desconectado com sucesso');
    this.unespCoreMenuNavigationService.appDrawer?.close();
  }

  login(): void {
    window.location.href = this.unespCoreAuthService.getLoginUrl();
  }

  get user$(): Observable<UnespCoreUsuario | null> {
    return this.unespCoreUserService.user$;
  }
}
