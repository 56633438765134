/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { TipoRecurso } from './../../../models/tipo-recurso'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TipoRecursoService } from 'src/app/services/tipo-recurso.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { DatePipe } from '@angular/common'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { Observable, map, of, startWith } from 'rxjs'
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips'

interface TipoConcursoOpt {
  key: string
  description: string
}

@Component({
  selector: 'app-tipo-recurso-editar',
  templateUrl: './tipo-recurso-editar.component.html',
  styleUrls: ['./tipo-recurso-editar.component.css'],
})
export class TipoRecursoEditarComponent implements OnInit {
  id?: number

  tipoRecursoFC: FormControl = new FormControl({ value: '', disabled: true })
  prazoFC: FormControl = new FormControl('', Validators.required)
  tipoAndamentoFC: FormControl = new FormControl({ value: '', disabled: true })
  tipoAndamentoValorFC: FormControl = new FormControl('')
  categoriaFC: FormControl = new FormControl('')
  tipoConcursoFC: FormControl = new FormControl('', Validators.required)

  formularioFC: FormGroup = new FormGroup({
    id: new FormControl(''),
    tipoRecurso: this.tipoRecursoFC,
    prazo: this.prazoFC,
    tipoAndamento: this.tipoAndamentoFC,
    tipoAndamentoValor: this.tipoAndamentoValorFC,
    categoria: this.categoriaFC,
    tipoConcurso: this.tipoConcursoFC,
  })

  datepipe: DatePipe = new DatePipe('pt-BR')

  @ViewChild('tipoConcursoChipList') tipoConcursoChipList: MatChipGrid | undefined
  @ViewChild('tipoConcursoInput') tipoConcursoInput: ElementRef | undefined

  todosTiposConcursos: TipoConcursoOpt = { key: 'TODOS', description: 'Nenhum Tipo de Concurso' }
  showTodosTiposConcursosMC: boolean = true
  listaDeConcursos: TipoConcursoOpt[] = []
  tiposDeConcursosSelecionados: TipoConcursoOpt[] = []
  tiposConcursosFiltrados: Observable<TipoConcursoOpt[]> = of(this.listaDeConcursos)

  constructor(
    private tipoRecursoService: TipoRecursoService,
    private route: ActivatedRoute,
    private router: Router,
    private unespCoreMessageService: UnespCoreMessageService
  ) {}

  ngOnInit(): void {
    for (let elemento in TipoConcurso) {
      this.listaDeConcursos.push({
        key: elemento,
        description: (TipoConcurso as any)[elemento],
      })
    }

    this.listaDeConcursos.push(this.todosTiposConcursos)

    this.tiposConcursosFiltrados = this.tipoConcursoFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        const str = typeof value === 'string' ? value : this.addChip(value)
        return str ? this._filtroTipoConcurso(str) : this.listaDeConcursos
      })
    )

    this.tipoConcursoFC.valueChanges.subscribe(
      () => (this.tipoConcursoChipList!.errorState = this.tipoConcursoFC.invalid)
    )

    this.route.params.subscribe(params => {
      this.id = params['id']
      if (this.id) {
        this.tipoRecursoService.editar(this.id).subscribe(obj => {
          // this.formularioFC.setValue(obj) << essa instrução estava apresentado erro, troquei pela de baixo:
          this.formularioFC.controls['id'].setValue(obj.id)
          this.formularioFC.controls['tipoRecurso'].setValue(obj.tipoRecurso)
          this.formularioFC.controls['prazo'].setValue(obj.prazo)
          this.formularioFC.controls['tipoAndamento'].setValue(obj.tipoAndamento)
          this.formularioFC.controls['tipoAndamentoValor'].setValue(obj.tipoAndamentoValor)
          this.formularioFC.controls['categoria'].setValue(obj.categoria)
          this.formularioFC.controls['tipoConcurso'].setValue(obj.tipoConcurso)

          obj.tipoConcurso.split(',').forEach((res: string) => {
            const value = this.listaDeConcursos.find(e => e.key == res.trim())
            if (value) this.addChip(value)
          })
        })
      }
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.formularioFC.controls) {
      if (this.formularioFC.get(name)?.invalid) {
        this.formularioFC.get(name)?.markAsTouched()
      }
    }
  }

  salvar(): void {
    this.applyTouchedToInvalidFields()

    if (!this.formularioFC.valid || !this.tiposDeConcursosSelecionados.length) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    let campos: TipoRecurso = {
      id: this.id,
      tipoRecurso: this.tipoRecursoFC.value,
      categoria: this.categoriaFC.value,
      prazo: this.prazoFC.value,
      tipoAndamento: this.tipoAndamentoFC.value,
      tipoAndamentoValor: this.tipoAndamentoValorFC.value,
      tipoConcurso: this.tiposDeConcursosSelecionados
        .map(tipConc => {
          return tipConc.key
        })
        .join(','),
    }

    this.tipoRecursoService.alteraDadosTipoRecurso(this.id!, campos).subscribe(obj => {
      this.unespCoreMessageService.showMessageSuccess(`Tipo de Recurso atualizado com sucesso`)
      this.router.navigate(['/tipo-recurso'])
    })
  }

  private _filtroTipoConcurso(tipo: string) {
    const filterValue = tipo.toLowerCase()
    return this.listaDeConcursos.filter(
      opt =>
        this.tiposDeConcursosSelecionados.indexOf(opt) == -1 &&
        (opt.key.toLowerCase().includes(filterValue) || opt.description.toLowerCase().includes(filterValue))
    )
  }

  addChip(tipo: TipoConcursoOpt): void {
    if (tipo === this.todosTiposConcursos) this.tiposDeConcursosSelecionados = []
    else if (this.tiposDeConcursosSelecionados.indexOf(tipo) == -1) this.tiposDeConcursosSelecionados.push(tipo)
  }

  removeChip(tipo: TipoConcursoOpt): void {
    const index = this.tiposDeConcursosSelecionados.indexOf(tipo)
    if (index >= 0) this.tiposDeConcursosSelecionados.splice(index, 1)
    if (tipo === this.todosTiposConcursos) this.tipoConcursoInput?.nativeElement.focus()
  }

  focusChip(): void {
    this.showTodosTiposConcursosMC = false
  }

  blurChip(event: MatChipInputEvent): void {
    event.chipInput!.clear()
    this.showTodosTiposConcursosMC = true
  }
}
