<div fxLayout="column" fxLayoutGap="20px">
  <h2>Perfis do usuário: {{ identificacao }}</h2>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" (keyup)="search($event)" placeholder="" #input />
  </mat-form-field>

  <div>
    <table mat-table #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let usuario">{{ usuario.nome }}</td>
      </ng-container>
      <ng-container matColumnDef="descricao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
        <td mat-cell *matCellDef="let usuario">{{ usuario.descricao }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
