/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { Configuracao } from 'src/app/models/configuracao'
import { ConfiguracaoService } from 'src/app/services/configuracao.service'
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes'
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips'

@Component({
  selector: 'app-configuracao-editar',
  templateUrl: './configuracao-editar.component.html',
  styleUrls: ['./configuracao-editar.component.scss'],
})
export class ConfiguracaoEditarComponent implements OnInit {
  readonly chipsSeparatorKeys = [ENTER, COMMA, SPACE] as const
  chipsValor: string[] = []
  usarChips: boolean = false
  chipPlaceHolder: string = ''
  erroChipsMsg: string = ''

  @ViewChild('valorChipList') valorChipList: MatChipGrid | undefined

  id?: number
  configuracao?: Configuracao
  valorFC: FormControl = new FormControl('')
  valorChipListFC: FormControl = new FormControl('')

  constructor(
    private configuracaoService: ConfiguracaoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      if (this.id) {
        this.configuracaoService.get(this.id).subscribe(obj => {
          this.configuracao = obj
          this.chipsSetUp(obj.chave)
          if (!this.usarChips) this.valorFC.setValue(obj.valor)
          else
            obj.valor.split(',').forEach(res => {
              const value = res.trim()
              if (value) this.chipsValor.push(value)
            })
        })
      }
    })
  }

  salvar(): void {
    if (!this.configuracao) return

    if (this.usarChips) {
      if (this.valorChipListFC.invalid) return
      this.configuracao.valor = this.chipsValor.join(',')
    } else {
      this.configuracao.valor = this.valorFC.value
    }

    this.configuracaoService.atualizar(this.configuracao).subscribe(obj => {
      if (obj.id) {
        this.configuracao = obj
        this.unespCoreMessageService.showMessageSuccess(`Chave #${this.id} atualizada com sucesso`)
        this.router.navigate(['/configuracao'])
      }
    })
  }

  addChip(event: MatChipInputEvent): void {
    if (this.valorChipListFC.invalid) {
      this.valorChipList!.errorState = true
      return
    }

    const value = (event.value || '').trim()
    if (value) this.chipsValor.push(value)

    event.chipInput!.clear()
  }

  removeChip(resp: string): void {
    const index = this.chipsValor.indexOf(resp)
    if (index >= 0) this.chipsValor.splice(index, 1)
  }

  chipsSetUp(chave: string): void {
    if (chave == 'CPF_RESTRITO') {
      this.usarChips = true
      this.valorChipListFC.setValidators([Validators.pattern(/^\d{11}$/)])
      this.chipPlaceHolder = 'Adicionar CPF'
      this.erroChipsMsg = 'Apenas os 11 digitos do CPF'
    } else if (chave == 'RG_RESTRITO') {
      this.usarChips = true
      this.chipPlaceHolder = 'Adicionar RG'
    } else return

    //Remove errorState do mat-chip quando a input atende o formato
    this.valorChipListFC.valueChanges.subscribe(() =>
      !this.valorChipListFC.invalid ? (this.valorChipList!.errorState = false) : null
    )
  }
}
