/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { DatePipe } from '@angular/common'
import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component'
import { Subscription } from 'rxjs'
import { InscricaoNota } from 'src/app/models/inscricao-nota'
import { InscricaoNotaCriterio } from 'src/app/models/inscricao-nota-criterio'
import { ProvaCriterio } from 'src/app/models/prova-criterio'
import { InscricaoNotasService } from 'src/app/services/inscricao-notas.service'
import { SalvarNotasDialogComponent, SalvarNotasDialogModel } from './salvar-notas-dialog/salvar-notas-dialog.component'

export interface TabelaNotasCriterios {
  criterios: Map<number, ProvaCriterio>
  linhasTabela: LinhaTabelaNotas[]
}

export interface LinhaTabelaNotas {
  id: number
  tipo: 'NOTA' | 'GRUPO'
  titulo: string
  notaMaxima: string | number | null
  idx?: number
}

@Component({
  selector: 'app-digitar-notas-candidato',
  templateUrl: './digitar-notas-candidato.component.html',
  styleUrls: ['./digitar-notas-candidato.component.css'],
})
export class DigitarNotasCandidatoComponent implements OnChanges {
  @Input() idConcurso?: number
  @Input() tipoProva?: string
  @Input() provaConcluida: boolean = false
  @Input() formatoNota: string = '1.2-2-floor'
  @Input() tipoCalculo?: string
  @Input() tabelaNotas?: TabelaNotasCriterios
  @Input() inscricoesIds?: number[]
  @Input() inscricaoIdx?: number
  @Output() notaChange = new EventEmitter<InscricaoNota>()
  @Output() retornar = new EventEmitter<null>()
  @Output() alteracoesPendentes = new EventEmitter<boolean>()

  candidato?: string
  primeiroCandidato: boolean = true
  ultimoCandidato: boolean = false
  selecionadoIdx: number = 0
  salvando: boolean = false

  notasMaximas: string[] = []

  notasForm = this.fb.group(
    {
      nota1: this.fb.array([]),
      nota2: this.fb.array([]),
      nota3: this.fb.array([]),
    },
    { updateOn: 'blur' }
  )

  idToIdxMap = new Map<number, number>()

  formSubscription?: Subscription

  totalNotaMax: number | null = null
  totalNota1: number | null = null
  totalNota2: number | null = null
  totalNota3: number | null = null

  tNota1Inicial?: number | null = null
  tNota2Inicial?: number | null = null
  tNota3Inicial?: number | null = null

  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(
    private unespCoreMessageService: UnespCoreMessageService,
    private inscricaoNotasService: InscricaoNotasService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  get nota1() {
    return this.notasForm.controls['nota1'] as FormArray<FormControl>
  }
  get nota2() {
    return this.notasForm.controls['nota2'] as FormArray<FormControl>
  }
  get nota3() {
    return this.notasForm.controls['nota3'] as FormArray<FormControl>
  }

  private limparNotasForm() {
    this.nota1.clear()
    this.nota2.clear()
    this.nota3.clear()
  }

  startFormSubscription() {
    this.formSubscription = this.notasForm.valueChanges.subscribe(value => {
      this.totalNota1 = this.calcNotaTotal(value.nota1 as string[])
      this.totalNota2 = this.calcNotaTotal(value.nota2 as string[])
      this.totalNota3 = this.calcNotaTotal(value.nota3 as string[])
      this.alteracoesPendentes.emit(this.haNotasAlteradas())
    })
  }

  notaBlur(event: any) {
    const tabIndex = event.target.tabIndex
    let nota = Number.parseFloat(event.target.value.replace(',', '.'))
    if (!isNaN(nota) && tabIndex) {
      let fc = this.getFcPorTabIndex(tabIndex)
      if (fc) fc.setValue(this.formatarNota(nota))
    }
  }

  notaFocus(event: any) {
    setTimeout(() => event.target.select(), 0)
  }

  private getFcPorTabIndex(index: number): FormControl | null {
    if (index < 2) return null
    let id = (index - 2) % this.idToIdxMap.size
    let coluna = Math.floor((index - 2) / this.idToIdxMap.size)

    switch (coluna) {
      case 0:
        return this.nota1.at(id)
      case 1:
        return this.nota2.at(id)
      case 2:
        return this.nota3.at(id)
      default:
        return null
    }
  }

  stopFormSubscription() {
    this.formSubscription?.unsubscribe()
  }

  ngOnChanges(): void {
    this.initTabelaNotas()

    if (this.idConcurso && this.tipoProva && this.inscricoesIds && this.inscricaoIdx !== undefined) {
      this.selecionadoIdx = this.inscricaoIdx
      this.carregaNotas()
    }
  }

  private formatarNota(nota: number | string | null): string {
    let ret = (nota != null ? Number.parseFloat(nota.toString()).toFixed(4) : '').replace('.', ',')
    return ret.endsWith('00') ? ret.slice(0, -2) : ret.endsWith('0') ? ret.slice(0, -1) : ret
  }

  private initTabelaNotas() {
    this.stopFormSubscription()

    this.limparNotasForm()
    this.notasMaximas = []

    this.idToIdxMap.clear()
    let idx = 0
    this.tabelaNotas?.linhasTabela.forEach(linha => {
      if (linha.tipo == 'NOTA') {
        let nMaxStr = linha.notaMaxima?.toString()
        nMaxStr = nMaxStr ? nMaxStr : ''
        this.notasMaximas.push(nMaxStr)

        const validator = nMaxStr ? notaMaximaValidator(nMaxStr) : undefined

        this.nota1.push(new FormControl('', validator))
        this.nota2.push(new FormControl('', validator))
        this.nota3.push(new FormControl('', validator))

        linha.idx = idx
        this.idToIdxMap.set(linha.id, idx++)
      }
    })

    this.totalNotaMax = this.calcNotaTotal(this.notasMaximas, true)

    if (this.provaConcluida) this.notasForm.disable()
    else this.notasForm.enable()

    this.startFormSubscription()
  }

  private carregaNotas() {
    this.primeiroCandidato = this.selecionadoIdx == 0
    this.ultimoCandidato = this.selecionadoIdx + 1 >= this.inscricoesIds!.length

    this.inscricaoNotasService
      .get(this.idConcurso!, this.tipoProva!, this.inscricoesIds![this.selecionadoIdx])
      .subscribe(nota => {
        this.stopFormSubscription()

        this.nota1.reset()
        this.nota2.reset()
        this.nota3.reset()

        let notas = nota.notasPorCriterio as any

        for (let key in notas) {
          let id: number = Number.parseInt(key)
          let idx = this.idToIdxMap.get(id)
          if (idx !== undefined) {
            this.nota1.at(idx).setValue(this.formatarNota(notas[id][0]))
            this.nota2.at(idx).setValue(this.formatarNota(notas[id][1]))
            this.nota3.at(idx).setValue(this.formatarNota(notas[id][2]))
          }
        }

        this.candidato = nota.nome

        this.totalNota1 = this.calcNotaTotal(this.nota1.value as string[])
        this.totalNota2 = this.calcNotaTotal(this.nota2.value as string[])
        this.totalNota3 = this.calcNotaTotal(this.nota3.value as string[])

        this.setNotasIniciais()

        this.startFormSubscription()
        setTimeout(() => this.setFocus(2), 0)

        this.salvando = false
      })
  }

  private calcNotaTotal(notas: string[], isNotaMaxima?: boolean): number | null {
    let soma = 0
    let semNotas = true

    this.tabelaNotas?.criterios.forEach(criterio => {
      if (criterio.subCriterios.length == 0 && criterio.notaMaxima != null) {
        const idx = this.idToIdxMap.get(criterio.id)
        const fNota = idx !== undefined && notas[idx] !== null ? Number.parseFloat(notas[idx].replace(',', '.')) : NaN
        if (!Number.isNaN(fNota)) {
          semNotas = false
          soma += fNota
        }
      } else if (criterio.subCriterios.length > 0) {
        let somaCriterio = 0

        criterio.subCriterios.forEach(subCriterio => {
          const idx = this.idToIdxMap.get(subCriterio.id)
          const scNota = idx !== undefined ? notas[idx] : null
          const fNota = scNota !== null ? Number.parseFloat(scNota.replace(',', '.')) : NaN
          if (!Number.isNaN(fNota)) {
            semNotas = false
            somaCriterio += fNota
          } else if (isNotaMaxima && criterio.notaMaxima) {
            semNotas = false
            const cMaxNota = Number.parseFloat(criterio.notaMaxima.toString())
            somaCriterio += Number.isNaN(cMaxNota) ? 0 : cMaxNota
          }
        })

        const notaMax = criterio.notaMaxima as number
        soma += criterio.notaMaxima != null && somaCriterio > notaMax ? notaMax : somaCriterio
      }
    })

    if (semNotas) return null

    if (this.tipoCalculo == 'MEDIA_POR_GRUPO') soma /= this.tabelaNotas ? this.tabelaNotas?.criterios.size : 1

    return soma
  }

  anterior() {
    this.alterarCandidato(this.selecionadoIdx - 1)
  }

  proximo() {
    this.alterarCandidato(this.selecionadoIdx + 1)
  }

  private alterarCandidato(idx: number) {
    if (this.haNotasAlteradas()) {
      this.confirmarDescartarAlteracoes().subscribe(descartar => {
        if (descartar) {
          this.selecionadoIdx = idx
          this.carregaNotas()
        }
      })
    } else {
      this.selecionadoIdx = idx
      this.carregaNotas()
    }
  }

  salvar() {
    if (this.salvando) return

    if (this.notasForm.invalid) {
      this.unespCoreMessageService.showMessageError('Preencha todas as notas com um valor valido.')
      return
    }

    let notasCriterios: InscricaoNotaCriterio[] = []

    this.tabelaNotas?.linhasTabela.forEach(linha => {
      if (linha.tipo == 'NOTA') {
        let idx = this.idToIdxMap.get(linha.id)
        let notas: (number | null)[] = [
          this.notaStrToFloat(this.nota1.at(idx!).value as string),
          this.notaStrToFloat(this.nota2.at(idx!).value as string),
          this.notaStrToFloat(this.nota3.at(idx!).value as string),
        ]
        notasCriterios.push({ id: linha.id, notas })
      }
    })

    let temNotaNull = false

    for (let notaCriterio of notasCriterios)
      if (notaCriterio.notas[0] == null || notaCriterio.notas[1] == null || notaCriterio.notas[2] == null)
        temNotaNull = true

    if (temNotaNull) {
      const confirmDialog = new SalvarNotasDialogModel(
        'Atenção',
        ['O preenchimento da planilha do candidato foi concluído?'],
        'Sim',
        'Não, salvar como rascunho'
      )
      const dialogRef = this.dialog.open(SalvarNotasDialogComponent, {
        data: confirmDialog,
      })
      dialogRef.afterClosed().subscribe(completar => {
        if (completar === undefined) return

        if (completar)
          for (let notaCriterio of notasCriterios) {
            notaCriterio.notas[0] ??= 0
            notaCriterio.notas[1] ??= 0
            notaCriterio.notas[2] ??= 0
          }

        this.salvarNotas(notasCriterios)
      })
    } else {
      this.salvarNotas(notasCriterios)
    }
  }

  private salvarNotas(notasCriterios: InscricaoNotaCriterio[]) {
    if (this.salvando) return
    this.salvando = true

    this.inscricaoNotasService
      .salvar(this.idConcurso!, this.tipoProva!, this.inscricoesIds![this.selecionadoIdx], notasCriterios)
      .subscribe(nota => {
        this.notaChange.emit(nota)
        this.unespCoreMessageService.showMessageSuccess(
          `Nota da ${this.tipoProva!} da Inscrição #${nota.idInscricao} atualizada!`
        )
        this.carregaNotas()
        this.salvando = false
      })
  }

  private notaStrToFloat(notaStr: string | null): number | null {
    if (notaStr == null || !notaStr) return null
    const ret = Number.parseFloat(notaStr.replace(',', '.'))
    return Number.isNaN(ret) ? null : ret
  }

  retornarLista() {
    if (this.haNotasAlteradas()) {
      this.confirmarDescartarAlteracoes().subscribe(descartar => {
        if (descartar) this.retornar.emit(null)
      })
    } else this.retornar.emit(null)
  }

  keytab() {
    let focus = document.activeElement ? document.activeElement.getAttribute('tabindex') : null
    if (focus) this.setFocus(Number.parseInt(focus) + 1)
  }

  private setFocus(idx: number) {
    let next = document.querySelector(`[tabindex="${idx}"]`)
    if (next) (next as any).focus()
  }

  private setNotasIniciais() {
    this.tNota1Inicial = this.totalNota1
    this.tNota2Inicial = this.totalNota2
    this.tNota3Inicial = this.totalNota3
    this.alteracoesPendentes.emit(false)
  }

  haNotasAlteradas(): boolean {
    return (
      this.tNota1Inicial != this.totalNota1 ||
      this.tNota2Inicial != this.totalNota2 ||
      this.tNota3Inicial != this.totalNota3
    )
  }

  private confirmarDescartarAlteracoes() {
    const confirmDialog = new ConfirmDialogModel('Atenção', 'Descartar alterações não salvas?')
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    return dialogRef.afterClosed()
  }
}

function notaMaximaValidator(notaMaxima: string): ValidatorFn {
  const max = Number.parseFloat(notaMaxima)

  return (control: AbstractControl): ValidationErrors | null => {
    const nota = control.value

    if (!nota) return null

    const notaPonto = nota.replace(',', '.')

    const notaFloat = Number.parseFloat(notaPonto)

    if (Number.isNaN(notaFloat)) return null

    if (notaFloat <= max) return null
    else return { nota_maxima: true }
  }
}
