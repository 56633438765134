/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { FormularioComplementar } from 'src/app/models/formulario-complementar'
import { FormularioComplementarService } from 'src/app/services/formulario-complementar.service'
import { TipoComplemento } from 'src/app/enums/tipo-complemento'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { PreviaFormularioComplementarComponent } from '../previa-formulario-complementar/previa-formulario-complementar.component'
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { FormularioReordenacao } from 'src/app/models/formulario-reordenacao'
import { FormularioEtapa } from 'src/app/enums/formulario-etapa'

@Component({
  selector: 'app-lista-formulario-complementar',
  templateUrl: './lista-formulario-complementar.component.html',
  styleUrls: ['./lista-formulario-complementar.component.css'],
})
export class ListaFormularioComplementarComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  tipoConcurso: Map<string, string> = new Map()

  previaFC = new FormControl('')

  colunasGrid: string[] = [
    'id',
    'tipoConcurso',
    'etapa',
    'titulo',
    'subtitulo',
    'tipoFormulario',
    'obrigatorio',
    'ativo',
    'action',
  ]
  dataSource: MatTableDataSource<FormularioComplementar> = new MatTableDataSource()

  constructor(
    private formularioComplementarService: FormularioComplementarService,
    private unespCoreMessageService: UnespCoreMessageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.formularioComplementarService.listar().subscribe(formularios => {
      for (let form of formularios) {
        form.tipoConcurso = this.initListaTipoConcurso(form.tipoConcurso)
      }

      this.dataSource = new MatTableDataSource(formularios)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
    })
    this.previaFC.valueChanges.subscribe(tipo => tipo && this.abrirPrevia(tipo))
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  /*
    Expande o nome do tipo de concurso para que a pesquisa funcione com eles
    Popula o Mapa de tipo de concurso para que a prévia mostre apenas
    concursos que possuam formulario complementar
  */
  private initListaTipoConcurso(str: string): string {
    let ret = `,${str},`
    const tipos = str.split(',')

    for (const tipo of tipos) {
      let txt = this.tipoConcursoTexto(tipo) || tipo
      this.tipoConcurso.set(tipo, txt)
      ret = txt + ';;' + ret
    }

    return ret
  }

  private tipoConcursoTexto(str?: string): string | undefined {
    if (typeof str === 'undefined' || str == null || str == '') return str
    return (TipoConcurso as any)[str]
  }

  formatarListaTipoConcurso(str: string): string {
    let tipos = str.split(';;')
    tipos.pop()
    let ret = tipos.pop() || ''
    let n = tipos.length
    for (let tipo of tipos) {
      if (ret.length < 25) ret += ', ' + tipo
      else if (n > 0) {
        ret += `, [+${n}]`
        break
      }
      n--
    }
    return ret
  }

  tipoComplementoTexto(str?: string): string {
    if (typeof str === 'undefined' || str == null || str == '') return ''
    return (TipoComplemento as any)[str]
  }

  etapaTexto(str?: string): string {
    if (typeof str === 'undefined' || str == null || str == '') return ''
    return (FormularioEtapa as any)[str]
  }

  abrirPrevia(tipo: string) {
    const forms = this.dataSource.data.filter(form => form.tipoConcurso.includes(`,${tipo},`))

    const dialogRef = this.dialog.open(PreviaFormularioComplementarComponent, {
      data: { tipoConcurso: this.tipoConcursoTexto(tipo), formularios: forms },
      width: '80%',
    })

    dialogRef.afterClosed().subscribe((data: FormularioReordenacao[]) => {
      if (data) {
        data.forEach(ordenacao => {
          this.dataSource.data.forEach(data => {
            if (data.id === ordenacao.idFormulario) {
              data.sequencia = ordenacao.ordem
            }
          })
        })
      }
      this.previaFC.reset()
    })
  }

  excluir(formulario: FormularioComplementar) {
    let index = this.dataSource.data.indexOf(formulario)
    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `Confirma a exclusão do Formulário Complementar #${formulario.id}`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: confirmDialog })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.formularioComplementarService.excluir(formulario.id as number).subscribe(() => {
          let tmp: FormularioComplementar[] = this.dataSource.data
          tmp.splice(index, 1)
          this.dataSource.data = tmp
          this.unespCoreMessageService.showMessageSuccess(
            `Formulário Complementar ${formulario.id} excluído com sucesso`
          )
        })
      }
    })
  }
}
