/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { UsuarioCandidato } from 'src/app/models/usuario-candidato'
import { UsuarioCandidatoService } from 'src/app/services/usuario-candidato.service'

@Component({
  selector: 'app-usuario-candidato-email',
  templateUrl: './usuario-candidato-email.component.html',
  styleUrls: ['./usuario-candidato-email.component.css'],
})
export class UsuarioCandidatoEmailComponent implements OnInit {
  id?: string
  usuarioCandidato?: UsuarioCandidato

  form = new FormGroup({
    emailAtual: new FormControl('', Validators.required),
    novoEmail: new FormControl('', Validators.required),
  })

  constructor(
    private usuarioCandidatoService: UsuarioCandidatoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.usuarioCandidatoService.get(Number(this.id)).subscribe(obj => {
          this.usuarioCandidato = obj
          this.form.get('emailAtual')?.setValue(obj.email)
        })
      }
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  gravar(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    if (this.id && this.usuarioCandidato) {
      var jsonData = {
        emailAtual: this.form.get('emailAtual')?.value,
        novoEmail: this.form.get('novoEmail')?.value,
      }
      this.usuarioCandidatoService.email(Number(this.id), jsonData).subscribe(resultado => {
        if (resultado == 'duplicated') {
          this.unespCoreMessageService.showMessageError('Já existe cadastro para o e-mail informado')
        } else if (resultado == 'updated') {
          this.unespCoreMessageService.showMessageSuccess('E-mail alterado com sucesso')
          this.router.navigate(['/usuario-candidato'])
        } else {
          this.unespCoreMessageService.showMessageError(
            'Não foi possível alterar, verique os valores e tente novamente'
          )
        }
      })
    }
  }
}
