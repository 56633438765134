/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css'],
})
export class ManualComponent implements OnInit {
  @Input() trustedUrl!: SafeUrl
  iframeHeight: number = 200
  perfil: string = ''

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      var { perfil } = data
      this.perfil = perfil
      let url = ''
      if (perfil === 'STGP') {
        url =
          'https://wikicrh.unesp.br/mediawiki/images/5/53/Manual_da_STGP_-_Sistema_de_Inscri%C3%A7%C3%B5es_13-12-2022.pdf'
      } else if (perfil === 'DTA') {
        url =
          'https://wikicrh.unesp.br/mediawiki/images/2/2a/Manual_da_DTA_-_Sistema_de_Inscri%C3%A7%C3%B5es_13-12-2022.pdf'
      } else if (perfil === 'Finanças') {
        url =
          'https://wikicrh.unesp.br/mediawiki/images/d/d9/Manual_de_Finan%C3%A7as_-_Sistema_de_Inscri%C3%A7%C3%B5es.pdf'
      } else if (perfil === 'Banca') {
        url =
          'https://wikicrh.unesp.br/mediawiki/images/5/5a/Manual_do_Usu%C3%A1rio_Externo_-_Sistema_de_Inscri%C3%A7%C3%B5es.pdf'
      } else if (perfil === 'CCPT') {
        url = 'https://wikicrh.unesp.br/mediawiki/images/4/4b/Manual_da_CCPT_-_Sistema_de_Inscri%C3%A7%C3%B5es.pdf'
      } else if (perfil === 'CACP') {
        url = 'https://wikicrh.unesp.br/mediawiki/images/0/0e/Manual_da_CACP_-_Sistema_de_Inscri%C3%A7%C3%B5es.pdf'
      }
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
      this.iframeHeight = window.innerHeight - 180
    })
  }
}
