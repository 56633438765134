/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { UsuarioCandidato } from 'src/app/models/usuario-candidato'
import { UsuarioCandidatoService } from 'src/app/services/usuario-candidato.service'

@Component({
  selector: 'app-usuario-candidato-senha',
  templateUrl: './usuario-candidato-senha.component.html',
  styleUrls: ['./usuario-candidato-senha.component.css'],
})
export class UsuarioCandidatoSenhaComponent implements OnInit {
  id?: string
  usuarioCandidato?: UsuarioCandidato

  form = new FormGroup({
    email: new FormControl('', Validators.required),
    novaSenha: new FormControl('', Validators.required),
    novaSenhaConfirmacao: new FormControl('', Validators.required),
  })

  constructor(
    private usuarioCandidatoService: UsuarioCandidatoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.usuarioCandidatoService.get(Number(this.id)).subscribe(obj => {
          this.usuarioCandidato = obj
          this.form.get('email')?.setValue(obj.email)
        })
      }
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  gravar(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    if (this.id && this.usuarioCandidato) {
      let novaSenha = this.form.get('novaSenha')?.value ?? ''
      if (/[A-Z]/.test(novaSenha) && /[a-z]/.test(novaSenha) && /[0-9]/.test(novaSenha) && novaSenha.length > 7) {
        let novaSenhaConfirmacao = this.form.get('novaSenhaConfirmacao')?.value ?? ''
        if (novaSenha !== novaSenhaConfirmacao) {
          this.unespCoreMessageService.showMessageError('A Nova Senha e a Confirmação não são iguais')
        } else {
          var jsonData = {
            senhaAtual: this.form.get('senhaAtual')?.value,
            novaSenha: this.form.get('novaSenha')?.value,
          }
          this.usuarioCandidatoService.senha(Number(this.id), jsonData).subscribe(resultado => {
            if (resultado == 'updated') {
              this.unespCoreMessageService.showMessageSuccess('Senha foi alterado com sucesso')
              this.router.navigate(['/usuario-candidato'])
            } else {
              this.unespCoreMessageService.showMessageError(
                'Não foi possível alterar, verique os valores e tente novamente'
              )
            }
          })
        }
      } else {
        this.unespCoreMessageService.showMessageError('A Nova Senha não atende aos requisitos')
      }
    }
  }
}
