/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-salvar-notas-dialog',
  templateUrl: './salvar-notas-dialog.component.html',
  styleUrls: ['./salvar-notas-dialog.component.scss'],
})
export class SalvarNotasDialogComponent {
  title: string
  message: string[]
  confirmacao: string
  negacao: string

  constructor(
    public dialogRef: MatDialogRef<SalvarNotasDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SalvarNotasDialogModel
  ) {
    // Update view with given values
    this.title = data.title
    this.message = data.message
    this.confirmacao = data.confirmacao
    this.negacao = data.negacao
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true)
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false)
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class SalvarNotasDialogModel {
  constructor(public title: string, public message: string[], public confirmacao: string, public negacao: string) {}
}
