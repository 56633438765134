/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InscricaoComplemento } from 'src/app/models/inscricao-complemento'
import { ArquivoSelecionado, arquivoUploadValidator } from 'src/app/modules/arquivo-upload/arquivo-upload.component'
import { InscricaoComplementoService } from 'src/app/services/inscricao-complemento.service'

@Component({
  selector: 'app-informacao-complementar-grupo',
  templateUrl: 'informacao-complementar-grupo.html',
  styleUrls: ['./informacao-complementar.component.css'],
})
export class InformacaoComplementarGrupoComponent {
  @Input() grupo?: string
  @Input() complementos?: InscricaoComplemento[]
  @Input() primeiroGrupo: Boolean = false
  @Input() formGroup?: FormGroup
}

@Component({
  selector: 'app-informacao-complementar-checkbox',
  templateUrl: 'informacao-complementar-checkbox.html',
  styleUrls: ['./informacao-complementar.component.css'],
})
export class InformacaoComplementarCheckboxComponent implements OnInit {
  @Input() complemento?: InscricaoComplemento
  @Input() formGroup?: FormGroup

  formControl = new FormControl(false)
  disabled: boolean = false
  oldValue: boolean = false

  constructor(private inscricaoComplementoService: InscricaoComplementoService) {}

  ngOnInit(): void {
    if (this.complemento) {
      let fase = this.complemento.etapa == 'SEGUNDA FASE' ? 'segundaFase' : 'inscricao'

      this.oldValue = this.complemento.valor == 'on'
      this.formControl.setValue(this.oldValue)
      if (this.complemento.requerido as boolean) this.formControl.addValidators(Validators.required)
      if (this.formGroup?.get(fase)?.disabled) {
        this.formControl.disable()
        this.disabled = true
      }
      this.formControl.registerOnDisabledChange((isDisabled: boolean) => (this.disabled = isDisabled))
      this.formControl.valueChanges.subscribe((value: boolean | null) => {
        if (value != null && value != this.oldValue) {
          this.uploadCheckBox(value)
          this.oldValue = value
        }
      })
      if (this.formGroup?.get(fase)) {
        ;(this.formGroup.get(fase) as FormGroup).addControl(this.complemento.id.toString(), this.formControl)
      }
    }
  }

  uploadCheckBox(value: boolean) {
    this.complemento!.valor = value ? 'on' : ''
    this.inscricaoComplementoService
      .alterar(this.complemento!.id as number, this.complemento!)
      .subscribe(complemento => (this.complemento = complemento))
  }
}

@Component({
  selector: 'app-informacao-complementar-file',
  templateUrl: 'informacao-complementar-file.html',
  styleUrls: ['./informacao-complementar.component.css'],
})
export class InformacaoComplementarFileComponent implements OnInit {
  @Input() complemento?: InscricaoComplemento
  @Input() formGroup?: FormGroup

  formControl: FormControl<ArquivoSelecionado> = new FormControl()

  ngOnInit(): void {
    if (this.complemento) {
      let fase = this.complemento.etapa == 'SEGUNDA FASE' ? 'segundaFase' : 'inscricao'

      const arquivo: ArquivoSelecionado = new ArquivoSelecionado()
      if (this.complemento.valor)
        arquivo.setNameAndSize(this.complemento.valor, this.complemento.anexoTamanho as number)
      this.formControl.setValue(arquivo)
      if (this.complemento.requerido as boolean)
        this.formControl.addValidators([arquivoUploadValidator(true), Validators.required])
      else this.formControl.addValidators(arquivoUploadValidator(false))
      if (this.formGroup?.get(fase)?.disabled) this.formControl.disable()
      if (this.formGroup?.get(fase)) {
        ;(this.formGroup.get(fase) as FormGroup).addControl(this.complemento.id.toString(), this.formControl)
      }
    }
  }
}

@Component({
  selector: 'app-informacao-complementar-text',
  templateUrl: 'informacao-complementar-text.html',
  styleUrls: ['./informacao-complementar.component.css'],
})
export class InformacaoComplementarTextComponent implements OnInit {
  @Input() complemento?: InscricaoComplemento
  @Input() formGroup?: FormGroup

  formControl = new FormControl('', { updateOn: 'blur' })

  constructor(private inscricaoComplementoService: InscricaoComplementoService) {}

  ngOnInit(): void {
    if (this.complemento) {
      let fase = this.complemento.etapa == 'SEGUNDA FASE' ? 'segundaFase' : 'inscricao'

      this.formControl.setValue(this.complemento.valor)
      if (this.complemento.requerido as boolean) this.formControl.addValidators(Validators.required)
      if (this.formGroup?.get(fase)?.disabled) this.formControl.disable()
      if (this.formGroup?.get(fase)) {
        ;(this.formGroup.get(fase) as FormGroup).addControl(this.complemento.id.toString(), this.formControl)
      }
      this.formControl.valueChanges.subscribe((value: string | null) => {
        if (value != null && this.formControl.dirty) {
          this.uploadInput(value)
          this.formControl.markAsPristine()
        }
      })
    }
  }

  uploadInput(str: string) {
    this.complemento!.valor = str
    this.inscricaoComplementoService
      .alterar(this.complemento!.id as number, this.complemento!)
      .subscribe(complemento => (this.complemento = complemento))
  }
}
