/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import {
  getAndamentoByDescription,
  TipoAndamento,
  TipoAndamentoDetalhe,
  TipoAndamentoDetalhes,
} from 'src/app/enums/tipo-andamento'
import { Andamento } from 'src/app/models/andamento'
import { AndamentoService } from 'src/app/services/andamento.service'
import { PermitirAlteracaoService } from 'src/app/services/permitir-alteracao.service'
import {
  ArquivoSelecionado,
  ArquivoStatus,
  ArquivoUploadComponent,
  arquivoUploadValidator,
} from '../../arquivo-upload/arquivo-upload.component'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-andamento-form',
  templateUrl: './andamento-form.component.html',
  styleUrls: ['./andamento-form.component.css'],
})
export class AndamentoFormComponent implements OnInit, OnChanges {
  datePipe: DatePipe = new DatePipe('pt-BR')

  @ViewChild('upload') upload: ArquivoUploadComponent | undefined

  @Input() isPermitirAlteracao: boolean = false
  @Input() andamento?: Andamento
  @Input() idConcurso?: number

  @Output() andamentoUpdate = new EventEmitter<Andamento | undefined>()

  id?: number
  isAndamentoConcluido: boolean = false

  tipoAndamento = TipoAndamento
  tipoAndamentoDetalhe?: TipoAndamentoDetalhe

  andamentoUploadPath: string = 'andamento/'
  andamentoUploadFc: FormControl<ArquivoSelecionado>

  tzOffset = new Date().getTimezoneOffset() * 60000

  form = new FormGroup({
    tipo: new FormControl('', Validators.required),
    titulo: new FormControl('', Validators.required),
    complemento: new FormControl(''),
    publicacao: new FormControl(new Date()),
    pagina: new FormControl(''),
    sessao: new FormControl(''),
    recursoInicio: new FormControl(new Date()),
    recursoFim: new FormControl(new Date()),
    anexo: new FormControl(''),
  })

  constructor(
    private andamentoService: AndamentoService,
    private permitirAlteracaoService: PermitirAlteracaoService,
    private unespCoreMessageService: UnespCoreMessageService
  ) {
    this.andamentoUploadFc = new FormControl(new ArquivoSelecionado(), {
      validators: [arquivoUploadValidator(false)],
      updateOn: 'submit',
    }) as FormControl<ArquivoSelecionado>
  }

  ngOnInit(): void {
    if (this.isPermitirAlteracao) this.form.disable()
  }

  ngOnChanges() {
    this.carregarAndamento(this.andamento)
  }

  carregarAndamento(andamento: Andamento | undefined): void {
    this.form.reset()
    if (typeof andamento === 'undefined') {
      this.id = undefined
      this.andamentoUploadFc.setValue(new ArquivoSelecionado())
    } else {
      this.form.controls['tipo'].setValue(getAndamentoByDescription(andamento.tipo))
      this.selecionaTipo()
      let publicacao =
        andamento.publicacao == null ? null : new Date(Date.parse(andamento.publicacao.toString()) + this.tzOffset)
      let recursoInicio =
        andamento.recursoInicio == null
          ? null
          : new Date(Date.parse(andamento.recursoInicio.toString()) + this.tzOffset)
      let recursoFim =
        andamento.recursoFim == null ? null : new Date(Date.parse(andamento.recursoFim.toString()) + this.tzOffset)
      this.form.controls['titulo'].setValue(andamento.titulo)
      this.form.controls['complemento'].setValue(andamento.complemento)
      this.form.controls['publicacao'].setValue(publicacao)
      this.form.controls['pagina'].setValue(andamento.pagina)
      this.form.controls['sessao'].setValue(andamento.sessao)
      this.form.controls['recursoInicio'].setValue(recursoInicio)
      this.form.controls['recursoFim'].setValue(recursoFim)
      this.id = Number(andamento.id!)
      this.isAndamentoConcluido = andamento.andamentoConcluido as boolean
      let anexo = this.andamentoUploadFc.value
      anexo.setNameAndSize(andamento.anexo, andamento.anexoTamanho as number)
      this.andamentoUploadFc.setValue(anexo)
      this.andamentoUploadPath = 'andamento/' + andamento.id
    }
  }

  async gravar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    let formulario = <Andamento>this.form.value

    if (this.id) {
      this.andamentoService.atualizar(this.idConcurso!, this.id, formulario).subscribe(andamento => {
        this.unespCoreMessageService.showMessageSuccess(`Andamento #${andamento.id} atualizado com sucesso`)
        this.anexoUpload(andamento.id, andamento)
      })
    } else {
      this.andamentoService.gravar(this.idConcurso!, formulario).subscribe(andamento => {
        this.unespCoreMessageService.showMessageSuccess(`Andamento #${andamento.id} cadastrado com sucesso`)
        this.anexoUpload(andamento.id, andamento)
      })
    }
  }

  private anexoUpload(id: number | string | undefined, andamento: Andamento) {
    if (this.andamentoUploadFc.value.status != ArquivoStatus.SELECIONADO) {
      this.andamentoUpdate.emit(andamento)
    }

    if (typeof id == 'undefined') return

    this.upload!.uploadPath = 'andamento/' + id
    const p = this.upload?.upload()
    if (p != null)
      p.then(arqStatus => {
        andamento.anexo = arqStatus.uuid + '.pdf'
        andamento.anexoTamanho = arqStatus.size
        this.andamentoUpdate.emit(andamento)
      })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  cancelar(): void {
    this.form.reset()
    this.id = undefined
    this.andamentoUpdate.emit(undefined)
  }

  permitirAlteracao() {
    if (this.id && this.isPermitirAlteracao) {
      this.permitirAlteracaoService.liberarRegistro('andamento', this.id).subscribe(registro => {
        this.isAndamentoConcluido = registro.andamento.concluido as boolean
        this.unespCoreMessageService.showMessageSuccess(`Andamento #${this.id} revertido para não concluído`)
      })
    }
  }

  clearValidations(name: string): void {
    this.form.get(name)?.reset()
    this.form.get(name)?.clearValidators()
    this.form.get(name)?.updateValueAndValidity()
  }

  private skipWeekend(date: Date): Date {
    if (date.getDay() === 6) {
      date.setDate(date.getDate() + 2)
      return date
    } else if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1)
      return date
    } else {
      return date
    }
  }

  selecionaTipo(): void {
    const tipo = this.form.get('tipo')?.getRawValue()
    this.tipoAndamentoDetalhe = TipoAndamentoDetalhes.get(tipo)

    this.clearValidations('recursoInicio')
    this.clearValidations('recursoFim')
    this.clearValidations('publicacao')
    this.clearValidations('pagina')
    this.clearValidations('sessao')

    let hoje = new Date()
    if (this.tipoAndamentoDetalhe?.recurso) {
      let recursoInicio =
        this.andamento?.recursoInicio != null
          ? new Date(Date.parse(this.andamento?.recursoInicio.toString()) + this.tzOffset)
          : new Date(Date.parse(hoje.toString()) + this.tzOffset)
      let dataComPrazo = new Date()
      dataComPrazo.setDate(dataComPrazo.getDate() + this.tipoAndamentoDetalhe?.prazo)
      let recursoFim =
        this.andamento?.recursoFim != null
          ? new Date(Date.parse(this.andamento?.recursoFim.toString()) + this.tzOffset)
          : new Date(Date.parse(dataComPrazo.toString()) + this.tzOffset)
      recursoFim = this.skipWeekend(recursoFim)

      this.form.controls['recursoInicio'].setValue(recursoInicio)
      this.form.controls['recursoFim'].setValue(recursoFim)

      this.form.controls['recursoInicio'].updateValueAndValidity()
      this.form.controls['recursoFim'].updateValueAndValidity()
    }
    if (this.tipoAndamentoDetalhe?.doe) {
      let publicacao =
        this.andamento?.publicacao != null
          ? new Date(Date.parse(this.andamento?.publicacao.toString()) + this.tzOffset)
          : new Date(Date.parse(hoje.toString()) + this.tzOffset)
      this.form.controls['publicacao'].setValue(publicacao)
      this.form.controls['sessao'].setValue('I')

      this.form.controls['publicacao'].updateValueAndValidity()
      this.form.controls['pagina'].updateValueAndValidity()
      this.form.controls['sessao'].updateValueAndValidity()
    }
  }

  clearDate(field: string): void {
    if (field === 'publicacao' || field === 'recursoInicio' || field === 'recursoFim') {
      this.form.controls[field].setValue(null)
    }
  }
}
