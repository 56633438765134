/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { U } from '@angular/cdk/keycodes'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { UnespCoreMenuNavigationService, UnespCoreMessageService, UnespCoreUserService, UnespCoreUsuario } from 'src/libs/unesp-core'
import { BehaviorSubject, map } from 'rxjs'
import { UsuarioLogin } from 'src/app/models/usuario-login'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class UserLoginService {
  private baseUrl: string

  constructor(
    private unespCoreUserService: UnespCoreUserService,
    private http: HttpClient,
    private messageService: UnespCoreMessageService,
    private router: Router,
    private unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
  ) {
    this.baseUrl = environment.baseUrlApi
    this.unespCoreUserService.user$.subscribe(user => {
      this._userLogin$.next(user as UsuarioLogin)
    })
  }

  private _userLogin$ = new BehaviorSubject<UsuarioLogin | null>(null)

  get user$(): BehaviorSubject<UsuarioLogin | null> {
    return this._userLogin$
  }

  getUser(): UsuarioLogin | null {
    return this.unespCoreUserService.getUser() as UsuarioLogin
  }

  loginSample(formData: FormData): void {
    this.getByEmailAndSenha(formData).subscribe({
      next: usuario => {
        if (usuario) {
          localStorage.setItem('sinsc__time', '3600')
          localStorage.setItem('sinsc__tokendate', Math.round(Date.now()).toString())
          let user: UnespCoreUsuario = usuario as UnespCoreUsuario
          user.roles = user.authorities.map(s => s.replace(/^ROLE_FFC.UNESP.INTRANET_/, ''))
          user.roles.push('**')
          this.unespCoreUserService.setUser(user)
          this.messageService.showMessageSuccess('Usuário autenticado com sucesso')
          this.router.navigate(['/'])
        } else {
          localStorage.setItem('sinsc__time', '0')
          this.messageService.showMessageError('Email e/ou senha inválido(s)')
        }
      },
      error: err => {
        localStorage.setItem('sinsc__time', '0')
        this.messageService.showMessageError(err.error.message)
      },
      complete: () => {
        setTimeout(() => {
          this.unespCoreMenuNavigationService.refreshMenu()
        }, 200)
      },
    })
  }

  private getByEmailAndSenha(form: FormData) {
    return this.http.post(`${this.baseUrl}/publico/auth/sample`, form).pipe(
      map(user => {
        return user
      })
    )
  }

  senhaRecuperar(email: string) {
    return this.http.get(`${this.baseUrl}/publico/senha-recuperar/${email}`, { responseType: 'text' }).pipe(
      map(email => {
        return email
      })
    )
  }

  senhaRedefinir(jsonData: any) {
    return this.http.post(`${this.baseUrl}/publico/senha-redefinir`, jsonData, { responseType: 'text' }).pipe(
      map(resultado => {
        return resultado
      })
    )
  }

  senhaAlterar(jsonData: any) {
    return this.http.post(`${this.baseUrl}/candidato/senha-alterar`, jsonData, { responseType: 'text' }).pipe(
      map(resultado => {
        return resultado
      })
    )
  }
}
