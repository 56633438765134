/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { ActivatedRoute, Router } from '@angular/router'
import { UnespCoreAuthService, UnespCoreMessageService } from 'src/libs/unesp-core'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'src/app/services/local-storage.service'
import { TipoSubmissao } from 'src/app/enums/tipo-submissao'
import { TipoSituacao } from 'src/app/enums/tipo-situacao'

@Component({
  selector: 'app-lista-inscricao',
  templateUrl: './lista-inscricao.component.html',
  styleUrls: ['./lista-inscricao.component.css'],
})
export class ListaInscricaoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef
  totalElements: number = 0

  colunasGrid: string[] = [
    'id',
    'concurso',
    'inscricao',
    'candidato',
    'contato',
    'status',
    'pago',
    'action',
    'boleto',
    'pix',
  ]
  dataSource: MatTableDataSource<Inscricao> = new MatTableDataSource()

  idConcurso? = undefined
  pago: number = 0
  isDepartamento: boolean = false

  parametroPagina: number | undefined = 1
  parametroTamanho: number | undefined = 10

  datepipe: DatePipe = new DatePipe('pt-BR')
  filtroSituacaoSelecionada = TipoSituacao.TODOS.valueOf()
  filtroSubmissaoSelecionada = TipoSubmissao.TODOS.valueOf()
  tipoSituacao = TipoSituacao
  tipoSubmissao = TipoSubmissao

  constructor(
    private inscricaoService: InscricaoService,
    private route: ActivatedRoute,
    private unespCoreAuthService: UnespCoreAuthService,
    private unespCoreMessageService: UnespCoreMessageService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isDepartamento = this.unespCoreAuthService.hasPermissionBasedRoles(['DEPARTAMENTO'])
    this.route.params.subscribe(params => {
      this.idConcurso = params['idConcurso']
      this.pago = params['pago'] === undefined ? 0 : params['pago'] === 'pago' ? 0 : 1

      this.parametroPagina = Number(this.route.snapshot.queryParamMap.get('pagina'))
      this.parametroTamanho = Number(this.route.snapshot.queryParamMap.get('tamanho'))
      if (
        this.localStorageService.get('lista-inscricao.filtro-situacao') == null ||
        this.localStorageService.get('lista-inscricao.filtro-situacao') == ''
      ) {
        this.filtroSituacaoSelecionada = 'TODOS'
      } else {
        this.filtroSituacaoSelecionada = this.localStorageService.get('lista-inscricao.filtro-situacao')
      }

      if (
        this.localStorageService.get('lista-inscricao.filtro-submissao') == null ||
        this.localStorageService.get('lista-inscricao.filtro-submissao') == ''
      ) {
        this.filtroSubmissaoSelecionada = 'TODOS'
      } else {
        this.filtroSubmissaoSelecionada = this.localStorageService.get('lista-inscricao.filtro-submissao')
      }
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()

    setTimeout(() => {
      this.input.nativeElement.value = this.localStorageService.get('lista-inscricao.search') ?? ''

      if (Number.isInteger(this.parametroPagina)) {
        this.paginator.pageIndex = this.parametroPagina
      } else {
        this.paginator.pageIndex = 0
      }
      if (Number.isInteger(this.parametroTamanho)) {
        this.paginator.pageSize = this.parametroTamanho
      }

      this.getData()
    }, 0)
  }

  public getData() {
    this.localStorageService.set('lista-inscricao.filtro-situacao', this.filtroSituacaoSelecionada)
    this.localStorageService.set('lista-inscricao.filtro-submissao', this.filtroSubmissaoSelecionada)
    this.localStorageService.set('lista-inscricao.search', this.input?.nativeElement.value)
    this.inscricaoService
      .listar(
        this.idConcurso,
        this.filtroSituacaoSelecionada.valueOf(),
        this.filtroSubmissaoSelecionada.valueOf(),
        this.pago,
        this.input?.nativeElement.value,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data['content'])
        this.dataSource.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'id':
              return item.id
            case 'concurso':
              return item.tituloConcurso
            case 'inscricao':
              return item.data
            case 'candidato':
              return item.nome
            case 'contato':
              return item.email
            case 'status':
              return item.status
            case 'pago':
              return item.pago
          }
        }
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
  }

  showEmitirBoleto(inscricao: Inscricao): boolean {
    if (this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'FINANCAS'])) {
      //return !inscricao.boletoSolicitado && inscricao.pagamentoConfigurado == 'BOLETO'
      //return !inscricao.boletoSolicitado && inscricao.pagamentoConfigurado !== 'PIX'
      //return !inscricao.boletoSolicitado && inscricao.pagamentoConfigurado !== 'PIX' && !inscricao.pago
      return inscricao.pagamentoConfigurado !== 'PIX' && !inscricao.pago
    }
    return false
  }

  showEmitirPix(inscricao: Inscricao): boolean {
    if (this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'FINANCAS'])) {
      //verificação para quando o código fosse anterior a atualização
      //return !inscricao.pixSolicitado && inscricao.pagamentoConfigurado == 'PIX'
      return inscricao.pagamentoConfigurado == 'PIX' && !inscricao.pago
    }
    return false
  }

  emitirPagamento(inscricao: Inscricao, tipo: string): void {
    if (tipo === 'PIX') {
      this.inscricaoService.emitirPagamento(inscricao.id, 'PIX').subscribe(resultado => {
        if (resultado === 'Success') {
          this.unespCoreMessageService.showMessageSuccess('PIX registrado com sucesso')
        } else {
          this.unespCoreMessageService.showMessageError('Não foi possível Registrar o pix')
        }
        this.ngOnInit()
      })
    } else {
      this.inscricaoService.emitirPagamento(inscricao.id, 'BOLETO').subscribe(resultado => {
        if (resultado === 'Success') {
          this.unespCoreMessageService.showMessageSuccess('Boleto registrado com sucesso')
        } else {
          this.unespCoreMessageService.showMessageError('Não foi possível Registrar o boleto')
        }
        this.ngOnInit()
      })
    }
  }

  abrirFicha(id: number): void {
    if (this.idConcurso) {
      this.router.navigate([`/inscricao/ficha/${id}/${this.idConcurso}`], {
        queryParams: { pagina: this.paginator.pageIndex, tamanho: this.paginator.pageSize },
      })
    } else {
      this.router.navigate([`/inscricao/ficha/${id}`], {
        queryParams: { pagina: this.paginator.pageIndex, tamanho: this.paginator.pageSize },
      })
    }
  }

  exportar() {
    if (this.totalElements !== 0) {
      this.inscricaoService.gerarArquivoCsv(this.idConcurso, this.input?.nativeElement.value)
    } else {
      this.unespCoreMessageService.showMessageError(`Não Existem registros para exportar`)
    }
  }
}
