/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { PermitirAlteracaoService } from 'src/app/services/permitir-alteracao.service'
import { Campus } from 'src/app/models/campus'
import { Concurso } from 'src/app/models/concurso'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { RegistroPermitirAlteracao } from 'src/app/models/registro-permitir-alteracao'
import { TipoRegistro } from 'src/app/enums/tipo-registro'
import { map, Observable, of, startWith } from 'rxjs'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { ProvaEtapa, TipoProva } from 'src/app/enums/tipo-prova'
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component'

@Component({
  selector: 'app-permitir-alteracao',
  templateUrl: './permitir-alteracao.component.html',
  styleUrls: ['./permitir-alteracao.component.scss'],
})
export class PermitirAlteracaoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator

  colunasGrid: string[] = ['id']
  dataSource: MatTableDataSource<RegistroPermitirAlteracao> = new MatTableDataSource()

  tipoRegistroFC: FormControl = new FormControl('')
  unidadeFC: FormControl = new FormControl('')
  concursoFC: FormControl = new FormControl('')

  tipoRegistro = TipoRegistro

  tipoProva: any = TipoProva
  provaEtapa: any = ProvaEtapa

  unidades: Campus[] = []
  concursos: Concurso[] = []

  unidadesFiltradas: Observable<Campus[]> = of(this.unidades)
  concursosFiltrados: Observable<Concurso[]> = of(this.concursos)

  private baseUrl: string

  constructor(
    @Inject('environment') env: UnespCoreEnvironment,
    private permitirAlteracaoService: PermitirAlteracaoService,
    public dialog: MatDialog
  ) {
    this.baseUrl = env.baseUrlRedirect.replace('/auth/', '')
  }

  ngOnInit(): void {
    this.unidadeFC.disable()
    this.concursoFC.disable()

    this.permitirAlteracaoService.getUnidades().subscribe(arr => (this.unidades = arr))

    this.tipoRegistroFC.valueChanges.subscribe(opt => this.mostrarUnidades())

    this.unidadesFiltradas = this.unidadeFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        const nome = typeof value === 'string' ? value : this.getConcursos(value)
        return nome ? this._filtroUnidade(nome as string) : this.unidades.slice()
      })
    )

    this.concursosFiltrados = this.concursoFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        const nome = typeof value === 'string' ? value : this.listar(value)
        return nome ? this._filtroConcurso(nome as string) : this.concursos.slice()
      })
    )

    this.dataSource.paginator = this.paginator
  }

  private _filtroUnidade(nome: string) {
    const filterValue = nome.toLowerCase()
    return this.unidades.filter(opt => opt.nome.toLowerCase().includes(filterValue))
  }

  nomeUnidade(unidade: Campus): string {
    return unidade && unidade.nome ? unidade.nome : ''
  }

  mostrarUnidades() {
    this.unidadeFC.reset()
    this.unidadeFC.enable()
    this.concursoFC.reset()
    this.concursoFC.disable()
  }

  private _filtroConcurso(nome: string) {
    const filterValue = nome.toLowerCase()
    return this.concursos.filter(opt => opt.titulo.toLowerCase().includes(filterValue))
  }

  tituloConcurso(concurso: Concurso): string {
    return concurso && concurso.titulo ? concurso.titulo : ''
  }

  getConcursos(unidade: Campus) {
    if (unidade == null) return
    this.permitirAlteracaoService.getConcursos(unidade.uuid).subscribe(arr => {
      this.concursos = arr
      this.concursoFC.reset()
      this.concursoFC.enable()
    })
  }

  listar(concurso: Concurso) {
    if (concurso != null) {
      const id = concurso.id as number
      if (id === undefined) return
      const tipo = this.tipoRegistroFC.value
      switch (tipo) {
        case 'DEFERIMENTO':
          this.colunasGrid = ['id', 'dados_inscricao', 'deferimento', 'action']
          break
        case 'PAGAMENTO':
          this.colunasGrid = ['id', 'dados_inscricao', 'pagamento', 'action']
          break
        case 'REDUCAO':
          this.colunasGrid = ['id', 'dados_inscricao', 'reducao', 'action']
          break
        case 'ANDAMENTO':
          this.colunasGrid = ['id', 'andamento_1', 'andamento_2', 'action']
          break
        case 'RECURSO':
          this.colunasGrid = ['id', 'recurso_0', 'recurso_1', 'recurso_2', 'action']
          break
        case 'CONCURSOPROVA':
          this.colunasGrid = ['prova_0', 'prova_1', 'prova_action']
          break
        default:
          return
      }
      this.permitirAlteracaoService
        .listarRegistros(tipo.toLowerCase(), id)
        .subscribe(registros => (this.dataSource = new MatTableDataSource(registros)))
    }
  }

  abrirNovaAba(tipo: string, id: number): void {
    window.open(`${this.baseUrl}/permitir-alteracao/${tipo}/${id}`, '_blank')
  }

  mudarParaEdicao(registro: RegistroPermitirAlteracao) {
    const prova = registro.concursoProva

    const provaNome = (TipoProva as any)[prova.tipoProva].nome

    const alteracao = (prova.provaEtapa as string) == 'CONCLUIDA' ? 'não concluída e não publicada' : 'não publicada'

    const confirmDialog = new ConfirmDialogModel('Atenção', `Mudar ${provaNome} para ${alteracao}?`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.permitirAlteracaoService.reverterProva(registro.id, prova.tipoProva, 'EDICAO').subscribe(result => {
          if (result) this.listar(this.concursoFC.value)
        })
    })
  }

  mudarParaPublicada(registro: RegistroPermitirAlteracao) {
    const prova = registro.concursoProva

    const provaNome = (TipoProva as any)[prova.tipoProva].nome

    const confirmDialog = new ConfirmDialogModel('Atenção', `Mudar ${provaNome} para não concluída?`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.permitirAlteracaoService.reverterProva(registro.id, prova.tipoProva, 'PUBLICADA').subscribe(result => {
          if (result) this.listar(this.concursoFC.value)
        })
    })
  }
}
