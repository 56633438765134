<h2>{{ concursoTitulo }}</h2>
<h3>{{ concursoTipo }}</h3>
<h3>{{ tipoProvaStr }}</h3>

<div *ngIf="editavel" data-div="copiarcriterios" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="begin center">
  <mat-form-field appearance="outline">
    <mat-label>{{ concursoRefLabel }}</mat-label>
    <input matInput #concursoRefInput [formControl]="concursoRefFC" [matAutocomplete]="autoConcursoRef" />
    <mat-autocomplete autoActiveFirstOption #autoConcursoRef="matAutocomplete" [displayWith]="nomeConcursoRef">
      <mat-option *ngFor="let opt of concursosRefFiltrados | async" [value]="opt">
        {{ opt.titulo }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="copiarCriterios()" [disabled]="!btnCopiar">
    Copiar criterios
  </button>
</div>
<br />
<div *ngIf="editavel" data-div="editordecriterios" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="begin center">
  <div fxFlex="2 0 1" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
    <mat-form-field appearance="outline">
      <mat-label>Critério</mat-label>
      <mat-select [formControl]="criterioGrupoFC">
        <mat-option *ngFor="let opt of criterios" [value]="opt.id">
          {{ opt.titulo }}
        </mat-option>
        <mat-option *ngIf="addCriteriosOpt" [value]="-1"><b>+ Adicionar Critério</b></mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!editandoSubCriterio" appearance="outline">
      <mat-label>Titulo do Critério</mat-label>
      <input matInput [formControl]="criterioTituloFC" name="criterio" />
    </mat-form-field>

    <mat-form-field *ngIf="!editandoSubCriterio" appearance="outline">
      <mat-label>Nota máxima</mat-label>
      <input
        matInput
        [formControl]="notaMaximaCriterioFC"
        name="notaMaxima"
        mask="0*,9999"
        [dropSpecialCharacters]="false" />
    </mat-form-field>

    <mat-form-field *ngIf="editandoSubCriterio" appearance="outline">
      <mat-label>Subcritério</mat-label>
      <input matInput [formControl]="subCriterioTituloFC" name="subcriterio" />
    </mat-form-field>

    <mat-form-field *ngIf="editandoSubCriterio" appearance="outline">
      <mat-label>Nota máxima</mat-label>
      <input
        matInput
        [formControl]="notaMaximaSubCriterioFC"
        name="notaMaximaSub"
        mask="0*,9999"
        [dropSpecialCharacters]="false" />
    </mat-form-field>
  </div>
  <button mat-raised-button color="primary" (click)="salvar()">
    {{ atualizando ? 'Atualizar' : 'Adicionar' }}
  </button>
  <button mat-stroked-button color="warn" (click)="cancelarEdicao()">Cancelar</button>
</div>
<br />
<div>
  <div
    class="list"
    cdkDropList
    [cdkDropListDisabled]="!editavel || !addCriteriosOpt"
    (cdkDropListDropped)="dropCriterio($event)">
    <div class="box title">
      <div><b>Critério</b></div>
      <div *ngIf="editavel"><b>Ações</b></div>
    </div>
    <div class="grupo" *ngFor="let criterio of criterios; let cIdx = index" cdkDrag>
      <div class="custom-placeholder" *cdkDragPlaceholder></div>
      <div>
        <div class="box grupo-titulo">
          <div class="text-left">
            {{ criterio.titulo }}
            ( Máximo {{ criterio.notaMaximaGrupo | number : '1.2-4' }} )
          </div>
          <ng-template [ngIf]="editavel && addCriteriosOpt">
            <div>
              <button mat-stroked-button color="basic" (click)="alterarCriterio(cIdx)">Alterar</button>&nbsp;
              <button
                mat-stroked-button
                color="basic"
                data-btn="excluircriterio"
                (click)="excluirCriterio(cIdx)"
                [disabled]="temNotas">
                Excluir
              </button>
            </div>
          </ng-template>
        </div>
        <div
          class="list criterios"
          cdkDropList
          [cdkDropListDisabled]="!editavel"
          (cdkDropListDropped)="dropSubCriterio($event, criterio.id)">
          <div class="box criterio" *ngFor="let subCriterio of criterio.subCriterios; let sCIdx = index" cdkDrag>
            <div class="custom-placeholder" *cdkDragPlaceholder></div>
            <div class="criterio">
              {{ subCriterio.titulo }}
              {{ subCriterio.notaMaxima ? '( Máximo ' + (subCriterio.notaMaxima | number : '1.2-4') + ' )' : '' }}
            </div>
            <div *ngIf="editavel">
              <button mat-stroked-button color="basic" (click)="alterarSubCriterio(cIdx, sCIdx)">Alterar</button>&nbsp;
              <button
                mat-stroked-button
                color="basic"
                data-btn="excluirsubcriterio"
                (click)="excluirSubCriterio(cIdx, sCIdx)"
                [disabled]="temNotas">
                Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box sem-criterios" *ngIf="criterios.length === 0">
      <span>Nenhum critério adicinado</span>
    </div>
  </div>
</div>
<br />
<div fxLayoutAlign="space-between center">
  <button mat-raised-button color="primary" (click)="imprimir()">Imprimir</button>
  <button mat-stroked-button color="warn" routerLink="/concurso/{{ idConcurso }}/provas">
    <mat-icon>arrow_back</mat-icon>Retornar as provas
  </button>
</div>
