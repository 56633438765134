/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, Inject } from '@angular/core'
import { FormularioComplementar } from 'src/app/models/formulario-complementar'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { FormularioComplementarService } from 'src/app/services/formulario-complementar.service'
import { FormularioReordenacao } from 'src/app/models/formulario-reordenacao'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { TipoComplemento } from 'src/app/enums/tipo-complemento'
import { FormularioEtapa } from 'src/app/enums/formulario-etapa'

export interface PreviaDialogData {
  tipoConcurso: string
  formularios: FormularioComplementar[]
}

@Component({
  selector: 'app-previa-formulario-complementar',
  templateUrl: 'previa-formulario-complementar.component.html',
  styleUrls: ['./previa-formulario-complementar.component.css'],
})
export class PreviaFormularioComplementarComponent implements OnInit {
  // mapGrupos: Map<string,FormularioComplementar[]> = new Map
  formularios!: FormularioComplementar[]

  constructor(
    private unespCoreMessageService: UnespCoreMessageService,
    private formularioComplementarService: FormularioComplementarService,
    public dialogRef: MatDialogRef<PreviaFormularioComplementarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PreviaDialogData
  ) {}

  ngOnInit(): void {
    // this.agruparFormularioComplementar()
    this.formularios = this.data.formularios
      .filter(f => f.ativo)
      .sort(function (a, b) {
        return a.etapa > a.etapa && a.sequencia > b.sequencia
          ? 1
          : b.etapa > b.etapa && b.sequencia > a.sequencia
          ? -1
          : 0
      })
  }

  // agruparFormularioComplementar(): void {
  //   for(let form of this.data.formularios){
  //     if (form.ativo) {
  //       let arr = this.mapGrupos.get(form.grupo)
  //       arr ? arr.push(form) : this.mapGrupos.set(form.grupo, [form] )
  //     }
  //   }
  // }

  tipoComplementoTexto(str?: string): string {
    if (typeof str === 'undefined' || str == null || str == '') return ''
    return (TipoComplemento as any)[str]
  }

  etapaTexto(str?: string): string {
    if (typeof str === 'undefined' || str == null || str == '') return ''
    return (FormularioEtapa as any)[str]
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.formularios, event.previousIndex, event.currentIndex)
  }

  salvar(): void {
    let elementos: FormularioReordenacao[] = []
    let ordem: number = 0
    this.formularios.forEach(formulario => {
      ordem++
      elementos.push(new FormularioReordenacao(Number(formulario.id!), ordem))
    })
    this.formularioComplementarService.reordenar(elementos!).subscribe(alteracoes => {
      if (alteracoes === elementos.length) {
        this.unespCoreMessageService.showMessageSuccess(`Ordenação atualizada com sucesso`)
        this.dialogRef.close(elementos)
      } else {
        this.unespCoreMessageService.showMessageError(`Não foi possível realizar a alteração`)
        this.dialogRef.close()
      }
    })
  }
}
