/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { PerfilService } from 'src/app/services/perfil.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Component, Inject } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { IncluirUsuarioPerfil } from 'src/app/models/incluir-usuario-perfil'

export interface AdicionarDialogData {
  idPerfil: number
}

@Component({
  selector: 'app-adicionar-perfil.component',
  templateUrl: './adicionar-perfil.component.html',
  styleUrls: ['./adicionar-perfil.component.scss'],
})
export class AdicionarPerfilComponent {
  novoUsuario?: IncluirUsuarioPerfil
  email: string = ''
  idPerfil?: number

  identificacaoFC: FormControl = new FormControl('', Validators.required)

  constructor(
    private perfilService: PerfilService,
    private unespCoreMessageService: UnespCoreMessageService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: AdicionarDialogData,
    public dialogRef: MatDialogRef<AdicionarPerfilComponent>
  ) {}

  salvar(): void {
    if (!this.identificacaoFC.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    if (this.data.idPerfil) {
      this.perfilService.adicionarUsuario(this.data.idPerfil, this.identificacaoFC.value).subscribe(value => {
        if (value) {
          this.unespCoreMessageService.showMessageSuccess(
            `Perfil ${this.data.idPerfil} aplicado com sucesso ao usuário ${this.identificacaoFC.value}`
          )
          this.dialogRef.close()
        } else this.identificacaoFC.setErrors({ invalido: true })
      })
    }
  }
}
