<div fxLayout="column" fxLayoutGap="20px">
  <h1>Candidato - Vincular Contas de E-mail</h1>
  <mat-card>
    <mat-card-content>
      <fieldset fxFlex="50%">
        <legend>E-mail de Origem</legend>
        <form [formGroup]="form" fxLayout="column" fxLayoutGap="5px">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="emailAtual"
              name="email"
              type="email"
              [email]="true"
              maxlength="150"
              required
              readonly="readonly"
              #inputAtual />
          </mat-form-field>
        </form>
        &nbsp; &nbsp;
        <h1>Inscrições referentes ao email de origem:</h1>
        <table mat-table [dataSource]="dataSourceAtual" matSort>
          <ng-container matColumnDef="candidato">
            <th mat-header-cell *matHeaderCellDef>Candidato</th>
            <td mat-cell *matCellDef="let row">
              <p>{{ row.nome }}</p>
              <p><b>CPF</b> {{ row.cpf }}</p>
              <p><b>E-mail</b> {{ row.email }}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="concurso">
            <th mat-header-cell *matHeaderCellDef>Concurso</th>
            <td mat-cell *matCellDef="let row">
              <p>{{ row.idConcurso }}</p>
              <p>{{ row.tituloConcurso }}</p>
              <p>{{ row.campus }}</p>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="colunasGridAtual"></tr>
          <tr mat-row *matRowDef="let row; columns: colunasGridAtual"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="2">
              <span *ngIf="inputAtual.value">Nenhum registro encontrado para a pesquisa: "{{ inputAtual.value }}"</span>
              <span *ngIf="!inputAtual.value">Nenhum registro disponível</span>
            </td>
          </tr>
        </table>
        <mat-paginator
          #paginatorAtual
          [length]="totalElementsAtual"
          [pageSize]="10"
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons>
        </mat-paginator>
      </fieldset>

      <fieldset fxFlex="50%">
        <legend>Vincular com a conta de e-mail:</legend>
        <form [formGroup]="form" fxLayout="column" fxLayoutGap="5px">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="novoEmail"
              name="novoEmail"
              type="email"
              [email]="true"
              maxlength="150"
              required
              placeholder="Digite e pressione ENTER para pesquisar"
              #input />
          </mat-form-field>
        </form>
        &nbsp; &nbsp;
        <h1>Inscrições referentes ao e-mail informado:</h1>
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="candidato">
            <th mat-header-cell *matHeaderCellDef>Candidato</th>
            <td mat-cell *matCellDef="let row">
              <p>{{ row.nome }}</p>
              <p><b>CPF</b> {{ row.cpf }}</p>
              <p><b>E-mail</b> {{ row.email }}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="concurso">
            <th mat-header-cell *matHeaderCellDef>Concurso</th>
            <td mat-cell *matCellDef="let row">
              <p>{{ row.idConcurso }}</p>
              <p>{{ row.tituloConcurso }}</p>
              <p>{{ row.campus }}</p>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
          <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="2">
              <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
              <span *ngIf="!input.value">Nenhum registro disponível</span>
            </td>
          </tr>
        </table>
        <mat-paginator
          #paginator
          [length]="totalElements"
          [pageSize]="10"
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons>
        </mat-paginator>
      </fieldset>
    </mat-card-content>
  </mat-card>
  <mat-card>
    &nbsp;
    <mat-card-actions>
      <div
        fxLayoutAlign.gt-md="space-between center"
        fxLayoutAlign.lt-md="center stretch"
        fxLayout.lt-md="column"
        fxLayoutGap="20px">
        <button mat-raised-button color="primary" (click)="gravar()"><mat-icon>edit</mat-icon> Vincular Email</button>
        <button mat-stroked-button color="warn" routerLink="/usuario-candidato">
          <mat-icon>close</mat-icon> Cancelar
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
