/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component'
import { map, Observable, of, startWith } from 'rxjs'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { TipoProva } from 'src/app/enums/tipo-prova'
import { Concurso } from 'src/app/models/concurso'
import { ProvaCriterio, ProvaGrupoCriterio, ProvaSubCriterio } from 'src/app/models/prova-criterio'
import { ProvaCriterioService } from 'src/app/services/prova-criterio.service'
import { CdkDragDrop } from '@angular/cdk/drag-drop'

@Component({
  selector: 'app-prova-criterios',
  templateUrl: './prova-criterios.component.html',
  styleUrls: ['./prova-criterios.component.css'],
})
export class ProvaCriteriosComponent implements OnInit {
  idConcurso?: number
  tipoProva?: string
  tipoProvaStr?: string
  tipoProvaVersao?: string
  criterioFormBtn?: string
  concursoTitulo?: string
  concursoTipo?: string

  editavel?: boolean
  addCriteriosOpt?: boolean = false
  temNotas?: boolean
  btnCopiar?: boolean

  idCount: number = 0

  criterios: ProvaGrupoCriterio[] = []

  concursosRef: Concurso[] = []
  concursosRefFiltrados: Observable<Concurso[]> = of(this.concursosRef)
  concursoRefFC = new FormControl()
  concursoRefLabel = 'Selecionar concurso como modelo'

  datepipe: DatePipe = new DatePipe('pt-BR')

  criterioGrupoFC = new FormControl(-1)
  editandoSubCriterio: boolean = false

  atualizando: boolean = false

  criterioTituloFC = new FormControl('', Validators.required)
  notaMaximaCriterioFC = new FormControl('')

  criterioForm = new FormGroup({
    id: new FormControl(),
    titulo: this.criterioTituloFC,
    notaMaxima: this.notaMaximaCriterioFC,
  })

  subCriterioTituloFC = new FormControl('', Validators.required)
  notaMaximaSubCriterioFC = new FormControl('')

  subCriterioForm = new FormGroup({
    id: new FormControl(),
    titulo: this.subCriterioTituloFC,
    notaMaxima: this.notaMaximaSubCriterioFC,
  })

  constructor(
    private unespCoreMessageService: UnespCoreMessageService,
    private provaCriterioService: ProvaCriterioService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.concursoRefFC.disable()

    this.route.params.subscribe(params => {
      this.idConcurso = params['idConcurso']
      this.tipoProva = params['tipoProva']
      this.tipoProvaStr = this.tipoProva ? (TipoProva as any)[this.tipoProva].nome : ''
      if (this.idConcurso && this.tipoProva) this.initTable()
    })

    this.criterioGrupoFC.valueChanges.subscribe(value => {
      this.editandoSubCriterio = true

      if (value == null) {
        this.subCriterioForm.get('titulo')?.disable()
        this.subCriterioForm.get('notaMaxima')?.disable()
      } else {
        this.subCriterioForm.get('titulo')?.enable()
        this.subCriterioForm.get('notaMaxima')?.enable()
        if (value == -1) {
          this.editandoSubCriterio = false
          this.resetForms()
        }
      }
    })
  }

  private initTable() {
    this.provaCriterioService.listar(this.tipoProva!, this.idConcurso!).subscribe(prova => {
      this.concursoTitulo = prova.concursoTitulo
      this.concursoTipo = (TipoConcurso as any)[prova.concursoTipo]
      this.tipoProvaVersao = prova.tipoProvaVersao

      this.updateCriterios(prova.criterios)

      this.editavel = prova.editavel
      this.addCriteriosOpt = !prova.criteriosPadronizados

      if (prova.notaMaximaSubCriterioOpcional) {
        this.notaMaximaCriterioFC.addValidators(Validators.required)
      } else {
        this.notaMaximaSubCriterioFC.addValidators(Validators.required)
      }

      this.criterioGrupoFC.setValue(this.addCriteriosOpt ? -1 : null)

      this.resetForms()

      if (prova.editavel) this.initConcursosRef()

      this.temNotas = prova.temNotas
    })
  }

  private updateCriterios(updated: ProvaGrupoCriterio[]) {
    this.criterios = updated

    for (let criterio of this.criterios) {
      if (!criterio.notaMaxima) {
        let notaMaximaGrupo = 0

        for (let subCriterio of criterio.subCriterios)
          notaMaximaGrupo += Number.parseFloat(subCriterio.notaMaxima.toString())

        criterio.notaMaximaGrupo = notaMaximaGrupo
      } else {
        criterio.notaMaximaGrupo = Number.parseFloat(criterio.notaMaxima.toString())
      }
    }
  }

  private resetForms() {
    this.criterioForm.reset()
    this.subCriterioForm.reset()
    this.atualizando = false
  }

  salvar() {
    if (this.editandoSubCriterio) {
      if (!this.subCriterioForm.valid) {
        this.unespCoreMessageService.showMessageError('Campos obrigatórios não preenchidos')
        return
      }

      let criterioId = this.criterioGrupoFC.value!
      let formData = this.subCriterioForm.value
      formData.notaMaxima = formData.notaMaxima ? formData.notaMaxima.replace(',', '.') : formData.notaMaxima

      if (this.atualizando) this.atualizarSubCriterio(criterioId, formData as ProvaSubCriterio)
      else this.adicionarSubCriterio(criterioId, formData as ProvaSubCriterio)
    } else {
      if (!this.criterioForm.valid) {
        this.unespCoreMessageService.showMessageError('Campos obrigatórios não preenchidos')
        return
      }

      let formData = this.criterioForm.value
      formData.notaMaxima = formData.notaMaxima ? formData.notaMaxima.replace(',', '.') : formData.notaMaxima

      if (this.atualizando)
        this.atualizarCriterio(formData.id, formData.titulo as string, formData.notaMaxima as string)
      else this.adicionarCriterio(formData as ProvaCriterio)
    }

    this.resetForms()
    this.criterioGrupoFC.enable()
  }

  private adicionarCriterio(novoCriterio: ProvaCriterio) {
    this.provaCriterioService
      .adicionarCriterio(this.tipoProva!, this.idConcurso!, novoCriterio)
      .subscribe(updated => this.updateCriterios(updated))
  }

  private removerCriterio(idx: number) {
    this.provaCriterioService
      .excluirCriterio(this.tipoProva!, this.idConcurso!, idx)
      .subscribe(updated => this.updateCriterios(updated))
  }

  private adicionarSubCriterio(idCriterio: number | string, novoSubCriterio: ProvaSubCriterio) {
    this.provaCriterioService
      .adicionarSubCriterio(this.tipoProva!, this.idConcurso!, idCriterio, novoSubCriterio)
      .subscribe(updated => this.updateCriterios(updated))
  }

  private removerSubCriterio(id: number) {
    this.provaCriterioService
      .excluirSubCriterio(this.tipoProva!, this.idConcurso!, id)
      .subscribe(updated => this.updateCriterios(updated))
  }

  private atualizarCriterio(id: number, titulo: string, notaMaxima: number | string | null) {
    const criterioAtualizado: ProvaCriterio = {
      id,
      titulo,
      notaMaxima,
      subCriterios: [],
    }

    this.provaCriterioService
      .atualizarCriterio(this.tipoProva!, this.idConcurso!, criterioAtualizado)
      .subscribe(updated => this.updateCriterios(updated))
  }

  private atualizarSubCriterio(idCriterio: number | string, novoSubCriterio: ProvaSubCriterio) {
    this.provaCriterioService
      .atualizarSubCriterio(this.tipoProva!, this.idConcurso!, idCriterio, novoSubCriterio)
      .subscribe(updated => this.updateCriterios(updated))
  }

  alterarCriterio(criterioIdx: number) {
    const notaMaxima = this.criterios[criterioIdx].notaMaxima

    let formData = {
      id: this.criterios[criterioIdx].id,
      titulo: this.criterios[criterioIdx].titulo,
      notaMaxima: notaMaxima ? notaMaxima.toString().replace('.', ',') : null,
    }

    this.criterioGrupoFC.setValue(-1)
    this.criterioGrupoFC.disable()
    this.criterioForm.setValue(formData)
    this.atualizando = true
  }

  alterarSubCriterio(criterioIdx: number, subCriterioIdx: number) {
    const notaMaxima = this.criterios[criterioIdx].subCriterios[subCriterioIdx].notaMaxima

    let formData = {
      id: this.criterios[criterioIdx].subCriterios[subCriterioIdx].id,
      titulo: this.criterios[criterioIdx].subCriterios[subCriterioIdx].titulo,
      notaMaxima: notaMaxima ? notaMaxima.toString().replace('.', ',') : null,
    }

    this.subCriterioForm.setValue(formData)
    this.criterioGrupoFC.setValue(this.criterios[criterioIdx].id)
    this.atualizando = true
  }

  cancelarEdicao() {
    this.resetForms()
    this.criterioGrupoFC.enable()
  }

  copiarCriterios() {
    const concurso = this.concursoRefFC.value as Concurso

    this.provaCriterioService
      .clonar(this.tipoProva!, this.idConcurso!, concurso.id!)
      .subscribe(criterios => (this.criterios = criterios))

    this.concursoRefFC.setValue('')
  }

  excluirCriterio(criterioIdx: number) {
    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `Confirma a exclusão do criterio "${this.criterios[criterioIdx].titulo.substring(0, 16)}"`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) this.removerCriterio(this.criterios[criterioIdx].id)
    })
  }

  excluirSubCriterio(criterioIdx: number, subCriterioIdx: number) {
    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `Confirma a exclusão do subcriterio "${this.criterios[criterioIdx].subCriterios[subCriterioIdx].titulo.substring(
        0,
        16
      )}"`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) this.removerSubCriterio(this.criterios[criterioIdx].subCriterios[subCriterioIdx].id)
    })
  }

  nomeConcursoRef(concurso: Concurso): string {
    return concurso && concurso.titulo ? concurso.titulo : ''
  }

  private _filtroConcurso(nome: string) {
    const filterValue = nome.toLowerCase()
    return this.concursosRef.filter(opt => opt.titulo.toLowerCase().includes(filterValue))
  }

  private initConcursosRef() {
    this.provaCriterioService.listaDeConcursosPorProvaVersao(this.tipoProvaVersao!).subscribe(concursos => {
      const idx = concursos.findIndex(c => c.id == this.idConcurso)
      if (idx != -1) concursos.splice(idx, 1)
      this.concursosRef = concursos
      if (concursos.length > 0) this.initCopiarCriterios()
      else this.concursoRefLabel = 'Sem modelos disponíveis'
    })
  }

  private initCopiarCriterios() {
    this.concursosRefFiltrados = this.concursoRefFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (typeof value === 'string') {
          this.btnCopiar = false
          return value ? this._filtroConcurso(value) : this.concursosRef.slice()
        } else {
          this.btnCopiar = true
          return this.concursosRef.slice()
        }
      })
    )
    this.concursoRefFC.enable()
  }

  dropSubCriterio(event: CdkDragDrop<any[]>, criterioId: number) {
    this.resetForms()
    this.criterioGrupoFC.enable()
    if (event.previousIndex == event.currentIndex) return
    this.provaCriterioService
      .reposicionarSubCriterio(this.tipoProva!, this.idConcurso!, criterioId, event.previousIndex, event.currentIndex)
      .subscribe(updated => (this.criterios = updated))
  }

  dropCriterio(event: CdkDragDrop<any[]>) {
    this.resetForms()
    this.criterioGrupoFC.enable()
    if (event.previousIndex == event.currentIndex) return
    this.provaCriterioService
      .reposicionarCriterio(this.tipoProva!, this.idConcurso!, event.previousIndex, event.currentIndex)
      .subscribe(updated => (this.criterios = updated))
  }

  imprimir() {
    this.provaCriterioService.gerarFichaDeAvaliacao(this.tipoProva!, this.idConcurso!)
  }
}
