<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <p *ngFor="let linha of message">{{ linha }}</p>
</div>

<div mat-dialog-actions class="dialog-action">
  <button type="button" mat-raised-button color="primary" (click)="onConfirm()">{{ confirmacao }}</button>
  <button type="button" mat-button (click)="onDismiss()">{{ negacao }}</button>
</div>
