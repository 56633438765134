/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, map, Observable, startWith, takeLast } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { Perfil } from '../models/lista-perfil'
import { PerfisDoUsuario } from '../models/perfil-por-usuario'

@Injectable({
  providedIn: 'root',
})
export class PerfilService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  //busca todos os perfis para exibir no option
  listarPerfil(): Observable<any> {
    return this.http.get<Perfil>(`${this.baseUrl}/perfil`).pipe(identity, this.errorHandlerService.catchError())
  }

  //busca os usuários de um perfil selecionado
  listaUsuariosDoPerfil(id: number): Observable<Perfil[]> {
    return this.http
      .get<Perfil[]>(`${this.baseUrl}/perfil/${id}/usuario`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  //busca os perfis de um determinado usuário
  listaPerfisDoUsuario(identificacao: string): Observable<PerfisDoUsuario[]> {
    return this.http
      .get<PerfisDoUsuario[]>(`${this.baseUrl}/perfil/${identificacao}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  //exclui um usuario do perfil selecionado no Option
  excluir(idPerfil: number, idUser: number): Observable<Perfil> {
    return this.http
      .delete<Perfil>(`${this.baseUrl}/perfil/${idPerfil}/usuario/${idUser}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  //Adiciona um usuário ao perfil selecionado no Option
  adicionarUsuario(idPerfil: number, identificacao: string) {
    identificacao = identificacao.replace('@unesp.br', '')
    return this.http.post<any>(`${this.baseUrl}/perfil/${idPerfil}/usuario/${identificacao}`, undefined).pipe(
      identity,
      this.errorHandlerService.catchError(),
      map(() => {
        return true
      }),
      startWith(false),
      takeLast(1)
    )
  }
}
