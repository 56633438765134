<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign.gt-md="space-between center">
    <h1>Andamento</h1>
    <p>
      <button mat-raised-button color="primary" (click)="adicionar()"><mat-icon>add</mat-icon> Adicionar</button>
    </p>
  </div>

  <app-andamento-form
    *ngIf="cadastro"
    [idConcurso]="idConcurso"
    [andamento]="andamento"
    (andamentoUpdate)="update($event)">
  </app-andamento-form>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" (keyup)="search($event)" placeholder="" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
        <td mat-cell *matCellDef="let row">
          {{ row.data | date : 'dd/MM/yyyy HH:mm:ss' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
        <td mat-cell *matCellDef="let row">
          {{ row.tipo.toString() }}
        </td>
      </ng-container>
      <ng-container matColumnDef="titulo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Informações</th>
        <td mat-cell *matCellDef="let row">
          <b>{{ row.titulo }}</b>
          <br />{{ row.complemento }}
        </td>
      </ng-container>
      <ng-container matColumnDef="publicacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Publicação</th>
        <td mat-cell *matCellDef="let row">
          {{ row.publicacao | date : 'dd/MM/yyyy' }} - {{ row.pagina }} - {{ row.sessao }}
        </td>
      </ng-container>
      <ng-container matColumnDef="recurso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Recurso</th>
        <td mat-cell *matCellDef="let row">
          {{ row.recursoInicio | date : 'dd/MM/yyyy' }} - {{ row.recursoFim | date : 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button color="basic" [matMenuTriggerFor]="menu" title="Opções">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              title="Anexo"
              (click)="verAnexo('andamento/' + row.id, row.anexo, row.titulo)"
              [disabled]="!row.anexo">
              <mat-icon>attachment</mat-icon><span>Anexo</span>
            </button>
            <button mat-menu-item title="Concluir" (click)="concluir(row, i)" [disabled]="row.andamentoConcluido">
              <mat-icon>check</mat-icon><span>Concluir</span>
            </button>
            <button mat-menu-item title="Editar" (click)="editar(row, i)" [disabled]="row.andamentoConcluido">
              <mat-icon>edit</mat-icon><span>Editar</span>
            </button>
            <button mat-menu-item title="Excluir" (click)="excluir(row, i)" [disabled]="row.andamentoConcluido">
              <mat-icon>delete</mat-icon>Deletar<span></span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
