<mat-card>
  <mat-card-content>
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
      <fieldset fxFlex="100%">
        <legend>Pagamento</legend>

        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Câmpus</mat-label>
              <input matInput [value]="inscricaoPagamento?.concursoCampus" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concurso</mat-label>
              <input matInput [value]="inscricaoPagamento?.concursoTitulo" [disabled]="true" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input matInput [value]="inscricaoPagamento?.candidatoCpf" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Nome</mat-label>
              <input matInput [value]="inscricaoPagamento?.nome" [disabled]="true" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Valor</mat-label>
              <input matInput [value]="inscricaoPagamento?.valor" name="valor" [disabled]="true" />
              <mat-hint>&nbsp;</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Código</mat-label>
              <input matInput [value]="inscricaoPagamento?.depositoCodigo" name="depositoCodigo" [disabled]="true" />
              <mat-hint>&nbsp;</mat-hint>
            </mat-form-field>

            <app-arquivo-upload
              name="fileInput"
              [autoUpload]="true"
              [fc]="anexoFormControl"
              accept="application/pdf"
              uploadPath="{{ 'inscricao/' + inscricaoPagamento?.id + '/pagamento' }}"
              [usarArquivoViewer]="true"
              label="Comprovante"></app-arquivo-upload>
          </div>

          <mat-divider></mat-divider>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Data</mat-label>
              <input
                matInput
                [value]="inscricaoPagamento?.pagoData | date : 'dd/MM/yyyy HH:mm:ss'"
                name="pagoData"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input matInput [value]="inscricaoPagamento?.pagoUsuario" name="pagoUsuario" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concluído</mat-label>
              <input
                matInput
                [value]="inscricaoPagamento?.pagoConcluida ? 'Sim' : 'Não'"
                name="pagoConcluida"
                [disabled]="true" />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Pago</mat-label>
            <mat-select name="pago" formControlName="pago">
              <mat-option value="true">Sim</mat-option>
              <mat-option value="false">Não</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <ng-container *ngIf="isPermitirAlteracao; then permitirAlteracaoBtn; else atualizarConcluirBtns"></ng-container>
      <ng-template #permitirAlteracaoBtn>
        <button
          mat-raised-button
          color="primary"
          (click)="permitirAlteracao()"
          [disabled]="!inscricaoPagamento?.pagoConcluida">
          <mat-icon>edit</mat-icon> Reverter
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>arrow_back</mat-icon> Voltar</button>
      </ng-template>
      <ng-template #atualizarConcluirBtns>
        <button mat-raised-button color="primary" (click)="gravar()" [disabled]="inscricaoPagamento?.pagoConcluida">
          <mat-icon>edit</mat-icon> Atualizar
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="concluir()"
          [disabled]="!isAtualizado || inscricaoPagamento?.pagoData === null || inscricaoPagamento?.pagoConcluida">
          <mat-icon>done</mat-icon> Concluir e notificar
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>close</mat-icon> Cancelar</button>
      </ng-template>
    </div>
  </mat-card-actions>
</mat-card>
