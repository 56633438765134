<div class="consultaForm" fxLayout="column" fxLayoutGap="10px">
  <h1>Consulta Log</h1>

  <div fxLayout.lt-md="column" fxLayout.gt-md="row" fxLayoutGap="10px">
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Nivel</mat-label>
        <mat-select [formControl]="nivelLogFC">
          <mat-option value="TODOS">Todos</mat-option>
          <mat-option value="INFO">Informativo</mat-option>
          <mat-option value="ERROR">Erro</mat-option>
        </mat-select>
        <mat-hint>Tipo de log.</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Intervalo</mat-label>
        <mat-icon class="danger" matPrefix (click)="clearDate()">close</mat-icon>
        <mat-date-range-input [rangePicker]="picker" [max]="dataMaxima" (click)="picker.open()">
          <input readonly matStartDate [formControl]="dataInicialFC" />
          <input readonly matEndDate [formControl]="dataFinalFC" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-hint>Filtrar por intervalo.</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Usuário</mat-label>
        <input matInput [formControl]="usuarioFC" />
        <mat-hint>Login do usuário.</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Operação</mat-label>
        <mat-select [formControl]="tipoAcaoOperacaoFC">
          <mat-option value="TODOS">Todas as Operações</mat-option>
          <mat-option *ngFor="let enum of tipoAcaoOperacao" [value]="enum.acao">
            {{ enum.nome }}
          </mat-option>
        </mat-select>
        <mat-hint>Operação realizada.</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Registro</mat-label>
        <mat-select [formControl]="tipoAcaoRefToFC">
          <mat-option value="TODOS">Todos os Registros</mat-option>
          <mat-option *ngFor="let enum of tipoAcaoRefTo" [value]="enum.registro">
            {{ enum.nome }}
          </mat-option>
        </mat-select>
        <mat-hint>Registro afetado.</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayoutAlign="end end">
      <span>{{ msgSelecionado }}</span>
    </div>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
      <button mat-raised-button color="primary" (click)="consultarBtnClick()">
        <mat-icon>check_circle_outline</mat-icon> Consultar
      </button>
      <!-- <button mat-raised-button color="primary" (click)="exportar()">
        <mat-icon>save_alt</mat-icon> Exportar
      </button> -->
    </div>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="nivel">
        <th mat-header-cell *matHeaderCellDef>Nível</th>
        <td mat-cell *matCellDef="let row">{{ row.nivel }}</td>
      </ng-container>
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>Data e Hora</th>
        <td mat-cell *matCellDef="let row">{{ row.dataHora | date : 'dd/MM/yyyy - HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="ip">
        <th mat-header-cell *matHeaderCellDef>IP</th>
        <td mat-cell *matCellDef="let row">
          <a class="queryLink" (click)="queryIP(row.ip)">{{ row.ip }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Login do Usuário</th>
        <td mat-cell *matCellDef="let row">
          <a class="queryLink" (click)="queryUser(row.username)">{{ row.username }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="acao">
        <th mat-header-cell *matHeaderCellDef>Ação</th>
        <td mat-cell *matCellDef="let row">
          {{ row.textoAcao.acao }}
          <a class="queryLink" (click)="queryRegistro(row)">{{ row.textoAcao.registro }}</a>
          {{ row.textoAcao.tipoPai }}
          <a class="queryLink" (click)="queryRegistroPai(row)">{{ row.textoAcao.pai }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="botao">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            color="basic"
            title="Detalhes"
            [disabled]="!row.temMaisDetalhes"
            (click)="abrirDetalhes(row)">
            <mat-icon>content_paste_search</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">
          <span>Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="25"
      [pageSizeOptions]="[25, 50, 100, 150]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
