/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

export enum TipoAndamento {
  ABERTURA = 'Abertura de Inscrições',
  CANCELADO = 'Cancelado',
  COMUNICADO = 'Comunicado',
  CONVOCACAO = 'Convocação para as Provas',
  DEFERIMENTO_INSCRICOES = 'Analise das Inscrições',
  LISTA_DEFINITIVA_INSCRITOS = 'Lista Definitiva de Inscritos e Abertura de Prazo para Impugnação de Banca  ',
  GABARITO = 'Divulgação do Gabarito e Caderno de Questões',
  HOMOLOGACAO = 'Homologação',
  IMPUGNACAO_COMISSAO = 'Divulgação da Comissão Examinadora',
  REDUCAO = 'Resultado da Redução de Taxa',
  RESULTADO_FASE_1 = 'Resultado da Fase 1',
  RESULTADO_FASE_2 = 'Resultado da Fase 2',
  RESULTADO_FINAL = 'Resultado Final Preliminar',
  RESULTADO_FINAL_DEFINITIVO = 'Resultado Final Definitivo',
  RESULTADO_RECURSO = 'Resultado de Análise de Recurso',
  RETIFICACAO = 'Retificação de Edital',
  REVOGADO = 'Edital de Revogação',
  CONVOCACAO_ANUENCIA = 'Convocação para Anuência do Candidato',
  ANALISE_INSCRICOES_PPI = 'Análise das Inscrições como PPI',
  RESULTADO_BANCA_PPI = 'Resultado de Análise de Banca de Heteroidentificação',
  RESULTADO_DOC_INDIGENAS = 'Resultado de Análise de Documentos - Indígena',
}

export function getAndamentoByDescription(value: string) {
  const indexOfS = Object.values(TipoAndamento).indexOf(value as unknown as TipoAndamento)
  const key = Object.keys(TipoAndamento)[indexOfS]
  return key
}

export interface TipoAndamentoDetalhe {
  recurso: boolean
  doe: boolean
  prazo: number
}

export const TipoAndamentoDetalhes = new Map<string, TipoAndamentoDetalhe>([
  ['ABERTURA', { recurso: false, doe: true, prazo: 0 }],
  ['CANCELADO', { recurso: false, doe: true, prazo: 0 }],
  ['COMUNICADO', { recurso: false, doe: true, prazo: 0 }],
  ['CONVOCACAO', { recurso: false, doe: true, prazo: 0 }],
  ['DEFERIMENTO_INSCRICOES', { recurso: true, doe: true, prazo: 5 }],
  ['LISTA_DEFINITIVA_INSCRITOS', { recurso: true, doe: true, prazo: 2 }],
  ['GABARITO', { recurso: true, doe: true, prazo: 3 }],
  ['HOMOLOGACAO', { recurso: false, doe: true, prazo: 0 }],
  ['IMPUGNACAO_COMISSAO', { recurso: true, doe: true, prazo: 2 }],
  ['REDUCAO', { recurso: true, doe: false, prazo: 3 }],
  ['RESULTADO_FASE_1', { recurso: true, doe: true, prazo: 2 }],
  ['RESULTADO_FASE_2', { recurso: true, doe: true, prazo: 2 }],
  ['RESULTADO_FINAL', { recurso: true, doe: true, prazo: 5 }],
  ['RESULTADO_FINAL_DEFINITIVO', { recurso: false, doe: true, prazo: 0 }],
  ['RESULTADO_RECURSO', { recurso: false, doe: true, prazo: 0 }],
  ['RETIFICACAO', { recurso: false, doe: true, prazo: 0 }],
  ['REVOGADO', { recurso: false, doe: true, prazo: 0 }],
  ['CONVOCACAO_ANUENCIA', { recurso: false, doe: true, prazo: 0 }],
  ['ANALISE_INSCRICOES_PPI', { recurso: true, doe: true, prazo: 3 }],
  ['RESULTADO_BANCA_PPI', { recurso: true, doe: true, prazo: 3 }],
  ['RESULTADO_DOC_INDIGENAS', { recurso: true, doe: true, prazo: 3 }],
])
