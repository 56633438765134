/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

/**
 * @description
 *
 * Interface utilizada para montagem dos itens de menu da aplicação. \
 * Os atributos displayName, iconName e route determinam um item único. \
 * Itens com mesmo displayName, iconName e route são considerados iguais e agrupados (as roles são agrupadas). \
 * Um item que possui filhos tem sua route ignorada, pois não é clicável.
 *
 * Se roles não existir no primeiro nível (N), o menu não é exibido. \
 * Se roles não existir no segundo nível (N+1) em diante, o menu assume todas as roles do nível anterior (N).
 *
 * As roles do nível N+1 devem ser um subconjunto de roles do nível N, podendo ser total ou parcial. \
 * Roles do nível N+1 devem respeitar a hierarquia de roles do nível N. \
 * Roles do nível N+1 que não existam no nível N, são removidas e ignoradas.
 *
 * A role: '**' é considerada menu público e sempre será exibido (mesmo sem usuário logado).
 * - Todos os sub menus de um menu público são também públicos, roles são ignoradas.
 *
 * A role: '!!' é considerada menu irrestrito para todos usuários (precisando apenas estar logado).
 * - Todos os sub menus de um menu irrestrito são também irrestritos, roles são ignoradas.
 *
 * @displayName : Texto que irá aparecer.
 * @iconName : Nome do ícone (GoogleFonts Material Icons).
 * @route : Rota.
 * @roles : Roles permitidas para ser exibido.
 * @disabled : Indica se item será desabilitado (exibido com aspecto desabilitado, não clicável).
 * @hidden : Indica se item será escondido (não irá aparecer no menu).
 * @addTopDivider : Indica se deve adicionar um divisor no topo do item.
 * @addBottomDivider : Indica se deve adicionar um divisor no final do item.
 * @order : Ordem de exibição no mesmo nível hierárquico.
 * @smallSize : Indica se o item será exibido em tamanho pequeno (icone menor, vertical menor).
 * @children : Sub itens.
 *
 * Material Icons: https://fonts.google.com/icons?icon.set=Material+Icons
 */
export abstract class UnespCoreMenuItem {
  displayName: string = '';
  roles: string[] = [];
  iconName: string = '';
  route?: string = '';
  disabled?: boolean = false;
  hidden?: boolean = false;
  addTopDivider?: boolean = false;
  addBottomDivider?: boolean = false;
  order?: number = 9999;
  smallSize?: boolean = false;
  children?: UnespCoreMenuItem[];
}

/**
 * Wildcard usado para role publica, acesso sem necessidade de login.
 */
export const ROLE_WILDCARD_PUBLIC = '**';

/**
 * Wildcard usado para role irrestrita (apenas usuários), acesso com necessidade de login.
 */
export const ROLE_WILDCARD_ONLYUSERS = '!!';
