<mat-card>
  <mat-card-content>
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
      <fieldset fxFlex="100%">
        <legend>Situação</legend>

        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Câmpus</mat-label>
              <input matInput [value]="inscricaoSituacao?.concursoCampus" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concurso</mat-label>
              <input matInput [value]="inscricaoSituacao?.concursoTitulo" [disabled]="true" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input matInput [value]="inscricaoSituacao?.candidatoCpf" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Nome</mat-label>
              <input matInput [value]="inscricaoSituacao?.nome" [disabled]="true" />
            </mat-form-field>
          </div>

          <mat-divider></mat-divider>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Data</mat-label>
              <input
                matInput
                [value]="inscricaoSituacao?.statusData | date : 'dd/MM/yyyy HH:mm:ss'"
                name="statusData"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input matInput [value]="inscricaoSituacao?.statusUsuario" name="statusUsuario" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concluído</mat-label>
              <input
                matInput
                [value]="inscricaoSituacao?.statusConcluida ? 'Sim' : 'Não'"
                name="statusConcluida"
                [disabled]="true" />
            </mat-form-field>
          </div>

          <mat-divider></mat-divider>

          <mat-form-field appearance="outline">
            <mat-label>Inscrição Submetida</mat-label>
            <mat-select name="submeteInscricao" formControlName="submeteInscricao">
              <mat-option value="true">Sim</mat-option>
              <mat-option value="false">Não</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Situação</mat-label>
            <mat-select name="status" formControlName="status">
              <mat-option *ngFor="let type of situacaoInscricao | keyvalue" [value]="type.key">
                {{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Motivo</mat-label>
            <input matInput formControlName="statusMotivo" name="statusMotivo" />
          </mat-form-field>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <ng-container *ngIf="isPermitirAlteracao; then permitirAlteracaoBtn; else atualizarConcluirBtns"></ng-container>
      <ng-template #permitirAlteracaoBtn>
        <button
          mat-raised-button
          color="primary"
          (click)="permitirAlteracao()"
          [disabled]="!inscricaoSituacao?.statusConcluida">
          <mat-icon>edit</mat-icon> Reverter
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>arrow_back</mat-icon> Voltar</button>
      </ng-template>
      <ng-template #atualizarConcluirBtns>
        <button mat-raised-button color="primary" (click)="gravar()" [disabled]="inscricaoSituacao?.statusConcluida">
          <mat-icon>edit</mat-icon> Atualizar
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="concluir()"
          [disabled]="!isAtualizado || inscricaoSituacao?.statusData === null || inscricaoSituacao?.statusConcluida">
          <mat-icon>done</mat-icon> Concluir e notificar
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>close</mat-icon> Cancelar</button>
      </ng-template>
    </div>
  </mat-card-actions>
</mat-card>
