<div fxLayout="column" fxLayoutGap="20px">
  <h1>Permitir Alteração</h1>
  <div fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Tipo de Registro</mat-label>
        <mat-select required [formControl]="tipoRegistroFC">
          <mat-option *ngFor="let enum of tipoRegistro | keyvalue" [value]="enum.key">
            {{ enum.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="3 1 0">
      <mat-form-field appearance="outline">
        <mat-label>Unidade</mat-label>
        <input
          type="text"
          matInput
          [formControl]="unidadeFC"
          [matAutocomplete]="autoUnidade"
          onClick="this.select();" />
        <mat-autocomplete autoActiveFirstOption #autoUnidade="matAutocomplete" [displayWith]="nomeUnidade">
          <mat-option *ngFor="let opt of unidadesFiltradas | async" [value]="opt">
            {{ opt.nome }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Concurso</mat-label>
      <input
        type="text"
        matInput
        [formControl]="concursoFC"
        [matAutocomplete]="autoConcurso"
        onClick="this.select();" />
      <mat-autocomplete autoActiveFirstOption #autoConcurso="matAutocomplete" [displayWith]="tituloConcurso">
        <mat-option *ngFor="let opt of concursosFiltrados | async" [value]="opt">
          {{ opt.titulo }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <h1>Registros</h1>
  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="id">#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>

      <!--Andamento-->
      <ng-container matColumnDef="andamento_1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="andamento.titulo">Dados da Andamento</th>
        <td mat-cell *matCellDef="let row">
          <p><b>Tipo:</b> {{ row.tipo }}</p>
          <p><b>Tipo:</b> {{ row.andamento.tipo }}</p>
          <p><b>Titulo:</b> {{ row.andamento.titulo }}</p>
          <p><b>Complemento:</b> {{ row.andamento.complemento }}</p>
          <p><b>Data:</b> {{ row.andamento.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="andamento_2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="andamento.dataPublicacao">Publicação</th>
        <td mat-cell *matCellDef="let row">
          <p><b>Publicação:</b> {{ row.andamento.dataPublicacao | date : 'dd/MM/yyyy' }}</p>
          <p><b>Pagina:</b> {{ row.andamento.pagina }} <b> Sessão:</b> {{ row.andamento.sessao }}</p>
          <p><b>Recurso Início:</b> {{ row.andamento.recursoInicio | date : 'dd/MM/yyyy' }}</p>
          <p><b>Recurso Fim:</b> {{ row.andamento.recursoFim | date : 'dd/MM/yyyy' }}</p>
        </td>
      </ng-container>

      <!--Recurso-->
      <ng-container matColumnDef="recurso_0">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="recurso.protocolo">Protocolo</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.recurso.protocolo }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="recurso_1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="recurso.nome">Dados da Recurso</th>
        <td mat-cell *matCellDef="let row">
          <p><b>Tipo:</b> {{ row.recurso.tipo }}</p>
          <p><b>Data:</b> {{ row.recurso.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
          <p><b>Nome:</b>{{ row.candidato.nome }}</p>
          <p><b>CPF:</b> {{ row.candidato.cpf }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="recurso_2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="recurso.parecerUsuario">Parecer</th>
        <td mat-cell *matCellDef="let row">
          <p><b>Parecer:</b> {{ row.recurso.parecer }}</p>
          <p><b>Usuário:</b> {{ row.recurso.parecerUsuario }}</p>
          <p><b>Data:</b> {{ row.recurso.parecerData | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
        </td>
      </ng-container>

      <!--Inscrição-->
      <ng-container matColumnDef="dados_inscricao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="nome">Dados da inscrição</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.candidato.nome }}</p>
          <p><b>CPF:</b> {{ row.candidato.cpf }}</p>
        </td>
      </ng-container>

      <!--Deferimento-->
      <ng-container matColumnDef="deferimento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status.usuario">Deferimento</th>
        <td mat-cell *matCellDef="let row">
          <p><b>Status:</b> {{ row.status.status }}</p>
          <p><b>Usuário:</b> {{ row.status.usuario }}</p>
          <p><b>Data:</b> {{ row.status.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
        </td>
      </ng-container>

      <!--Pagamento-->
      <ng-container matColumnDef="pagamento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="pagamento.usuario">Pagamento</th>
        <td mat-cell *matCellDef="let row">
          <p><b>Pago:</b> {{ row.pagamento.pago ? 'Sim' : 'Não' }}</p>
          <p><b>Usuário:</b> {{ row.pagamento.usuario }}</p>
          <p><b>Data:</b> {{ row.pagamento.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
        </td>
      </ng-container>

      <!--Redução-->
      <ng-container matColumnDef="reducao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="reducao.usuario">Redução</th>
        <td mat-cell *matCellDef="let row">
          <p><b>Aprovada:</b> {{ row.reducao.aprovada ? 'Sim' : 'Não' }}</p>
          <p><b>Usuário:</b> {{ row.reducao.usuario }}</p>
          <p><b>Data:</b> {{ row.reducao.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
        </td>
      </ng-container>

      <!--Concurso Prova-->
      <ng-container matColumnDef="prova_0">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="concursoProva.tipoProva">Tipo Prova</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ tipoProva[row.concursoProva.tipoProva].nome }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="prova_1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="concursoProva.provaPasso">Etapa atual</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ provaEtapa[row.concursoProva.provaEtapa] }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="prova_action">
        <th mat-header-cell *matHeaderCellDef>Etapas anteriores</th>
        <td mat-cell *matCellDef="let row; let i = index" style="min-width: 215px">
          <button
            mat-stroked-button
            color="basic"
            (click)="mudarParaEdicao(row)"
            [disabled]="row.concursoProva.provaEtapa === 'EDICAO'">
            1ª - Edição
          </button>
          &nbsp;&nbsp;
          <button
            mat-stroked-button
            color="basic"
            (click)="mudarParaPublicada(row)"
            [disabled]="row.concursoProva.provaEtapa === 'EDICAO' || row.concursoProva.provaEtapa === 'PUBLICADA'">
            2ª - Publicada
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index" style="min-width: 215px">
          <button mat-stroked-button color="basic" (click)="abrirNovaAba(row.tipo.toLowerCase(), row.id)">
            Permitir Alteração
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5"><span>Nenhum registro disponível</span></td>
      </tr>
    </table>
  </div>
</div>
