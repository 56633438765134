/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InscricaoSituacaoService } from 'src/app/services/inscricao-situacao.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute } from '@angular/router'
import { InscricaoSituacao } from 'src/app/models/inscricao-situacao'
import { SituacaoInscricao } from 'src/app/enums/situacao-inscricao'
import { PermitirAlteracaoService } from 'src/app/services/permitir-alteracao.service'

@Component({
  selector: 'app-inscricao-situacao',
  templateUrl: './inscricao-situacao.component.html',
  styleUrls: ['./inscricao-situacao.component.scss'],
})
export class InscricaoSituacaoComponent implements OnInit {
  isPermitirAlteracao: boolean
  isAtualizado: boolean
  id?: number
  inscricaoSituacao?: InscricaoSituacao
  situacaoInscricao = SituacaoInscricao

  form = new FormGroup({
    status: new FormControl('', Validators.required),
    statusMotivo: new FormControl(''),
    submeteInscricao: new FormControl(''),
  })

  constructor(
    private inscricaoSituacaoService: InscricaoSituacaoService,
    private permitirAlteracaoService: PermitirAlteracaoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute
  ) {
    this.isPermitirAlteracao = this.route.snapshot.url[0].toString() == 'permitir-alteracao'
    this.isAtualizado = false
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.inscricaoSituacaoService.get(this.id).subscribe(obj => {
          this.inscricaoSituacao = obj
          if (obj.submeteInscricao !== null)
            this.form.get('submeteInscricao')?.setValue(obj.submeteInscricao.toString())
          this.form.get('status')?.setValue(obj.status)
          this.form.get('statusMotivo')?.setValue(obj.statusMotivo)
          this.isAtualizado = true

          this.form
            .get('status')
            ?.valueChanges.subscribe(
              value =>
                (this.isAtualizado =
                  this.inscricaoSituacao?.status == value &&
                  this.inscricaoSituacao?.statusMotivo == this.form.get('statusMotivo')?.value)
            )

          this.form
            .get('statusMotivo')
            ?.valueChanges.subscribe(
              value =>
                (this.isAtualizado =
                  this.inscricaoSituacao?.statusMotivo == value &&
                  this.inscricaoSituacao?.status == this.form.get('status')?.value)
            )

          if (this.inscricaoSituacao.statusConcluida || this.isPermitirAlteracao) {
            this.form.disable()
          }
        })
      }
    })
  }

  gravar(): void {
    if (this.id && !this.inscricaoSituacao?.statusConcluida) {
      let formulario = <InscricaoSituacao>this.form.value
      if (formulario.status == 'INDEFERIDA' && (formulario.statusMotivo == null || formulario.statusMotivo == '')) {
        this.unespCoreMessageService.showMessageError(`É preciso informar o Motivo`)
      } else {
        this.inscricaoSituacaoService.alterar(this.id, formulario).subscribe(inscricaoSituacao => {
          this.inscricaoSituacao = inscricaoSituacao
          this.unespCoreMessageService.showMessageSuccess(`Situação #${this.id} atualizada com sucesso`)
          this.isAtualizado =
            this.inscricaoSituacao?.status == this.form.get('status')?.value &&
            this.inscricaoSituacao?.statusMotivo == this.form.get('statusMotivo')?.value
        })
      }
    }
  }

  concluir(): void {
    if (this.id && !this.inscricaoSituacao?.statusConcluida) {
      this.inscricaoSituacaoService.concluir(this.id).subscribe(inscricaoSituacao => {
        this.inscricaoSituacao = inscricaoSituacao
        this.unespCoreMessageService.showMessageSuccess(`Situação #${this.id} concluído com sucesso`)
        this.form.disable()
      })
    }
  }

  permitirAlteracao() {
    if (this.id && this.isPermitirAlteracao && this.inscricaoSituacao) {
      this.permitirAlteracaoService.liberarRegistro('deferimento', this.id).subscribe(registro => {
        this.inscricaoSituacao!.statusConcluida = registro.status.concluido
        this.unespCoreMessageService.showMessageSuccess(`Situação #${this.id} revertido para não concluído`)
      })
    }
  }
}
