/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { TipoRecurso } from '../models/tipo-recurso'

@Injectable({
  providedIn: 'root',
})
export class TipoRecursoService {
  get(id: number) {
    throw new Error('Method not implemented.')
  }

  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  listar(search = '', page = 0, size = 20): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/tipo-recurso`, {
        params: new HttpParams().set('search', search).set('page', page.toString()).set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  editar(idRecurso: number): Observable<any> {
    return this.http
      .get<TipoRecurso>(`${this.baseUrl}/tipo-recurso/editar/${idRecurso}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  alteraDadosTipoRecurso(idRecurso: number, formTipoRecurso: TipoRecurso): Observable<TipoRecurso> {
    return this.http
      .put<TipoRecurso>(`${this.baseUrl}/tipo-recurso/salvar/${idRecurso}`, formTipoRecurso)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  incluir(formTipoRecurso: TipoRecurso): Observable<TipoRecurso> {
    return this.http
      .put<TipoRecurso>(`${this.baseUrl}/tipo-recurso/incluir/`, formTipoRecurso)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  excluir(idRecurso: number): Observable<TipoRecurso> {
    return this.http
      .delete<TipoRecurso>(`${this.baseUrl}/tipo-recurso/excluir/${idRecurso}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
