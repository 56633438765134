/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { getClassificacaoInscricaoByDescription, ClassificacaoInscricao } from '../../../enums/classificacao-inscricao'
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ListagensDiversasService } from '../../../services/listagens-diversas.service'
import { ActivatedRoute } from '@angular/router'
import { ListagemClassificacao } from 'src/app/models/listagem-classificacao'
import { DatePipe } from '@angular/common'
import { UnespCoreMessageService } from 'src/libs/unesp-core'

@Component({
  selector: 'app-classificacao-inscricao',
  templateUrl: './classificacao-inscricao.component.html',
  styleUrls: ['./classificacao-inscricao.component.scss'],
})
export class ClassificacaoInscricaoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef

  totalElements: number = 0

  colunasGrid: string[] = ['nome', 'cpf', 'situacao', 'pago', 'classificacao', 'posicao', 'autor', 'data', 'action']
  dataSource: MatTableDataSource<ListagemClassificacao> = new MatTableDataSource()

  idConcurso?: number
  filtro: string = ''

  datepipe: DatePipe = new DatePipe('pt-BR')

  optClassificacao = ClassificacaoInscricao

  constructor(
    private listagensDiversasService: ListagensDiversasService,
    private route: ActivatedRoute,
    private unespCoreMessageService: UnespCoreMessageService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['id']
      this.getData()
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
  }

  concluir(row: ListagemClassificacao) {
    this.listagensDiversasService.concluirClassificacaoInscricao(this.idConcurso, row.id).subscribe(value => {
      if (value) {
        row.concluida = true
        this.unespCoreMessageService.showMessageSuccess(`${row.nome}, classificação concluída com sucesso`)
      } else {
        this.unespCoreMessageService.showMessageError(`Falha ao concluir, ${row.nome}`)
      }
    })
  }

  salvar(row: ListagemClassificacao) {
    if (row.situacao != 'Deferida' && row.classificacao == 'HABILITADO') {
      this.unespCoreMessageService.showMessageError(
        `Não é permitido Habilitar candidato com a inscrição ${row.situacao}`
      )
    } else if (!row.pago && row.classificacao == 'HABILITADO') {
      this.unespCoreMessageService.showMessageError(`Não é permitido Habilitar candidato sem pagamento`)
    } else if (row.classificacao == 'NAO_AVALIADO') {
      this.unespCoreMessageService.showMessageWarning(`Informe a classificação do candidato ${row.nome}`)
    } else if (row.classificacao == 'HABILITADO' && !row.posicao) {
      this.unespCoreMessageService.showMessageWarning(`Informe a posição da classificação do candidato ${row.nome}`)
    } else {
      this.listagensDiversasService
        .salvarClassificacaoInscricao(this.idConcurso, row.id, row.classificacao, row.posicao ?? '')
        .subscribe(value => {
          if (value) {
            row.autor = value.autor
            row.motivo = value.motivo
            row.posicao = value.posicao
            row.data = value.data
            this.unespCoreMessageService.showMessageSuccess(`${row.nome}, classificação registrada com sucesso`)
          } else {
            this.unespCoreMessageService.showMessageError(`Falha ao registrar, ${row.nome}`)
          }
        })
    }
  }

  private getData() {
    this.listagensDiversasService
      .getClassificacaoInscricao(
        this.idConcurso!,
        this.input?.nativeElement.value,
        this.filtro,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
      .subscribe(data => {
        let inscricoes = data['content'] as ListagemClassificacao[]
        this.dataSource = new MatTableDataSource(
          inscricoes.map(value => {
            value.classificacao = getClassificacaoInscricaoByDescription(value.classificacao)
            return value
          })
        )
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
  }

  exportar() {
    this.listagensDiversasService
      .getClassificacaoInscricao(this.idConcurso!, this.input?.nativeElement.value, this.filtro, 0, this.totalElements)
      .subscribe(data => {
        let recursos: ListagemClassificacao[] = data['content']
        let content = `"#","NOME","CPF","PROTOCOLO","PAGO","CLASSIFICAÇÃO","POSIÇÃO","AUTOR","DATA"\r\n`
        recursos.forEach(row => {
          content += `"${row.id}","${row.nome}","${row.cpf}","${row.protocolo}","${row.pago ? 'Sim' : 'Não'}","${
            row.classificacao
          }","${row.posicao ?? ''}","${row.autor ?? ''}","${
            row.data === null ? '' : this.datepipe.transform(row.data, 'dd/MM/YYYY HH:mm:ss')
          }"\r\n`
        })
        let binaryData = []
        binaryData.push(content)
        let downloadLink = document.createElement('a')
        downloadLink.setAttribute('download', `listagem/classificacao-${this.idConcurso}.csv`)
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csvcharset=utf-8' }))
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      })
  }

  selecionar(): void {
    this.getData()
  }
}
