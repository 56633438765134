/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Pipe, PipeTransform } from '@angular/core'
import { DecimalPipe } from '@angular/common'

@Pipe({
  name: 'numberRound',
})
export class NumberRoundPipe extends DecimalPipe implements PipeTransform {
  override transform(
    value: string | number,
    digitsInfo?: string | undefined,
    locale?: string | undefined
  ): string | null
  override transform(value: null | undefined, digitsInfo?: string | undefined, locale?: string | undefined): null
  override transform(
    value: string | number | null | undefined,
    digitsInfo?: string | undefined,
    locale?: string | undefined
  ): string | null {
    if (value === undefined || value === null) return null

    if (digitsInfo === undefined) return super.transform(value, digitsInfo, locale)

    const digitsInfoArr = digitsInfo.split('-')

    if (digitsInfoArr.length < 3) return super.transform(value, digitsInfo, locale)

    const valueFloat = Number.parseFloat(value?.toString())
    const digits = Number(digitsInfoArr[1])
    const roundingMode = digitsInfoArr[2]

    digitsInfo = digitsInfoArr[0] + '-' + digitsInfoArr[1]

    if (Number.isNaN(valueFloat) || Number.isNaN(digits)) return super.transform(value, digitsInfo, locale)

    let roundedValue = valueFloat * 10 ** digits

    switch (roundingMode) {
      case 'round':
        roundedValue = Math.round(roundedValue)
        break
      case 'ceil':
        roundedValue *= 10 ** digits
        roundedValue = Math.round(roundedValue)
        roundedValue /= 10 ** digits
        roundedValue = Math.ceil(roundedValue)
        break
      case 'floor':
        roundedValue *= 10 ** digits
        roundedValue = Math.round(roundedValue)
        roundedValue /= 10 ** digits
        roundedValue = Math.floor(roundedValue)
        break
      case 'trunc':
        roundedValue = Math.trunc(roundedValue)
    }

    roundedValue /= 10 ** digits

    return super.transform(roundedValue, digitsInfo, locale)
  }
}
