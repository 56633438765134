/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { UsuarioCandidato } from 'src/app/models/usuario-candidato'
import { UsuarioCandidatoService } from 'src/app/services/usuario-candidato.service'
import { MatTableDataSource } from '@angular/material/table'
import { Inscricao } from 'src/app/models/inscricao'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap, timeout } from 'rxjs'
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-usuario-candidato-vincular-contas',
  templateUrl: './usuario-candidato-vincular-contas.component.html',
  styleUrls: ['./usuario-candidato-vincular-contas.component.css'],
})
export class UsuarioCandidatoVincularContasComponent implements OnInit {
  @ViewChild('paginator', { static: true }) public paginator!: MatPaginator
  @ViewChild('paginatorAtual', { static: true }) public paginatorAtual!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild(MatSort) sortAtual!: MatSort
  @ViewChild('input') input!: ElementRef
  @ViewChild('inputAtual') inputAtual!: ElementRef
  totalElements: number = 0
  totalElementsAtual: number = 0
  id?: string
  usuarioCandidato?: UsuarioCandidato

  form = new FormGroup({
    emailAtual: new FormControl('', Validators.required),
    novoEmail: new FormControl('', Validators.required),
  })

  colunasGridAtual: string[] = ['candidato', 'concurso']
  colunasGrid: string[] = ['candidato', 'concurso']
  dataSource: MatTableDataSource<Inscricao> = new MatTableDataSource()
  dataSourceAtual: MatTableDataSource<Inscricao> = new MatTableDataSource()
  email: string = ''

  constructor(
    private usuarioCandidatoService: UsuarioCandidatoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.usuarioCandidatoService.get(Number(this.id)).subscribe(obj => {
          this.usuarioCandidato = obj
          this.form.get('emailAtual')?.setValue(obj.email)
          this.email = obj.email
        })
        this.getData()
        this.getDataAtual()
      }
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.paginatorAtual.pageIndex = 0
          this.getData()
          this.getDataAtual()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
    setTimeout(() => {
      this.getData()
    }, 0)

    this.paginatorAtual?.page.pipe(tap(() => this.getDataAtual())).subscribe()
    setTimeout(() => {
      this.getDataAtual()
    }, 0)
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }
  gravar(): void {
    this.applyTouchedToInvalidFields()
    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }
    if (this.form.get('emailAtual')?.value == this.form.get('novoEmail')?.value) {
      this.unespCoreMessageService.showMessageError('Os e-mails informados são iguais')
      return
    }

    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `O e-mail ${this.form.get('emailAtual')?.value}
      será excluido e somente o e-mail ${this.form.get('novoEmail')?.value} será acessível.
      Confirmar a vinculação?`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        if (this.id && this.usuarioCandidato) {
          let jsonData = {
            emailAtual: this.form.get('emailAtual')?.value,
            novoEmail: this.form.get('novoEmail')?.value,
          }

          this.usuarioCandidatoService.vincularEmail(Number(this.id), jsonData).subscribe(res => {
            if (res == 'email_inexistente') {
              this.unespCoreMessageService.showMessageError('E-mail não cadastrado na base de dados')
              return
            } else if (res == 'atualizado') {
              this.unespCoreMessageService.showMessageSuccess('E-mail vinculado com sucesso')
              this.router.navigate(['/usuario-candidato'])
              return
            } else {
              this.unespCoreMessageService.showMessageError(
                'Não foi possível alterar, verique os valores e tente novamente'
              )
              return
            }
          })
        } else {
          this.unespCoreMessageService.showMessageError('Houve um problema ao salvar os novos dados de e-mail')
          return
        }
      } else {
        this.unespCoreMessageService.showMessageError('Não houve alteração')
        return
      }
    })
  }

  public getData() {
    this.usuarioCandidatoService.listarInscricoesPorEmail(this.form.get('novoEmail')?.value).subscribe(data => {
      this.dataSource = new MatTableDataSource(data['content'])
      this.dataSource.sort = this.sort
      this.totalElements = data['totalElements']
      this.dataSource.paginator = this.paginator
    })
  }

  public getDataAtual() {
    this.usuarioCandidatoService.listarInscricoesPorEmail(this.form.get('emailAtual')?.value).subscribe(dataAtual => {
      this.dataSourceAtual = new MatTableDataSource(dataAtual['content'])
      this.dataSourceAtual.sort = this.sortAtual
      this.totalElementsAtual = dataAtual['totalElementsAtual']
      this.dataSourceAtual.paginator = this.paginatorAtual
    })
  }
}
