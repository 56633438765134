/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ConcursoService } from 'src/app/services/concurso.service'
import { UnespCoreAuthService, UnespCoreMessageService, UnespCoreUserService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { Concurso, Tema } from 'src/app/models/concurso'
import { inputValitador } from 'src/app/utils/input-valitador'
import {
  ArquivoUploadComponent,
  ArquivoSelecionado,
  arquivoUploadValidator,
  ArquivoStatus,
} from 'src/app/modules/arquivo-upload/arquivo-upload.component'
import { TipoConcurso, TipoConcursoDTA } from 'src/app/enums/tipo-concurso'
import { Usuario } from 'src/app/models/usuario'
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes'
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips'
import { MatTableDataSource } from '@angular/material/table'
import { IPendingChanges } from 'src/app/utils/pending-changes'
import { Campus } from 'src/app/models/campus'

@Component({
  selector: 'app-cadastro-concurso',
  templateUrl: './cadastro-concurso.component.html',
  styleUrls: ['./cadastro-concurso.component.css'],
})
export class CadastroConcursoComponent implements OnInit, IPendingChanges {
  readonly chipsSeparatorKeys = [ENTER, COMMA, SPACE] as const
  responsaveis: string[] = []

  @ViewChild('upload') upload: ArquivoUploadComponent | undefined
  @ViewChild('responsaveisChipList') responsaveisChipList: MatChipGrid | undefined

  editalUploadPath: string = 'edital/'
  editalUploadFc: FormControl<ArquivoSelecionado>

  colunasPontoGrid: string[] = ['data', 'titulo', 'action']
  pontosDataSource: MatTableDataSource<Tema> = new MatTableDataSource()
  hasPontos: boolean = false
  pontoInput = new FormControl('')
  editarPontoIdx: number = -1
  alteracoesNosPontos: boolean = false

  tipoConcurso = TipoConcurso
  tipoConcursoDTA = TipoConcursoDTA
  campusHabilitados: Campus[] = []

  numeroDespachoPattern: string = '(^[0-9]{1,5}\\/\\d{4}$)'

  id?: number
  titulo = new FormControl('', Validators.required)
  descricao = new FormControl('', Validators.required)
  tipo = new FormControl('', Validators.required)
  uuid = new FormControl('', Validators.required)
  inicio = new FormControl(new Date(), Validators.required)
  fim = new FormControl(new Date(), Validators.required)
  inicioFase2 = new FormControl(new Date())
  fimFase2 = new FormControl(new Date())
  horaFim = new FormControl('', [Validators.required, inputValitador(/\b([01][0-9]|2[0-3]):([0-5][0-9])\b/gm)])
  vagas = new FormControl('')
  valorInscricao = new FormControl('', Validators.required)
  edital = new FormControl('')
  editalSize = new FormControl('')
  salario = new FormControl('')
  destaque = new FormControl('')
  resumo = new FormControl('')
  ativo = new FormControl('')
  online = new FormControl('')
  responsaveisRecurso = new FormControl('', Validators.email)
  andamento = new FormControl('')
  habilitarDevolucao = new FormControl('')
  processo = new FormControl('')
  numeroContad = new FormControl('')
  numeroDespacho = new FormControl('', [Validators.required, Validators.pattern(this.numeroDespachoPattern)])
  contatoEmail = new FormControl('', Validators.required)
  emailDepartamento = new FormControl('')
  contatoTelefone = new FormControl('')

  form = new FormGroup({
    titulo: this.titulo,
    descricao: this.descricao,
    tipo: this.tipo,
    uuid: this.uuid,
    inicio: this.inicio,
    fim: this.fim,
    inicioFase2: this.inicioFase2,
    fimFase2: this.fimFase2,
    horaFim: this.horaFim,
    vagas: this.vagas,
    valorInscricao: this.valorInscricao,
    edital: this.edital,
    editalSize: this.editalSize,
    salario: this.salario,
    destaque: this.destaque,
    resumo: this.resumo,
    ativo: this.ativo,
    online: this.online,
    responsaveisRecurso: this.responsaveisRecurso,
    andamento: this.andamento,
    habilitarDevolucao: this.habilitarDevolucao,
    processo: this.processo,
    numeroContad: this.numeroContad,
    numeroDespacho: this.numeroDespacho,
    contatoEmail: this.contatoEmail,
    emailDepartamento: this.emailDepartamento,
    contatoTelefone: this.contatoTelefone,
  })

  constructor(
    private concursoService: ConcursoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private unespCoreUserService: UnespCoreUserService,
    private route: ActivatedRoute,
    private router: Router,
    private unespCoreAuthService: UnespCoreAuthService
  ) {
    this.editalUploadFc = new FormControl(new ArquivoSelecionado(), {
      validators: [arquivoUploadValidator(false)],
      updateOn: 'submit',
    }) as FormControl<ArquivoSelecionado>
  }

  ngOnInit(): void {
    this.concursoService.camposHabilitados().subscribe(campus => {
      this.campusHabilitados = campus
    })
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.editalUploadPath += this.id
        this.concursoService.get(this.id).subscribe(obj => {
          this.titulo.setValue(obj.titulo)
          this.editalUploadFc.value.setNameAndSize(obj.edital, obj.editalSize as number)
          this.editalUploadFc.setValue(this.editalUploadFc.value)
          this.descricao.setValue(obj.descricao)
          this.vagas.setValue(obj.vagas != null ? obj.vagas.toString() : '')
          this.valorInscricao.setValue(this.formatarValor(Number.parseFloat(obj.valorInscricao.toString())))
          let tzOffset = new Date().getTimezoneOffset() * 60000
          this.inicio.setValue(new Date(Date.parse(obj.inicio.toString()) + tzOffset))
          this.fim.setValue(new Date(Date.parse(obj.fim.toString()) + tzOffset))
          if (obj.inicioFase2 !== null) {
            this.inicioFase2.setValue(new Date(Date.parse(obj.inicioFase2.toString()) + tzOffset))
          }
          if (obj.fimFase2 !== null) {
            this.fimFase2.setValue(new Date(Date.parse(obj.fimFase2.toString()) + tzOffset))
          }
          this.edital.setValue(obj.edital)
          this.salario.setValue(
            obj.salario == null ? '' : this.formatarValor(Number.parseFloat(obj.salario.toString()))
          )
          this.destaque.setValue(obj.destaque)
          this.resumo.setValue(obj.resumo)
          this.ativo.setValue(obj.ativo.toString())
          this.uuid.setValue(obj.uuid.toString())
          this.online.setValue(obj.online.toString())
          this.tipo.setValue(obj.tipo)
          this.andamento.setValue(obj.andamento)
          this.horaFim.setValue(obj.horaFim.toString())
          this.habilitarDevolucao.setValue(obj.habilitarDevolucao.toString())
          this.editalSize.setValue(obj.editalSize != null ? obj.editalSize.toString() : '')
          this.processo.setValue(obj.processo)
          this.numeroContad.setValue(obj.numeroContad)
          this.numeroDespacho.setValue(obj.numeroDespacho)
          this.contatoEmail.setValue(obj.contatoEmail)
          this.emailDepartamento.setValue(obj.emailDepartamento)
          this.contatoTelefone.setValue(obj.contatoTelefone)
          obj.responsaveisRecurso.split(',').forEach(res => {
            const value = res.trim()
            if (value) this.responsaveis.push(value)
          })
          if (typeof obj.temas != 'undefined') this.pontosDataSource.data = obj.temas
        })
      } else {
        this.uuid.setValue((<Usuario>this.unespCoreUserService.getUser()).idUnidadeUniversitaria.toString())
        this.online.setValue('true')
        this.habilitarDevolucao.setValue('false')
        this.ativo.setValue('true')
        this.horaFim.setValue('23:59')
      }
    })

    //Remove errorState do mat-chip quando a input atende o formato de email
    this.responsaveisRecurso.valueChanges.subscribe(() =>
      !this.responsaveisRecurso.invalid ? (this.responsaveisChipList!.errorState = false) : null
    )

    this.tipo.valueChanges.subscribe(value => this.setHasPontos(value))
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  async gravar() {
    this.applyTouchedToInvalidFields()

    if (this.hasPontos && !this.validaPontos()) return

    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    if (!this.comparaDatas()) {
      return
    }
    if (!this.comparaDatasSegundaFase()) {
      return
    }

    let formulario = <Concurso>this.form.value
    formulario.valorInscricao = formulario.valorInscricao != null ? formulario.valorInscricao.replace(',', '.') : ''
    formulario.salario = formulario.salario != null ? formulario.salario.replace(',', '.') : ''
    formulario.responsaveisRecurso = this.responsaveis.join(',')
    formulario.temas = this.pontosDataSource.data

    if (this.id) {
      this.concursoService.atualizar(this.id, formulario).subscribe(concurso => {
        this.editalUpload(concurso.id)
        this.alteracoesNosPontos = false
        this.unespCoreMessageService.showMessageSuccess(`Concurso #${concurso.id} atualizado com sucesso`)
        this.router.navigate(['/concurso'])
      })
    } else {
      this.concursoService.gravar(formulario).subscribe(concurso => {
        this.editalUpload(concurso.id)
        this.unespCoreMessageService.showMessageSuccess(`Concurso #${concurso.id} cadastrado com sucesso`)
        this.router.navigate(['/concurso'])
      })
    }
  }

  private editalUpload(id?: number | string) {
    if (typeof id == 'undefined') return

    this.upload!.uploadPath = 'edital/' + id
    if (this.editalUploadFc.value.status == ArquivoStatus.SELECIONADO) {
      this.upload?.upload()
    }
  }

  addChip(event: MatChipInputEvent): void {
    if (this.responsaveisRecurso.invalid) {
      this.responsaveisChipList!.errorState = true
      return
    }

    const value = (event.value || '').trim()
    if (value) {
      if (value.includes('@unicamp.br')) {
        this.responsaveis.push(value)
      } else {
        this.unespCoreMessageService.showMessageError('Apenas emails @unicamp.br são permitidos')
      }
    }

    event.chipInput!.clear()
  }

  removeChip(resp: string): void {
    const index = this.responsaveis.indexOf(resp)
    if (index >= 0) this.responsaveis.splice(index, 1)
    this.form.markAsDirty()
  }

  setHasPontos(tipo: string | null): void {
    this.hasPontos = tipo == 'RDIDP' || tipo == 'RTP' || tipo == 'RTC'
  }

  validaPontos(): boolean {
    // Não é mais obrigatório
    // if (this.pontosDataSource.data.length == 0){
    //   this.unespCoreMessageService.showMessageError(`É nescessário cadastrar temas para ${this.tipo.value}`)
    // } else
    // if (this.editarPontoIdx != -1) {
    //   this.unespCoreMessageService.showMessageError("Complete ou cancele a edição do ponto")
    // } else if (this.pontoInput.touched) {
    //   this.unespCoreMessageService.showMessageError("O ponto não foi adicionado ainda")
    // } else {
    //   return true
    // }
    // this.pontoInput.markAsTouched()
    // this.pontoInput.setErrors({'incorrect': true})
    // return false
    return true
  }

  adicionarPonto() {
    let newTitulo = this.pontoInput.value ? this.pontoInput.value : ''
    if (newTitulo.length > 0) {
      let temas = this.pontosDataSource.data
      temas.push({
        data: new Date(),
        titulo: newTitulo,
        ativo: true,
      })
      this.pontosDataSource.data = temas
      this.limparPontoInput()
      this.alteracoesNosPontos = true
    } else {
      this.pontoInput.markAsTouched()
    }
  }
  atualizarPonto() {
    let newTitulo = this.pontoInput.value ? this.pontoInput.value : ''
    if (newTitulo.length > 0) {
      this.pontosDataSource.data[this.editarPontoIdx].titulo = newTitulo
      this.pontosDataSource.data[this.editarPontoIdx].data = new Date()
      this.limparPontoInput()
      this.alteracoesNosPontos = true
    }
  }

  editarPonto(tema: Tema, idx: number) {
    this.editarPontoIdx = idx
    this.pontoInput.setValue(tema.titulo)
  }

  limparPontoInput() {
    this.pontoInput.setValue('')
    this.pontoInput.markAsUntouched()
    this.editarPontoIdx = -1
  }

  excluirPonto(tema: Tema, idx: number) {
    if (this.editarPontoIdx != -1) this.limparPontoInput()
    this.pontosDataSource.data.splice(idx, 1)
    this.pontosDataSource.data = this.pontosDataSource.data
    this.alteracoesNosPontos = true
  }

  pontoInputOnBlur() {
    if (this.pontoInput.value == '' && this.editarPontoIdx == -1 && this.pontosDataSource.data.length > 0) {
      this.pontoInput.markAsUntouched()
    }
  }

  private formatarValor(valor: number): string {
    return valor.toFixed(2).replace('.', ',')
  }

  hasPendingChanges(): boolean {
    return this.alteracoesNosPontos
  }
  pendingChangesMsg(): string {
    return 'Descartar alterações na lista de Pontos?'
  }

  showTipoConcursoRH(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH'])
  }

  showTipoConcursoDTA(): boolean {
    return (
      !this.unespCoreAuthService.hasPermissionBasedRoles(['RH']) &&
      this.unespCoreAuthService.hasPermissionBasedRoles(['DTA'])
    )
  }

  clearDateInicioFase2(): void {
    this.inicioFase2.setValue(null)
  }

  clearDateFimFase2(): void {
    this.fimFase2.setValue(null)
  }

  comparaDatas(): boolean {
    if (this.fim.value != null && this.inicio.value != null) {
      if (this.fim.value < this.inicio.value) {
        this.unespCoreMessageService.showMessageError('Data de término da primeira fase menor que a data de início')
        return false
      } else {
        return true
      }
    } else {
      if (
        (this.fim.value != null && this.inicio.value == null) ||
        (this.fim.value == null && this.inicio.value != null)
      ) {
        this.unespCoreMessageService.showMessageError('Apenas uma das datas da primeira fase foi informada')
        return false
      } else {
        return true
      }
    }
  }

  comparaDatasSegundaFase(): boolean {
    if (this.fimFase2.value != null && this.inicioFase2.value != null) {
      if (this.fimFase2.value < this.inicioFase2.value) {
        this.unespCoreMessageService.showMessageError('Data de término da segunda fase menor que a data de início')
        return false
      } else {
        return true
      }
    } else {
      if (
        (this.fimFase2.value != null && this.inicioFase2.value == null) ||
        (this.fimFase2.value == null && this.inicioFase2.value != null)
      ) {
        this.unespCoreMessageService.showMessageError('Apenas uma das datas da segunda fase foi informada')
        return false
      } else {
        return true
      }
    }
  }
}
