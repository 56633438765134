/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { DatePipe } from '@angular/common'
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { fromEvent, debounceTime, distinctUntilChanged, tap, filter } from 'rxjs'
import { Recurso } from 'src/app/models/recurso'
import { LocalStorageService } from 'src/app/services/local-storage.service'
import { RecursoService } from 'src/app/services/recurso.service'

@Component({
  selector: 'app-lista-recurso',
  templateUrl: './lista-recurso.component.html',
  styleUrls: ['./lista-recurso.component.css'],
})
export class ListaRecursoComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef
  totalElements: number = 0

  colunasGrid: string[] = ['id', 'concurso', 'nome', 'tipoRecurso', 'data', 'parecer', 'action']
  dataSource: MatTableDataSource<Recurso> = new MatTableDataSource()

  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(private recursoService: RecursoService, private localStorageService: LocalStorageService) {}

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()

    setTimeout(() => {
      this.input.nativeElement.value = this.localStorageService.get('lista-recurso.search') ?? ''
      this.getData()
    }, 0)
  }

  private getData() {
    this.localStorageService.set('lista-recurso.search', this.input?.nativeElement.value)
    this.recursoService
      .listar(this.input?.nativeElement.value, this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data['content'])
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
  }

  exportar() {
    this.recursoService.listar(this.input?.nativeElement.value, 0, this.totalElements).subscribe(data => {
      let recursos: Recurso[] = data['content']
      let content = `"#","CÂMPUS","CONCURSO","NOME","RECURSO","DATA","PARECER","PARECER-DATA","PARECER-RESPONSÁVEL"\r\n`
      recursos.forEach(row => {
        content += `"${row.id}","${row.concursoCampus}","${row.tituloConcurso.replace(/"/g, "'")}","${row.nome}","${
          row.tipoRecurso
        }","${this.datepipe.transform(row.data, 'dd/MM/YYYY HH:mm:ss')}","${row.parecer}","${
          row.parecerData == null ? '' : this.datepipe.transform(row.parecerData, 'dd/MM/YYYY HH:mm:ss')
        }","${row.parecerUsuario}"\r\n`
      })
      let binaryData = []
      binaryData.push(content)
      let downloadLink = document.createElement('a')
      downloadLink.setAttribute('download', 'recursos.csv')
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csv;charset=utf-8;' }))
      document.body.appendChild(downloadLink)
      downloadLink.click()
      downloadLink.remove()
    })
  }
}
