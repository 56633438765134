/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, map, Observable, startWith, takeLast } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  private download(response: any): void {
    let contentDisposition = response.headers.get('Content-Disposition')
    let filename = contentDisposition.split(';')[1].split('=')[1].trim().replace(/\"/g, '')
    let binaryData = []
    binaryData.push(response.body)
    let downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', filename)
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.type }))
    document.body.appendChild(downloadLink)
    downloadLink.click()
    downloadLink.remove()
  }

  private request(url: string): Observable<any> {
    return this.http
      .get<any>(url, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  arquivoDownload(path: string, name: string): void {
    let url = `${this.baseUrl}/arquivo/${path}/${name}`
    this.request(url).subscribe((response: any) => this.download(response))
  }

  arquivoDownloadUrl(url: string): void {
    this.request(url).subscribe((response: any) => this.download(response))
  }

  private arquivoObjectURL(url: string): Observable<string> {
    return this.request(url).pipe(
      map(response => {
        let contentDisposition = response.headers.get('Content-Disposition')
        let mimetype = contentDisposition.split(';')[2].split('=')[1].trim().replace(/\"/g, '')
        let binaryData = []
        binaryData.push(response.body)
        return window.URL.createObjectURL(new Blob(binaryData, { type: mimetype }))
      }),
      startWith(''),
      takeLast(1)
    )
  }

  edital(id: number) {
    let url = `${this.baseUrl}/status-documentacao/edital/${id}`
    return this.request(url).subscribe((response: any) => this.download(response))
  }

  arquivoObjectURLPorPath(path: string, name: string): Observable<string> {
    return this.arquivoObjectURL(`${this.baseUrl}/arquivo/${path}/${name}`)
  }
}
