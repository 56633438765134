<div fxLayout="column" fxLayoutGap="20px">
  <h1>Configurações</h1>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" (keyup)="search($event)" placeholder="" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="campus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="campus">Câmpus</th>
        <td mat-cell *matCellDef="let row">{{ row.campus }}</td>
      </ng-container>
      <ng-container matColumnDef="chave">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="chave">Chave</th>
        <td mat-cell *matCellDef="let row">{{ row.chave }}</td>
      </ng-container>
      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="valor">Valor</th>
        <td mat-cell *matCellDef="let row">{{ formataValor(row.chave, row.valor) }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button color="basic" routerLink="editar/{{ row.id }}" title="Editar">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
