<div fxLayout="column" fxLayoutGap="20px">
  <h2>{{ concursoTitulo }}</h2>
  <h3>{{ concursoTipo }}</h3>
  <h3>{{ tipoProvaStr }}</h3>

  <app-digitar-notas-candidato
    *ngIf="digitandoNotas"
    [idConcurso]="idConcurso"
    [tipoProva]="tipoProva"
    [provaConcluida]="provaConcluida"
    [formatoNota]="formatoNota"
    [tipoCalculo]="tipoCalculo"
    [inscricoesIds]="inscricoesIds"
    [inscricaoIdx]="inscricaoDigitar"
    [tabelaNotas]="tabelaNotasCriterios"
    (notaChange)="updateNota($event)"
    (retornar)="fecharDigitacao()"
    (alteracoesPendentes)="setAlteracoesPendentes($event)"></app-digitar-notas-candidato>

  <div *ngIf="!digitandoNotas">
    <div>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="candidato">
          <th mat-header-cell *matHeaderCellDef rowspan="2">Candidato</th>
          <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
        </ng-container>
        <ng-container matColumnDef="examinador-header">
          <th mat-header-cell *matHeaderCellDef colspan="3">Examinador</th>
        </ng-container>
        <ng-container matColumnDef="nota1">
          <th mat-header-cell *matHeaderCellDef>1°</th>
          <td mat-cell *matCellDef="let row">
            {{ row.nota1 !== null ? (row.nota1 | numberRound : formatoNota) : '--' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nota2">
          <th mat-header-cell *matHeaderCellDef>2°</th>
          <td mat-cell *matCellDef="let row">
            {{ row.nota2 !== null ? (row.nota2 | numberRound : formatoNota) : '--' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nota3">
          <th mat-header-cell *matHeaderCellDef>3°</th>
          <td mat-cell *matCellDef="let row">
            {{ row.nota3 !== null ? (row.nota3 | numberRound : formatoNota) : '--' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="acoes">
          <th mat-header-cell *matHeaderCellDef rowspan="2"></th>
          <td mat-cell *matCellDef="let idx = index">
            <button mat-stroked-button color="basic" (click)="digitar(idx)" [disabled]="semCriterios">Digitar</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colunasH1"></tr>
        <tr mat-header-row *matHeaderRowDef="colunasH2"></tr>
        <tr mat-row *matRowDef="let row; columns: colunasTd"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="8">
            <span>Nenhum registro disponível</span>
          </td>
        </tr>
      </table>
    </div>
    <br />
    <div fxLayoutAlign="end center">
      <button mat-stroked-button color="warn" routerLink="/concurso/{{ idConcurso }}/provas">
        <mat-icon>arrow_back</mat-icon>Retornar as provas
      </button>
    </div>
  </div>
</div>
