<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign="space-between center">
    <h1>Inscrições</h1>
    <div>
      <button mat-raised-button color="primary" (click)="exportar()"><mat-icon>save_alt</mat-icon> Exportar</button>
    </div>
  </div>

  <div fxLayout.lt-md="column" fxLayoutGap="20px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Situação</mat-label>
      <mat-select name="filtroSituacao" [(value)]="filtroSituacaoSelecionada" (selectionChange)="getData()">
        <mat-option *ngFor="let enum of tipoSituacao | keyvalue" [value]="enum.key">
          {{ enum.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Submissao</mat-label>
      <mat-select name="filtroSubmissao" [(value)]="filtroSubmissaoSelecionada" (selectionChange)="getData()">
        <mat-option *ngFor="let enum of tipoSubmissao | keyvalue" [value]="enum.key">
          {{ enum.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="concurso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Concurso</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.campus }}</p>
          <p>{{ row.tituloConcurso }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="inscricao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Inscrição</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
          <p *ngIf="!isDepartamento">{{ row.protocolo }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="candidato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidato</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.nome }}</p>
          <p><b>CPF</b> {{ row.cpf }}</p>
          <p><b>RG</b> {{ row.rg }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="contato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contato</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.email }}</p>
          <p>{{ row.celular }}</p>
          <p>{{ row.telefone }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Situação</th>
        <td mat-cell *matCellDef="let row" [ngSwitch]="row">
          <p>{{ row.status | inscricaoSituacao }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="pago">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pago</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.pago ? 'Sim' : 'Não' }}</p>
          <p class="red" *ngIf="row.reducao">
            <span *ngIf="!row.reducaoData">Redução Solicitada e Aguardando Análise</span>
            <span *ngIf="row.reducaoData && !row.reducaoAprovado">Redução Não aprovada</span>
            <span *ngIf="row.reducaoData && row.reducaoAprovado">Redução Aprovada</span>
            <span *ngIf="row.reducaoData && !row.reducaoConcluida"> (Não concluída)</span>
          </p>
          <p *ngIf="row.devolucaoSolicitada && !row.devolucaoConcluida">Devolução solicitada</p>
          <p *ngIf="row.devolucaoSolicitada && row.devolucaoConcluida">Devolução concluída</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button color="basic" (click)="abrirFicha(row.id)" title="Ficha">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="boleto">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button
            *ngIf="showEmitirBoleto(row)"
            mat-icon-button
            color="warn"
            (click)="emitirPagamento(row, 'BOLETO')"
            title="Emitir boleto">
            <mat-icon>request_quote</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="pix">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button
            *ngIf="showEmitirPix(row)"
            mat-icon-button
            color="primary"
            (click)="emitirPagamento(row, 'PIX')"
            title="Emitir pix">
            <mat-icon>pix</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
