/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router'
import { ItemListaConfiguracao } from 'src/app/models/item-lista-configuracao'
import { ConfiguracaoService } from 'src/app/services/configuracao.service'

@Component({
  selector: 'app-lista-configuracao',
  templateUrl: './lista-configuracao.component.html',
  styleUrls: ['./lista-configuracao.component.css'],
})
export class ListaConfiguracaoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  colunasGrid: string[] = ['id', 'campus', 'chave', 'valor', 'action']
  dataSource: MatTableDataSource<ItemListaConfiguracao> = new MatTableDataSource()

  constructor(private configuracaoService: ConfiguracaoService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.route.snapshot.url[0].toString() == 'gerencia-configuracao')
      this.configuracaoService.listarGerencia().subscribe(configuracoes => this.carregarDataSource(configuracoes))
    else this.configuracaoService.listarRHeFinancas().subscribe(configuracoes => this.carregarDataSource(configuracoes))
  }

  carregarDataSource(configuracoes: ItemListaConfiguracao[]) {
    this.dataSource = new MatTableDataSource(configuracoes)
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  formataValor(chave: string, valor: string): string {
    if (chave == 'CPF_RESTRITO' || chave == 'RG_RESTRITO') {
      return valor.split(',').sort().join(' ')
    } else return valor
  }
}
