/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, map, Observable } from 'rxjs'
import { InscricaoFicha } from '../models/inscricao-ficha'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class InscricaoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  get(id: number): Observable<InscricaoFicha> {
    return this.http
      .get<InscricaoFicha>(`${this.baseUrl}/inscricao/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listar(
    idConcurso?: number,
    filtroSituacaoSelecionada: string = '',
    filtroSubmissaoSelecionada: string = '',
    pago = 0,
    search = '',
    page = 0,
    size = 10
  ): Observable<any> {
    let url =
      idConcurso !== undefined ? `${this.baseUrl}/inscricao/concurso/${idConcurso}` : `${this.baseUrl}/inscricao`

    return this.http
      .get<any>(url, {
        params: new HttpParams()
          .set('situacaoInscricao', filtroSituacaoSelecionada)
          .set('submissaoInscricao', filtroSubmissaoSelecionada)
          .set('search', search)
          .set('pago', pago)
          .set('page', page.toString())
          .set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listarDocumentos(
    idConcurso: number,
    situacaoInscricao: string,
    submissaoInscricao: string,
    search = '',
    page = 0,
    size = 10
  ): Observable<any> {
    let url = `${this.baseUrl}/inscricao/concurso/${idConcurso}/documentos`
    return this.http
      .get<any>(url, {
        params: new HttpParams().set('search', search).set('page', page.toString()).set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  alterar(id: number, form: InscricaoFicha): Observable<InscricaoFicha> {
    return this.http
      .put<InscricaoFicha>(`${this.baseUrl}/inscricao/${id}`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  temas(id: number): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.baseUrl}/inscricao/${id}/temas`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  emitirPagamento(id: number, tipo: string): Observable<string> {
    return this.http
      .post<string>(`${this.baseUrl}/inscricao/${id}/${tipo}/emitir-pagamento`, { responseType: 'text' })
      .pipe(
        map(resultado => {
          return resultado
        })
      )
  }

  gerarArquivoCsv(idConcurso: number | null = null, search = '') {
    let headers = new HttpHeaders()
    headers = headers.set('Accept', 'text/csv')
    if (idConcurso == null) {
      this.http
        .get<any>(`${this.baseUrl}/inscricao/gerar-csv`, {
          headers: headers,
          responseType: 'blob' as 'json',
          observe: 'response',
          params: new HttpParams().set('search', search),
        })
        .pipe(identity, this.errorHandlerService.catchError())
        .subscribe(res => {
          let url = window.URL.createObjectURL(res.body)
          let downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.download = `inscricoes.csv`
          downloadLink.click()
          window.URL.revokeObjectURL(url)
          downloadLink.remove()
        })
    } else {
      this.http
        .get<any>(`${this.baseUrl}/inscricao/${idConcurso}/gerar-csv`, {
          headers: headers,
          responseType: 'blob' as 'json',
          observe: 'response',
          params: new HttpParams().set('search', search),
        })
        .pipe(identity, this.errorHandlerService.catchError())
        .subscribe(res => {
          let url = window.URL.createObjectURL(res.body)
          let downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.download = `inscricoes-${idConcurso}.csv`
          downloadLink.click()
          window.URL.revokeObjectURL(url)
          downloadLink.remove()
        })
    }
  }
}
