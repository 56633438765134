<h1>Configuração</h1>
<mat-card>
  <mat-card-content>
    <form>
      <fieldset fxFlex="100%">
        <legend>{{ configuracao?.grupo }}</legend>

        <div fxLayout="column" fxLayoutGap="5px">
          <mat-form-field appearance="outline">
            <mat-label>Chave</mat-label>
            <input matInput [value]="configuracao?.chave" [disabled]="true" />
          </mat-form-field>

          <div *ngIf="usarChips; then chips_content; else texto_content"></div>

          <ng-template #texto_content>
            <mat-form-field appearance="outline">
              <mat-label>Valor</mat-label>
              <textarea matInput [formControl]="valorFC" rows="1"></textarea>
            </mat-form-field>
          </ng-template>

          <ng-template #chips_content>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Valor</mat-label>
              <mat-chip-grid #valorChipList>
                <mat-chip-option *ngFor="let valor of chipsValor" (removed)="removeChip(valor)">
                  {{ valor }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
                <input
                  [placeholder]="chipPlaceHolder"
                  [matChipInputFor]="valorChipList"
                  [matChipInputSeparatorKeyCodes]="chipsSeparatorKeys"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addChip($event)"
                  [formControl]="valorChipListFC"
                  name="inputValorChipList" />
              </mat-chip-grid>
              <mat-error *ngIf="valorChipList.errorState && valorChipListFC.invalid">
                {{ erroChipsMsg }}
              </mat-error>
            </mat-form-field>
          </ng-template>

          <mat-form-field appearance="outline">
            <mat-label>Explicação</mat-label>
            <textarea matInput [value]="configuracao?.explicacao" [disabled]="true" rows="1"></textarea>
          </mat-form-field>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <button mat-raised-button color="primary" (click)="salvar()">
        <mat-icon>check_circle_outline</mat-icon> Salvar
      </button>
      <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>close</mat-icon> Cancelar</button>
    </div>
  </mat-card-actions>
</mat-card>
