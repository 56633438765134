/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { UnespCoreMenuNavigationService, UnespCoreMessageService } from 'src/libs/unesp-core'
import { UsuarioLogin } from 'src/app/models/usuario-login'
import { Observable, Subscription } from 'rxjs'
import { UserLoginService } from 'src/app/services/usuario-login.service'
import { getMenu } from 'src/app/menu'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent implements OnInit, OnDestroy {
  user?: UsuarioLogin | null
  private userSubscription!: Subscription
  perfil: string = ''
  recarregarMenu: number = 0

  form = new FormGroup({
    email: new FormControl('admin@gmail.com', Validators.required),
    senha: new FormControl('123', Validators.required),
  })

  constructor(
    private userLoginService: UserLoginService,
    private unespCoreMessageService: UnespCoreMessageService,
    private router: Router,
    // private recaptchaV3Service: ReCaptchaV3Service,
    private unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
  ) {
  }

  ngOnInit(): void {
    this.userLoginService.user$.subscribe(user => {
      this.user = user
      this.getPerfil()
      if (user) {
        this.recarregarMenu = 0
      }
      this.recarregarMenu += 1
      if (this.recarregarMenu === 1) {
        setTimeout(() => {
          this.unespCoreMenuNavigationService.itensMenuSource.next(
            getMenu(this.userLoginService.getUser() === null, this.userLoginService.getUser()?.roles ?? [])
          )
        }, 500)
      }
      if (user) {
        this.router.navigate(['/'])
      }
    })
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe()
    }
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  private getPerfil(): void {
    if (this.user) {
      this.user.roles.forEach(role => {
        if (role !== '**' && role !== '!!') {
          this.perfil += role + ', '
        }
      })
      if (this.perfil.length > 0) {
        this.perfil = this.perfil.slice(0, -2)
      }
    }
  }


  login(): void {
    this.applyTouchedToInvalidFields()
    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Verifique se preencheu todos os campos do formulário corretamente')
      return
    }
    if (this.form.get('email')?.value && this.form.get('senha')?.value) {
      let formData: FormData = new FormData()
      formData.append('email', this.form.get('email')?.getRawValue())
      formData.append('senha', this.form.get('senha')?.getRawValue())
      // this.recaptchaV3Service.execute('loginCandidato').subscribe((token: string) => {
        this.userLoginService.loginSample(formData)
      // })
    }
  }

  recuperarSenha(): void {
    this.unespCoreMessageService.showMessageWarning('Recurso indisponível')
  }

  get userLogin$(): Observable<UsuarioLogin | null> {
    return this.userLoginService.user$
  }
}
