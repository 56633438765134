<div>
  <div *ngIf="complemento?.explicacao" class="input-explicacao">
    <mat-icon style="cursor: default">error_outline</mat-icon>
    <p>{{ complemento?.explicacao }}</p>
  </div>

  <div class="input-row">
    <mat-form-field appearance="outline">
      <mat-label>{{ complemento?.requerido ? '(Obrigatório)' : '(Opcional)' }}</mat-label>
      <textarea matInput [formControl]="formControl" rows="4" class="input-text"></textarea>
    </mat-form-field>
  </div>
</div>
