<h1>Recurso</h1>
<mat-card>
  <mat-card-content>
    <form fxLayout="column" fxLayoutGap="20px">
      <fieldset fxFlex="100%">
        <legend>Dados do recurso</legend>

        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Câmpus</mat-label>
              <input matInput [value]="recurso?.concursoCampus" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concurso</mat-label>
              <input matInput [value]="recurso?.tituloConcurso" [disabled]="true" />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput [value]="recurso?.nome" [disabled]="true" />
          </mat-form-field>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Protocolo</mat-label>
              <input matInput [value]="recurso?.protocolo" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Tipo do recurso</mat-label>
              <input matInput [value]="recurso?.tipoRecurso" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Data de abertura</mat-label>
              <input matInput [value]="recurso?.data | date : 'dd/MM/yyyy HH:mm:ss'" [disabled]="true" />
            </mat-form-field>
          </div>

          <app-arquivo-upload
            *ngIf="recurso?.anexo"
            [autoUpload]="true"
            [fc]="anexoFormControl"
            accept="application/pdf"
            uploadPath="{{ 'recurso/' + recurso?.id }}"
            [usarArquivoViewer]="true"
            label="Anexo"></app-arquivo-upload>

          <mat-form-field appearance="outline">
            <mat-label>Texto</mat-label>
            <textarea matInput [value]="recurso?.texto" [disabled]="true" rows="4"></textarea>
          </mat-form-field>

          <mat-divider></mat-divider>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Data</mat-label>
              <input
                matInput
                [value]="recurso?.parecerData | date : 'dd/MM/yyyy HH:mm:ss'"
                name="parecerData"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input matInput [value]="recurso?.parecerUsuario" name="parecerUsuario" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concluído</mat-label>
              <input
                matInput
                [value]="recurso?.parecerConcluido ? 'Sim' : 'Não'"
                name="parecerConcluida"
                [disabled]="true" />
            </mat-form-field>
          </div>
          <div fxLayout.gt-md="row" fxFill>
            <div fxFlex="100">
              <app-arquivo-upload
                #upload
                label="Arquivo em PDF do parecer"
                accept="application/pdf"
                [uploadPath]="parecerUploadPath"
                [fc]="parecerUploadFc"
                [usarArquivoViewer]="true">
              </app-arquivo-upload>
            </div>
            <div
              class="btnExcluir"
              *ngIf="
                !recurso?.parecerConcluido &&
                (parecerUploadFc.value.status === 4 ||
                  parecerUploadFc.value.status === 3 ||
                  parecerUploadFc.value.status === 2)
              ">
              <button
                mat-icon-button
                color="warn"
                (click)="apagarDocumento(recurso?.id)"
                [disabled]="recurso?.parecerConcluido">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>Parecer</mat-label>
            <mat-select [formControl]="parecerFC">
              <mat-option value="false">Em análise</mat-option>
              <mat-option value="true">Concluído</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-card class="mat-card-yellow">
            <mat-card-content>
              O parecer do recurso somente deverá ser incluído no campo acima pela STGP ou DTA após a manifestação da
              Congregação
            </mat-card-content>
          </mat-card>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <ng-container *ngIf="isPermitirAlteracao; then permitirAlteracaoBtn; else salvarimprimirBtns"></ng-container>
      <ng-template #permitirAlteracaoBtn>
        <button mat-raised-button color="primary" (click)="permitirAlteracao()" [disabled]="!recurso?.parecerConcluido">
          <mat-icon>edit</mat-icon> Reverter
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>arrow_back</mat-icon> Voltar</button>
      </ng-template>
      <ng-template #salvarimprimirBtns>
        <button mat-raised-button color="primary" (click)="salvar()" [disabled]="!salvarBtn">
          <mat-icon>check_circle_outline</mat-icon> Salvar
        </button>
        <button mat-raised-button color="primary" (click)="imprimir()"><mat-icon>print</mat-icon> Imprimir</button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>close</mat-icon> Cancelar</button>
      </ng-template>
    </div>
  </mat-card-actions>
</mat-card>
