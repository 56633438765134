<h2 mat-dialog-title>Detalhes</h2>
<hr style="border: 0; border-top: solid 1px #0000003f" />
<mat-dialog-content>
  <div [ngSwitch]="tipoLog">
    <div *ngSwitchCase="'ERRO'">
      <span>Erro: {{ detalhes.erroMsg }}</span>
    </div>
    <div *ngSwitchCase="'LOGIN'">
      <p><b>Usuário:</b> #{{ detalhes.idUsuario }} - {{ detalhes.identificacao }}</p>
      <p><b>Nome:</b> {{ detalhes.nomeUsuario }}</p>
      <p><b>Unidade:</b> {{ detalhes.nomeUnidadeUniversitaria }} - {{ detalhes.descricaoCampusSigla }}</p>
      <p>
        <b>Perfis:</b> <code> {{ detalhes.authorities.join(',  ') }} </code>
      </p>
    </div>
    <div *ngSwitchCase="'INSERT'">
      <pre>{{ detalhesJSONIdented() }}</pre>
    </div>
    <div *ngSwitchCase="'UPDATE'">
      <span *ngIf="detalhes.registro !== null">{{ detalhes.registro }}</span>
      <table>
        <thead>
          <th>Campo</th>
          <th>Original</th>
          <th>Modificado</th>
        </thead>
        <tbody>
          <tr *ngFor="let diff of detalhes.diffArr">
            <td>
              <span>{{ diff.campo }}</span>
            </td>
            <td>
              <p>{{ diff.original }}</p>
            </td>
            <td>
              <p>{{ diff.modificado }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</mat-dialog-content>
<br />
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-button mat-dialog-close>Fechar</button>
</div>
