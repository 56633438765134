/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { Andamento } from '../models/andamento'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class AndamentoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  gravar(idConcurso: number, form: Andamento): Observable<any> {
    return this.http
      .post<string>(`${this.baseUrl}/concurso/${idConcurso}/andamento`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  atualizar(idConcurso: number, id: number, form: Andamento): Observable<Andamento> {
    return this.http
      .put<Andamento>(`${this.baseUrl}/concurso/${idConcurso}/andamento/${id}`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listar(idConcurso: number): Observable<Andamento[]> {
    return this.http
      .get<Andamento[]>(`${this.baseUrl}/concurso/${idConcurso}/andamento`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  excluir(idConcurso: number, id: number): Observable<Andamento> {
    return this.http
      .delete<Andamento>(`${this.baseUrl}/concurso/${idConcurso}/andamento/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  concluir(idConcurso: number, id: number): Observable<Andamento> {
    return this.http
      .put<Andamento>(`${this.baseUrl}/concurso/${idConcurso}/andamento/${id}/concluir`, [])
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
