/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InscricaoPagamentoDevolucaoService } from 'src/app/services/inscricao-pagamento-devolucao.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute } from '@angular/router'
import { InscricaoPagamentoDevolucao } from 'src/app/models/inscricao-pagamento-devolucao'
import { ArquivoSelecionado } from '../../arquivo-upload/arquivo-upload.component'

@Component({
  selector: 'app-inscricao-pagamento-devolucao',
  templateUrl: './inscricao-pagamento-devolucao.component.html',
  styleUrls: ['./inscricao-pagamento-devolucao.component.scss'],
})
export class InscricaoPagamentoDevolucaoComponent implements OnInit {
  id?: number
  inscricaoPagamentoDevolucao?: InscricaoPagamentoDevolucao

  anexoFormControl: FormControl<ArquivoSelecionado> = new FormControl()

  form = new FormGroup({
    devolucaoConcluida: new FormControl('', Validators.required),
  })

  constructor(
    private inscricaoPagamentoDevolucaoService: InscricaoPagamentoDevolucaoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const anexo: ArquivoSelecionado = new ArquivoSelecionado()
    this.anexoFormControl.setValue(anexo)
    this.anexoFormControl.disable()
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.inscricaoPagamentoDevolucaoService.get(this.id).subscribe(obj => {
          this.inscricaoPagamentoDevolucao = obj
          anexo.setNameAndSize(obj.depositoAnexo, obj.depositoAnexoTamanho)
          this.anexoFormControl.setValue(anexo)
          this.form.get('devolucaoConcluida')?.setValue(obj.devolucaoConcluida ? 'true' : 'false')
        })
      }
    })
  }

  gravar(): void {
    if (this.id) {
      let opcao = this.form.controls['devolucaoConcluida'].value == 'true' ? true : false
      if (
        this.inscricaoPagamentoDevolucao?.devolucaoConcluida != opcao ||
        this.inscricaoPagamentoDevolucao?.devolucaoData == null
      ) {
        if (opcao) {
          this.inscricaoPagamentoDevolucaoService.confirmar(this.id).subscribe(inscricaoPagamentoDevolucao => {
            this.inscricaoPagamentoDevolucao = inscricaoPagamentoDevolucao
            this.unespCoreMessageService.showMessageSuccess(`Devolução #${this.id} confirmado com sucesso`)
          })
        } else {
          this.inscricaoPagamentoDevolucaoService.cancelar(this.id).subscribe(inscricaoPagamentoDevolucao => {
            this.inscricaoPagamentoDevolucao = inscricaoPagamentoDevolucao
            this.unespCoreMessageService.showMessageSuccess(`Devolução #${this.id} cancelado com sucesso`)
          })
        }
      }
    }
  }
}
