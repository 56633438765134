/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UsuarioExternoService } from 'src/app/services/usuario-externo.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { UsuarioExterno } from 'src/app/models/usuario-externo'

@Component({
  selector: 'app-cadastro-usuario-externo',
  templateUrl: './cadastro-usuario-externo.component.html',
  styleUrls: ['./cadastro-usuario-externo.component.css'],
})
export class CadastroUsuarioExternoComponent implements OnInit {
  cpf?: string
  usuarioExterno?: UsuarioExterno

  form = new FormGroup({
    nome: new FormControl('', Validators.required),
    cpf: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
  })

  constructor(
    private usuarioExternoService: UsuarioExternoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.cpf = params['cpf']
      this.form.reset()
      if (this.cpf) {
        this.usuarioExternoService.get(this.cpf).subscribe(obj => {
          this.usuarioExterno = obj
          this.form.get('nome')?.setValue(obj.nome)
          this.form.get('cpf')?.setValue(obj.cpf)
          this.form.get('email')?.setValue(obj.email)
        })
      }
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  gravar(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    let formulario = <UsuarioExterno>this.form.value
    if (this.cpf && this.usuarioExterno) {
      this.usuarioExterno.cpf = this.form.get('cpf')?.getRawValue()
      this.usuarioExterno.nome = this.form.get('nome')?.getRawValue()
      this.usuarioExterno.email = this.form.get('email')?.getRawValue()
      this.usuarioExternoService.atualizar(this.usuarioExterno).subscribe(usuarioExterno => {
        this.unespCoreMessageService.showMessageSuccess(`Usuário externo #${usuarioExterno.cpf} atualizado com sucesso`)
        this.router.navigate(['/usuario-externo'])
      })
    } else {
      this.usuarioExternoService.gravar(formulario).subscribe(usuarioExterno => {
        this.unespCoreMessageService.showMessageSuccess(`Usuário externo #${usuarioExterno.cpf} cadastrado com sucesso`)
        this.router.navigate(['/usuario-externo'])
      })
    }
  }
}
