/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { DatePipe } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { TipoProva } from 'src/app/enums/tipo-prova'
import { InscricaoNota } from 'src/app/models/inscricao-nota'
import { ProvaCriterio } from 'src/app/models/prova-criterio'
import { InscricaoNotasService } from 'src/app/services/inscricao-notas.service'
import { IPendingChanges } from 'src/app/utils/pending-changes'
import { TabelaNotasCriterios } from '../digitar-notas-candidato/digitar-notas-candidato.component'

@Component({
  selector: 'app-listar-notas-candidatos',
  templateUrl: './listar-notas-candidatos.component.html',
  styleUrls: ['./listar-notas-candidatos.component.css'],
})
export class ListarNotasCandidatosComponent implements OnInit, IPendingChanges {
  idConcurso?: number
  concursoTitulo?: string
  concursoTipo?: string

  tipoProva?: string
  tipoProvaStr?: string
  tipoCalculo?: string

  formatoNota: string = '1.2-2-floor'

  colunasH1: string[] = ['candidato', 'examinador-header', 'acoes']
  colunasH2: string[] = ['nota1', 'nota2', 'nota3']
  colunasTd: string[] = ['candidato', 'nota1', 'nota2', 'nota3', 'acoes']
  dataSource: MatTableDataSource<InscricaoNota> = new MatTableDataSource()
  inscricoesIds?: number[]

  datepipe: DatePipe = new DatePipe('pt-BR')

  tabelaNotasCriterios?: TabelaNotasCriterios
  inscricaoDigitar?: number
  provaConcluida: boolean = true
  semCriterios: boolean = true
  digitandoNotas: boolean = false
  alteracoesPendentes: boolean = false

  constructor(
    private unespCoreMessageService: UnespCoreMessageService,
    private inscricaoNotasService: InscricaoNotasService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['idConcurso']
      this.tipoProva = params['tipoProva']
      this.tipoProvaStr = this.tipoProva ? (TipoProva as any)[this.tipoProva].nome : ''

      if (this.idConcurso) {
        this.initTable()
      }
    })
  }

  private setModoCalculoNota(modo: string, casasDecimais: number) {
    if (Number.isNaN(casasDecimais)) return

    if (modo === 'FLOOR') modo = 'floor'
    else if (modo === 'HALF_EVEN') modo = 'round'
    else return

    this.formatoNota = `1.${casasDecimais}-${casasDecimais}-${modo}`
  }

  private initTable() {
    this.inscricaoNotasService.listar(this.idConcurso!, this.tipoProva!).subscribe(prova => {
      this.inscricoesIds = []
      prova.notas.forEach(nota => {
        this.inscricoesIds?.push(nota.idInscricao)
      })
      this.setModoCalculoNota(prova.modoArredondamento, prova.casasDecimaisNota)
      this.dataSource = new MatTableDataSource(prova.notas)
      this.concursoTitulo = prova.concursoTitulo
      this.concursoTipo = (TipoConcurso as any)[prova.concursoTipo]
      this.tipoCalculo = prova.tipoCalculo
      this.provaConcluida = prova.concluida
      this.initTabelaNotasCriterios(prova.criterios)
    })
  }

  private initTabelaNotasCriterios(criterios: ProvaCriterio[]) {
    this.semCriterios = criterios.length == 0

    let tabela: TabelaNotasCriterios = {
      criterios: new Map<number, ProvaCriterio>(),
      linhasTabela: [],
    }

    criterios.forEach(criterio => {
      tabela.criterios.set(criterio.id, criterio)

      if (criterio.notaMaxima != null || criterio.subCriterios.length > 0)
        tabela.linhasTabela.push({
          id: criterio.id,
          tipo: criterio.subCriterios.length == 0 ? 'NOTA' : 'GRUPO',
          titulo: criterio.titulo,
          notaMaxima: criterio.notaMaxima,
        })

      let linhaGrupoIdx = tabela.linhasTabela.length - 1
      let notaGrupoSoma = 0

      criterio.subCriterios.forEach(subCriterio => {
        if (subCriterio.notaMaxima) notaGrupoSoma += Number.parseFloat(subCriterio.notaMaxima.toString())
        tabela.linhasTabela.push({
          id: subCriterio.id,
          tipo: 'NOTA',
          titulo: subCriterio.titulo,
          notaMaxima: subCriterio.notaMaxima,
        })
      })

      if (criterio.subCriterios.length > 0 && criterio.notaMaxima == null)
        tabela.linhasTabela[linhaGrupoIdx].notaMaxima = notaGrupoSoma
    })

    this.tabelaNotasCriterios = tabela
  }

  digitar(inscricao: number) {
    this.inscricaoDigitar = inscricao
    this.digitandoNotas = true
  }

  updateNota(nota: InscricaoNota) {
    let notas = this.dataSource.data
    let idx = notas.findIndex(n => n.idInscricao == nota.idInscricao)
    nota.nota1 = nota.nota1
    nota.nota2 = nota.nota2
    nota.nota3 = nota.nota3
    notas[idx] = nota
    this.dataSource.data = notas
  }

  fecharDigitacao() {
    this.digitandoNotas = false
  }

  setAlteracoesPendentes(temPendencia: boolean) {
    this.alteracoesPendentes = temPendencia
  }

  hasPendingChanges(): boolean {
    return this.digitandoNotas && this.alteracoesPendentes
  }
  pendingChangesMsg(): string {
    return 'Descartar alterações não salvas?'
  }
}
