/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { Recurso } from '../models/recurso'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class RecursoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  get(id: number): Observable<Recurso> {
    return this.http.get<Recurso>(`${this.baseUrl}/recurso/${id}`).pipe(identity, this.errorHandlerService.catchError())
  }

  listar(search = '', page = 0, size = 10): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/recurso`, {
        params: new HttpParams().set('search', search).set('page', page.toString()).set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  analizar(rec: Recurso): Observable<Recurso> {
    return this.http
      .put<Recurso>(`${this.baseUrl}/recurso/analizar`, rec)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  apagarArquivoAnexo(idRecurso: number | undefined, filename: string | undefined) {
    return this.http
      .delete<Recurso>(`${this.baseUrl}/recurso/parecer/${idRecurso}/excluir`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
