/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UnespCoreComponentsModule } from './components/components.module';
import { UnespCoreInterceptorsModule } from './interceptors';
import { UnespCoreServicesModule } from './services';

@NgModule({
  declarations: [],
  imports: [
    UnespCoreComponentsModule,
    UnespCoreInterceptorsModule,
    UnespCoreServicesModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  exports: [
    UnespCoreComponentsModule,
    UnespCoreInterceptorsModule,
    UnespCoreServicesModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
})
export class UnespCoreModule {}
