<div fxLayout="column" fxLayoutGap="20px">
  <h1>Candidatos</h1>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-stroked-button color="basic" routerLink="/usuario-candidato/{{ row.id }}/email">
            Alterar e-mail
          </button>
          &nbsp;
          <button mat-stroked-button color="basic" routerLink="/usuario-candidato/{{ row.id }}/senha">
            Alterar senha
          </button>
          &nbsp;
          <button mat-stroked-button color="basic" routerLink="/usuario-candidato/{{ row.id }}/vincular-emails">
            Vincular Contas
          </button>
        </td>
        &nbsp;
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
