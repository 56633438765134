<div *ngIf="loading" class="loader-container">
  <div fxFlex="0 0 auto" fxFlexFill fxLayoutAlign="center center" class="spinner">
    <mat-progress-spinner *ngIf="!spinnerCustom" mode="indeterminate"></mat-progress-spinner>
    <img *ngIf="spinnerCustom" [src]='spinnerCustom'>
  </div>
</div>

<mat-sidenav-container fxFlex="1 1 auto">
  <mat-sidenav #appDrawer fxLayout="column" class="unesp-menu mat-elevation-z3" fxFlex="1 1 auto">
    <mat-nav-list *ngIf="unespCoreMenuNavigationService.appDrawerEnabled" 
                  fxFlex="1 1 auto" fxLayout="column" class="inline-list">
      <div *ngIf="usernameOnSidenav" fxFlex="0 0 auto">
        <div *ngIf="user$ | async as user" [ngClass]="'container-usuario'">
          <div [ngClass]="'container-usuario-title'">Usuário</div>
          <b [ngClass]="'container-usuario-username'">
            {{ user.identificacao }}
          </b>
        </div>
        <mat-divider></mat-divider>
      </div>

      <unesp-core-sidenav-item *ngFor="let item of itensMenu" [item]="item" fxLayout="column">
      </unesp-core-sidenav-item>

      <div *ngIf="loginOnSidenav" fxFlex="0 0 auto">
        <mat-divider></mat-divider>

        <a *ngIf="!(user$ | async)" mat-list-item (click)="login()">
          <mat-icon class="unesp-menu-item-icon">login</mat-icon>
          <span class="unesp-menu-item">Login</span>
        </a>

        <a *ngIf="user$ | async" mat-list-item (click)="logout()">
          <mat-icon class="unesp-menu-item-icon">login</mat-icon>
          <span class="unesp-menu-item">Sair</span>
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content fxFlex="1 1 auto">
    <ng-content select=".ngcontent-content"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>