<mat-card>
  <mat-card-content>
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
      <fieldset fxFlex="100%">
        <legend>Cadastro</legend>
        <div fxLayout="column" fxLayoutGap="5px">
          <mat-form-field appearance="outline">
            <mat-label>Andamento</mat-label>
            <mat-select formControlName="tipo" name="tipo" (selectionChange)="selecionaTipo()">
              <mat-option *ngFor="let type of tipoAndamento | keyvalue" [value]="type.key">
                {{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Título</mat-label>
            <input matInput formControlName="titulo" name="titulo" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Complemento</mat-label>
            <input matInput formControlName="complemento" name="complemento" />
          </mat-form-field>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill *ngIf="tipoAndamentoDetalhe?.doe">
            <mat-form-field appearance="outline">
              <mat-label>DOE publicação</mat-label>
              <mat-icon class="danger" matPrefix (click)="clearDate('publicacao')">close</mat-icon>
              <input
                matInput
                readonly
                [matDatepicker]="pickerPublicacao"
                formControlName="publicacao"
                name="publicacao" />
              <mat-datepicker-toggle matSuffix [for]="pickerPublicacao"></mat-datepicker-toggle>
              <mat-datepicker #pickerPublicacao></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>DOE página</mat-label>
              <input matInput formControlName="pagina" name="pagina" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>DOE sessão</mat-label>
              <input matInput formControlName="sessao" name="sessao" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill *ngIf="tipoAndamentoDetalhe?.recurso">
            <mat-form-field appearance="outline">
              <mat-label>Recurso início</mat-label>
              <mat-icon class="danger" matPrefix (click)="clearDate('recursoInicio')">close</mat-icon>
              <input
                matInput
                readonly
                [matDatepicker]="pickerRecursoInicio"
                formControlName="recursoInicio"
                name="recursoInicio" />
              <mat-datepicker-toggle matSuffix [for]="pickerRecursoInicio"></mat-datepicker-toggle>
              <mat-datepicker #pickerRecursoInicio></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Recurso fim</mat-label>
              <mat-icon class="danger" matPrefix (click)="clearDate('recursoFim')">close</mat-icon>
              <input
                matInput
                readonly
                [matDatepicker]="pickerRecursoFim"
                formControlName="recursoFim"
                name="recursoFim" />
              <mat-datepicker-toggle matSuffix [for]="pickerRecursoFim"></mat-datepicker-toggle>
              <mat-datepicker #pickerRecursoFim></mat-datepicker>
            </mat-form-field>
          </div>

          <app-arquivo-upload
            #upload
            *ngIf="!isPermitirAlteracao"
            label="Anexo"
            [uploadPath]="andamentoUploadPath"
            accept="application/pdf"
            [fc]="andamentoUploadFc"
            [usarArquivoViewer]="true"></app-arquivo-upload>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <ng-container *ngIf="isPermitirAlteracao; then permitirAlteracaoBtn; else salvarCancelarBtns"></ng-container>
      <ng-template #permitirAlteracaoBtn>
        <button mat-raised-button color="primary" (click)="permitirAlteracao()" [disabled]="!isAndamentoConcluido">
          <mat-icon>edit</mat-icon> Reverter
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>arrow_back</mat-icon> Voltar</button>
      </ng-template>
      <ng-template #salvarCancelarBtns>
        <button mat-raised-button color="primary" (click)="gravar()" *ngIf="this.id === undefined">
          <mat-icon>add</mat-icon> Adicionar
        </button>
        <button mat-raised-button color="primary" (click)="gravar()" *ngIf="this.id !== undefined">
          <mat-icon>edit</mat-icon> Atualizar
        </button>
        <button mat-stroked-button color="warn" (click)="cancelar()"><mat-icon>close</mat-icon> Cancelar</button>
      </ng-template>
    </div>
  </mat-card-actions>
</mat-card>
