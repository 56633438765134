/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { UsuarioExterno } from '../models/usuario-externo'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class UsuarioExternoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  gravar(form: UsuarioExterno): Observable<any> {
    return this.http
      .post<string>(`${this.baseUrl}/usuario-externo`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  get(cpf: string): Observable<UsuarioExterno> {
    return this.http
      .get<UsuarioExterno>(`${this.baseUrl}/usuario-externo/${cpf}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  atualizar(form: UsuarioExterno): Observable<UsuarioExterno> {
    return this.http
      .put<UsuarioExterno>(`${this.baseUrl}/usuario-externo`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listar(): Observable<UsuarioExterno[]> {
    return this.http
      .get<UsuarioExterno[]>(`${this.baseUrl}/usuario-externo`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
