<table class="list">
  <tr class="candidato-row">
    <th colspan="5">
      <div class="candidato-nav">
        <div>
          <button mat-icon-button color="basic" tabindex="1" [disabled]="primeiroCandidato" (click)="anterior()">
            <mat-icon>skip_previous</mat-icon>
          </button>
        </div>
        <div>
          <b>{{ candidato }}</b>
        </div>
        <div>
          <button
            mat-icon-button
            color="basic"
            [tabIndex]="3 * idToIdxMap.size + 3"
            [disabled]="ultimoCandidato"
            (click)="proximo()">
            <mat-icon>skip_next</mat-icon>
          </button>
        </div>
      </div>
    </th>
  </tr>
  <tr class="title">
    <th class="criterio-cell"><div>Criterio</div></th>
    <th><div>Nota Maxima</div></th>
    <th><div>Examinador 1</div></th>
    <th><div>Examinador 2</div></th>
    <th><div>Examinador 3</div></th>
  </tr>
  <tr *ngFor="let linha of tabelaNotas?.linhasTabela">
    <div *ngIf="linha.tipo === 'GRUPO'; then linha_grupo; else linha_nota"></div>
    <ng-template #linha_grupo>
      <td class="criterio-cell grupo-titulo" colspan="5">
        <div>{{ linha.titulo }} ( Máximo {{ linha.notaMaxima | number : '1.2-2' }} )</div>
      </td>
    </ng-template>
    <ng-template #linha_nota>
      <td class="criterio-cell">
        <div>{{ linha.titulo }}</div>
      </td>
      <td class="nota-cell">
        <div>{{ linha.notaMaxima | number : '1.2-2' }}</div>
      </td>
      <td class="nota-cell separador input-cell">
        <input
          class="nota-input"
          [tabIndex]="linha.idx! + 2"
          [formControl]="nota1.at(linha.idx!)"
          [dropSpecialCharacters]="false"
          mask="0*,9999"
          (keyup.enter)="keytab()"
          (blur)="notaBlur($event)"
          (focus)="notaFocus($event)" />
      </td>
      <td class="nota-cell separador input-cell">
        <input
          class="nota-input"
          [tabIndex]="linha.idx! + 2 + idToIdxMap.size"
          [formControl]="nota2.at(linha.idx!)"
          [dropSpecialCharacters]="false"
          mask="0*,9999"
          (keyup.enter)="keytab()"
          (blur)="notaBlur($event)"
          (focus)="notaFocus($event)" />
      </td>
      <td class="nota-cell separador input-cell">
        <input
          class="nota-input"
          [tabIndex]="linha.idx! + 2 + 2 * idToIdxMap.size"
          [formControl]="nota3.at(linha.idx!)"
          [dropSpecialCharacters]="false"
          mask="0*,9999"
          (keyup.enter)="keytab()"
          (blur)="notaBlur($event)"
          (focus)="notaFocus($event)" />
      </td>
    </ng-template>
  </tr>
  <tr class="total">
    <td class="criterio-cell"><div>Nota Total</div></td>
    <td class="nota-cell">
      <div>{{ totalNotaMax !== null ? (totalNotaMax | numberRound : formatoNota) : '' }}</div>
    </td>
    <td class="nota-cell separador">
      <div class="notaTotal">{{ totalNota1 !== null ? (totalNota1 | numberRound : formatoNota) : '' }}</div>
    </td>
    <td class="nota-cell separador">
      <div class="notaTotal">{{ totalNota2 !== null ? (totalNota2 | numberRound : formatoNota) : '' }}</div>
    </td>
    <td class="nota-cell separador">
      <div class="notaTotal">{{ totalNota3 !== null ? (totalNota3 | numberRound : formatoNota) : '' }}</div>
    </td>
  </tr>
</table>

<br />
<div fxLayoutAlign="space-between center">
  <button
    mat-raised-button
    color="primary"
    (click)="salvar()"
    [tabIndex]="3 * idToIdxMap.size + 2"
    [disabled]="salvando || provaConcluida">
    <mat-icon>save</mat-icon>Salvar
  </button>
  <button mat-stroked-button color="warn" (click)="retornarLista()" tabindex="-1">
    <mat-icon>close</mat-icon>Retornar a lista
  </button>
</div>
