<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign="space-between center">
    <h1>Usuários externos</h1>
    <div>
      <button mat-raised-button color="primary" routerLink="/usuario-externo/cadastro">
        <mat-icon>add</mat-icon> Adicionar
      </button>
    </div>
  </div>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" (keyup)="search($event)" placeholder="" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="nome" matSortDirection="asc">
      <ng-container matColumnDef="idUsuario">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.idUsuario }}</td>
      </ng-container>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
      </ng-container>
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF</th>
        <td mat-cell *matCellDef="let row">{{ row.cpf | mask : '000.000.000-00' }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container>
      <ng-container matColumnDef="identificacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificação</th>
        <td mat-cell *matCellDef="let row">{{ row.identificacao }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index" style="min-width: 215px">
          <button mat-icon-button color="basic" routerLink="/usuario-externo/cadastro/{{ row.cpf }}" title="Editar">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
