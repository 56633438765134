<div class="input-row">
  <span class="input-checkbox">
    <mat-checkbox
      [formControl]="formControl"
      style="white-space: normal"
      required="complemento?.requerido"
      id="iccb-{{ complemento!.id }}">
    </mat-checkbox
    ><br />
    <span style="cursor: default" [ngClass]="{ 'disabled-label': disabled }">{{
      complemento?.requerido ? 'Obrigatório' : 'Opcional'
    }}</span>
  </span>
  <label for="iccb-{{ complemento!.id }}-input" class="checkbox-label">
    <p [ngClass]="{ 'disabled-label': disabled }">{{ complemento?.explicacao }}</p>
  </label>
</div>
