<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions class="dialog-action">
  <button type="button" mat-raised-button color="primary" (click)="onConfirm()">Sim</button>
  <button type="button" mat-button (click)="onDismiss()">Não</button>
</div>
