/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { DatePipe } from '@angular/common'
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { UnespCoreAuthService, UnespCoreMessageService } from 'src/libs/unesp-core'
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component'
import { debounceTime, distinctUntilChanged, fromEvent, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { FiltroCcpt } from 'src/app/enums/filtro-ccpt'
import { FiltroCacp } from 'src/app/enums/filtro-cacp'
import { TipoConcurso, TipoConcursoDTA, TipoConcursoLabel } from 'src/app/enums/tipo-concurso'
import { Concurso } from 'src/app/models/concurso'
import { ConcursoService } from 'src/app/services/concurso.service'
import { LocalStorageService } from 'src/app/services/local-storage.service'
import { Andamento } from '../../../models/andamento'
import { TipoAndamento } from 'src/app/enums/tipo-andamento'

@Component({
  selector: 'app-lista-concurso',
  templateUrl: './lista-concurso.component.html',
  styleUrls: ['./lista-concurso.component.css'],
})
export class ListaConcursoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef
  totalElements: number = 0

  colunasGrid: string[] = ['id', 'campus', 'processo', 'titulo', 'inscricao', 'tipo', 'andamento', 'action', 'action2']
  dataSource: MatTableDataSource<Concurso> = new MatTableDataSource()
  datepipe: DatePipe = new DatePipe('pt-BR')

  filtroCcpt = FiltroCcpt
  filtroCcptSelecionado = 'TODOS'
  filtroTipoConcursoSelecionado = ''
  filtroCacp = FiltroCacp
  filtroCacpSelecionado = 'TODOS'

  andamento: Andamento[] = []
  filtroAndamentoSelecionado = ''
  tipoAndamento = TipoAndamento
  tipoConcursoDTA = TipoConcursoDTA
  tipoConcurso = TipoConcurso

  constructor(
    private concursoService: ConcursoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private unespCoreAuthService: UnespCoreAuthService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.filtroTipoConcursoSelecionado = this.localStorageService.get('lista-concurso.filtro-tipo-concurso') ?? ''
    this.filtroAndamentoSelecionado = this.localStorageService.get('lista-concurso.filtro-etapa') ?? ''
    if (this.unespCoreAuthService.hasPermissionBasedRoles(['CCPT'])) {
      this.filtroCcptSelecionado = this.localStorageService.get('lista-concurso.filtro-ccpt') ?? 'PRAZO_PARECER'
    } else {
      this.filtroCcptSelecionado = this.localStorageService.get('lista-concurso.filtro-ccpt') ?? 'TODOS'
    }

    if (this.unespCoreAuthService.hasPermissionBasedRoles(['CACP'])) {
      this.filtroCacpSelecionado = this.localStorageService.get('lista-concurso.filtro-cacp') ?? 'PRAZO_PARECER'
    } else {
      this.filtroCacpSelecionado = this.localStorageService.get('lista-concurso.filtro-cacp') ?? 'TODOS'
    }
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()

    setTimeout(() => {
      this.input.nativeElement.value = this.localStorageService.get('lista-concurso.search') ?? ''
      this.getData()
    }, 0)
  }

  public getData() {
    this.localStorageService.set('lista-concurso.search', this.input?.nativeElement.value)
    this.localStorageService.set('lista-concurso.filtro-ccpt', this.filtroCcptSelecionado)
    this.localStorageService.set('lista-concurso.filtro-cacp', this.filtroCacpSelecionado)
    this.localStorageService.set('lista-concurso.filtro-etapa', this.filtroAndamentoSelecionado)
    this.localStorageService.set('lista-concurso.filtro-tipo-concurso', this.filtroTipoConcursoSelecionado)
    this.concursoService
      .listar(
        this.input?.nativeElement.value,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.filtroCacpSelecionado,
        this.filtroCcptSelecionado,
        this.filtroAndamentoSelecionado,
        this.filtroTipoConcursoSelecionado
      )
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data['content'])
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
        // custom: sort para a coluna do tipo data
        this.dataSource.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'inscricao':
              return item.inicio
            default:
              return item[property]
          }
        }
      })
  }

  excluir(concurso: Concurso): void {
    let index = this.dataSource.data.indexOf(concurso)
    const confirmDialog = new ConfirmDialogModel('Atenção', `Confirma a exclusão do Concurso #${concurso.id}`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.concursoService.excluir(concurso.id as number).subscribe(concurso => {
          let tmp: Concurso[] = this.dataSource.data
          tmp.splice(index, 1)
          this.dataSource.data = tmp
          this.unespCoreMessageService.showMessageSuccess(`Concurso ${concurso.id} excluído com sucesso`)
        })
      }
    })
  }

  getTipo(tipo: string): string {
    return TipoConcursoLabel.get(tipo) ?? ''
  }

  exportar() {
    this.concursoService
      .listar(
        this.input?.nativeElement.value,
        0,
        this.totalElements,
        this.filtroCacpSelecionado,
        this.filtroCcptSelecionado,
        this.filtroAndamentoSelecionado,
        this.filtroTipoConcursoSelecionado
      )
      .subscribe(data => {
        let recursos: Concurso[] = data['content']
        let content = `"#","QTDE-INSCRITOS","PROCESSO","NÚMERO-CONTAD","TIPO","ANDAMENTO","ANDAMENTO-DATA","CÂMPUS","TÍTULO","QTDE-VAGAS","VALOR-INSCRIÇÃO","VALOR-SALÁRIO","INÍCIO","TERMINO","RESPONSÁVEIS-RECURSOS","DEVOLUÇÃO-HABILITADA","ONLINE","ATIVO"\r\n`
        recursos.forEach(row => {
          content += `"${row.id}","${row.quantidadeInscritos}","${row.processo}","${row.numeroContad}","${this.getTipo(
            row.tipo
          )}","${row.andamento}","${this.datepipe.transform(row.andamentoData, 'dd/MM/YYYY')}","${
            row.campus
          }","${row.titulo.replace(/"/g, "'")}","${row.vagas}","${row.valorInscricao}","${
            row.salario
          }","${this.datepipe.transform(row.inicio, 'dd/MM/YYYY HH:mm:ss')}","${this.datepipe.transform(
            row.fim.toString() + ' ' + row.horaFim.toString(),
            'dd/MM/YYYY HH:mm:ss'
          )}","${row.responsaveisRecurso}","${row.habilitarDevolucao ? 'Sim' : 'Não'}","${
            row.online ? 'Sim' : 'Não'
          }","${row.ativo ? 'Sim' : 'Não'}"\r\n`
        })
        let binaryData = []
        binaryData.push(content)
        let downloadLink = document.createElement('a')
        downloadLink.setAttribute('download', 'concursos.csv')
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csv;charset=utf-8;' }))
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      })
  }

  canModify(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA'])
  }

  canOpenOptions(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'FINANCAS'])
  }

  isCCPT(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['CCPT'])
  }

  isCACP(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['CACP'])
  }

  mostrarProvasBtn(concurso: Concurso): boolean {
    return ['RDIDP', 'RTP', 'RTC'].includes(concurso.tipo)
    //return false
  }

  showTipoConcursoDTA(): boolean {
    return (
      !this.unespCoreAuthService.hasPermissionBasedRoles(['RH']) &&
      this.unespCoreAuthService.hasPermissionBasedRoles(['DTA'])
    )
  }

  selecionar() {
    this.getData()
  }
}
