<h1>Candidato</h1>
<mat-card>
  <mat-card-content>
    <fieldset fxFlex="100%">
      <legend>Alterar e-mail</legend>
      <form [formGroup]="form" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field appearance="outline">
          <mat-label>Atual</mat-label>
          <input
            matInput
            formControlName="emailAtual"
            name="email"
            type="email"
            [email]="true"
            required
            readonly="readonly" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Novo</mat-label>
          <input matInput formControlName="novoEmail" name="email" type="email" [email]="true" required />
        </mat-form-field>
      </form>
    </fieldset>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <button mat-raised-button color="primary" (click)="gravar()"><mat-icon>edit</mat-icon> Atualizar</button>
      <button mat-stroked-button color="warn" routerLink="/usuario-candidato">
        <mat-icon>close</mat-icon> Cancelar
      </button>
    </div>
  </mat-card-actions>
</mat-card>
