<div fxLayout="column" fxLayoutGap="20px">
  <h1>Perfis</h1>

  <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
    <div fxLayout.gt-md="row" fxLayoutAlign="start start" fxLayoutGap="15px">
      <mat-form-field appearance="outline">
        <mat-label>Consultar usuários com perfil</mat-label>
        <mat-select [formControl]="opcaoPerfilFC">
          <mat-option *ngFor="let perfil of perfis" [value]="perfil.idPerfil">
            {{ perfil.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        title="Adicionar usuário ao perfil"
        mat-raised-button
        color="primary"
        (click)="adicionarUsuarioDialog()"
        [disabled]="opcaoPerfilFC.value === ''">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
    <div fxLayout.gt-md="row" fxLayoutAlign="start start" fxLayoutGap="15px">
      <mat-form-field appearance="outline">
        <mat-label>Consultar perfis do usuário</mat-label>
        <input matInput [formControl]="buscaPerfis" />
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        routerLink="{{ buscaPerfis.value }}"
        [disabled]="buscaPerfis.value === ''">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>

  <div>
    <mat-form-field appearance="outline" fxFill>
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Pesquisar</mat-label>
      <input matInput type="search" (keyup)="search($event)" placeholder="" #input />
    </mat-form-field>
  </div>

  <div>
    <table mat-table #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let usuario">{{ usuario.nome }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
        <td mat-cell *matCellDef="let usuario">{{ usuario.emailPrincipal }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="min-width: 140px"></th>
        <td mat-cell *matCellDef="let usuario">
          <button mat-icon-button color="warn" (click)="excluir(usuario)" title="Excluir">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
