<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign="space-between center">
    <h1>Formulário Complementar</h1>
    <div>
      <button mat-raised-button color="primary" routerLink="adicionar"><mat-icon>add</mat-icon> Adicionar</button>
    </div>
  </div>

  <div fxLayout.lt-md="column" fxLayoutGap.gt-md="20px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Pesquisar</mat-label>
      <input matInput type="search" (keyup)="search($event)" placeholder="" #input />
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Pré-visualizar / Reordenar</mat-label>
      <mat-select [formControl]="previaFC">
        <mat-option *ngFor="let enum of tipoConcurso | keyvalue" [value]="enum.key">
          {{ enum.value }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix style="opacity: 60%">preview</mat-icon>
    </mat-form-field>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="tipoConcurso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="tipoConcurso">Tipo de Concurso</th>
        <td mat-cell *matCellDef="let row">{{ formatarListaTipoConcurso(row.tipoConcurso) }}</td>
      </ng-container>
      <ng-container matColumnDef="etapa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="etapa">Etapa</th>
        <td mat-cell *matCellDef="let row">{{ etapaTexto(row.etapa) }}</td>
      </ng-container>
      <ng-container matColumnDef="titulo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="grupo">Título</th>
        <td mat-cell *matCellDef="let row">{{ row.grupo }}</td>
      </ng-container>
      <ng-container matColumnDef="subtitulo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="explicacao">Subtítulo</th>
        <td mat-cell *matCellDef="let row">{{ row.explicacao }}</td>
      </ng-container>
      <ng-container matColumnDef="tipoFormulario">
        <th mat-header-cell *matHeaderCellDef>Tipo de formulário</th>
        <td mat-cell *matCellDef="let row">{{ tipoComplementoTexto(row.tipoComplemento) }}</td>
      </ng-container>
      <ng-container matColumnDef="obrigatorio">
        <th mat-header-cell *matHeaderCellDef>Obrigatório</th>
        <td mat-cell *matCellDef="let row">{{ row.requerido ? 'Sim' : 'Não' }}</td>
      </ng-container>
      <ng-container matColumnDef="ativo">
        <th mat-header-cell *matHeaderCellDef>Ativo</th>
        <td mat-cell *matCellDef="let row">{{ row.ativo ? 'Sim' : 'Não' }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" style="min-width: 180px">
          <button mat-icon-button color="basic" routerLink="editar/{{ row.id }}" title="Editar">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="basic" routerLink="adicionar/{{ row.id }}" title="Adicionar ao mesmo Título">
            <mat-icon>control_point_duplicate</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="excluir(row)" title="Excluir">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
