/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { UnespCoreEnvironment, UnespCoreGenericService, UnespCoreMessageService } from 'src/libs/unesp-core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ArquivoUploadService extends UnespCoreGenericService {
  readonly ROUTE = 'arquivo'

  constructor(
    // Dependencias da classe pai.
    http: HttpClient,
    @Inject('environment') environment: UnespCoreEnvironment
  ) {
    super(http, environment)
  }

  arquivoUpload(path: string, file: FormData): Observable<any> {
    return this.create<FormData>(`${this.ROUTE}/${path}`, file)
  }
}
