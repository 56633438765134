/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { TipoComplemento } from 'src/app/enums/tipo-complemento'
import { FormularioComplementarService } from 'src/app/services/formulario-complementar.service'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips'
import { map, Observable, of, startWith } from 'rxjs'
import { FormularioComplementar } from 'src/app/models/formulario-complementar'
import { FormularioEtapa } from 'src/app/enums/formulario-etapa'

export interface ChipTipoDeConcurso {
  key: string
  nome: string
}

@Component({
  selector: 'app-cadastro-formulario-complementar',
  templateUrl: './cadastro-formulario-complementar.component.html',
  styleUrls: ['./cadastro-formulario-complementar.component.scss'],
})
export class CadastroFormularioComplementarComponent implements OnInit {
  @ViewChild('tipoConcursoChipList') tipoConcursoChipList: MatChipGrid | undefined

  id?: number
  adicionar: boolean = false

  tiposDeConcursoAutocompletar: Map<string, string> = new Map()
  tiposDeConcursoSelecionados: ChipTipoDeConcurso[] = []
  tiposDeConcursoFiltrados: Observable<Map<string, string>> = of(this.tiposDeConcursoAutocompletar)

  matChipInputFC: FormControl = new FormControl('')
  tiposDeConcursoFC: FormControl = new FormControl('', Validators.required)
  tituloFC: FormControl = new FormControl('', Validators.required)
  subtituloFC: FormControl = new FormControl('')
  etapaFC: FormControl = new FormControl('', Validators.required)
  tipoDeCampoFC: FormControl = new FormControl('', Validators.required)
  obrigatorioFC: FormControl = new FormControl('', Validators.required)
  ativoFC: FormControl = new FormControl('', Validators.required)

  form = new FormGroup({
    id: new FormControl(),
    tipoConcurso: this.tiposDeConcursoFC,
    grupo: this.tituloFC,
    explicacao: this.subtituloFC,
    etapa: this.etapaFC,
    tipoComplemento: this.tipoDeCampoFC,
    requerido: this.obrigatorioFC,
    ativo: this.ativoFC,
  })

  tipoComplemento = TipoComplemento
  formularioEtapa = FormularioEtapa

  constructor(
    private formularioComplementarService: FormularioComplementarService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    for (let key in TipoConcurso) this.tiposDeConcursoAutocompletar.set(key, (TipoConcurso as any)[key])
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['modelo']

      if (this.id) this.adicionar = true
      else this.id = params['id']

      if (this.id) {
        this.formularioComplementarService.get(this.id).subscribe(obj => {
          if (!this.adicionar) {
            this.form.get('id')?.setValue(obj.id)
            this.subtituloFC.setValue(obj.explicacao)
            this.etapaFC.setValue(obj.etapa)
            this.tipoDeCampoFC.setValue(obj.tipoComplemento)
            this.obrigatorioFC.setValue(obj.requerido.toString())
            this.ativoFC.setValue(obj.ativo.toString())
          }
          this.tiposDeConcursoFC.setValue(obj.tipoConcurso)
          this.initMatChip(obj.tipoConcurso)
          this.tituloFC.setValue(obj.grupo)
        })
      } else this.adicionar = true
    })

    this.tiposDeConcursoFiltrados = this.matChipInputFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        const str = (TipoConcurso as any)[value] ? this.addChip(value) : (value as string)
        return str ? this._filtroTiposDeConcurso(str) : this.tiposDeConcursoAutocompletar
      })
    )

    this.tiposDeConcursoFC.valueChanges.subscribe(
      () => (this.tipoConcursoChipList!.errorState = this.tiposDeConcursoFC.invalid)
    )
  }

  private initMatChip(str: string) {
    let tipos = str.split(',')
    for (let tipo of tipos) {
      this.tiposDeConcursoSelecionados.push({ key: tipo, nome: (TipoConcurso as any)[tipo] })
      this.tiposDeConcursoAutocompletar.delete(tipo)
    }
  }

  private _filtroTiposDeConcurso(str: string): Map<string, string> {
    const filterValue = str.toLowerCase()
    let ret: Map<string, string> = new Map()

    this.tiposDeConcursoAutocompletar.forEach(
      (value, key) => value.toLowerCase().includes(filterValue) && ret.set(key, value)
    )

    return ret
  }

  addChip(key: string) {
    this.tiposDeConcursoSelecionados.push({ key, nome: (TipoConcurso as any)[key] })
    this.tiposDeConcursoAutocompletar.delete(key)
    let tipos = this.tiposDeConcursoFC.value
    this.tiposDeConcursoFC.setValue(tipos ? tipos + `,${key}` : key)
    this.tiposDeConcursoFC.markAsTouched()
  }

  removeChip(str: string) {
    const idxa = this.tiposDeConcursoSelecionados.findIndex(value => value.key == str)
    this.tiposDeConcursoSelecionados.splice(idxa, 1)

    this.tiposDeConcursoAutocompletar.set(str, (TipoConcurso as any)[str])

    let arr: string[] = this.tiposDeConcursoFC.value.toString().split(',')
    const idxb = arr.indexOf(str)
    arr.splice(idxb, 1)
    this.tiposDeConcursoFC.setValue(arr.join(','))
    this.tiposDeConcursoFC.markAsTouched()
  }

  blurChipIput(event: MatChipInputEvent): void {
    event.chipInput!.clear()
    this.tipoConcursoChipList!.errorState = this.tiposDeConcursoFC.invalid
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
    if (this.tiposDeConcursoFC.invalid) {
      this.tipoConcursoChipList!.errorState = true
    }
  }

  salvar(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
      return
    }

    let formulario = <FormularioComplementar>this.form.value

    if (formulario.id) {
      this.formularioComplementarService.atualizar(formulario.id as number, formulario).subscribe(formulario => {
        this.unespCoreMessageService.showMessageSuccess(
          `Formulário Complementar #${formulario.id} atualizado com sucesso`
        )
        this.router.navigate(['/formulario-complementar'])
      })
    } else {
      this.formularioComplementarService.gravar(formulario).subscribe(formulario => {
        this.unespCoreMessageService.showMessageSuccess(
          `Formulário Complementar #${formulario.id} cadastrado com sucesso`
        )
        this.router.navigate(['/formulario-complementar'])
      })
    }

    this.unespCoreMessageService.showMessageSuccess(`Formulario complementar #${this.id} salvo`)
  }
}
