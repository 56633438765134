/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { InscricaoNota } from '../models/inscricao-nota'
import { InscricaoNotaCriterio } from '../models/inscricao-nota-criterio'
import { NotaComCriterios } from '../models/nota-com-criterios'

import { ProvaComNotas } from '../models/prova-com-notas'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class InscricaoNotasService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = `${env.baseUrlApi}/inscricao-nota`
  }

  listar(idConcurso: number | string, tipoProva: string): Observable<ProvaComNotas> {
    return this.http
      .get<ProvaComNotas>(`${this.baseUrl}/${idConcurso}/${tipoProva}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  get(idConcurso: number | string, tipoProva: string, idInscricao: number | string): Observable<NotaComCriterios> {
    return this.http
      .get<NotaComCriterios>(`${this.baseUrl}/${idConcurso}/${tipoProva}/${idInscricao}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  salvar(
    idConcurso: number | string,
    tipoProva: string,
    idInscricao: number | string,
    criterios: InscricaoNotaCriterio[]
  ): Observable<InscricaoNota> {
    return this.http
      .post<InscricaoNota>(`${this.baseUrl}/${idConcurso}/${tipoProva}/${idInscricao}`, criterios)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
