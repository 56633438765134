<mat-card>
  <mat-card-content fxFlexFill fxLayout.gt-md="row" fxLayout.lt-md="column">
    <div fxFlex.gt-md="30" fxFlex.lt-md="100" class="tabela-anexos">
      <table>
        <tr
          *ngFor="let anexoLI of anexoList; let i = index"
          (click)="mostrarArquivo(i)"
          [ngClass]="{ 'anexo-selecionado': anexoSelecionado === i }">
          <td [ngClass]="{ desabilitado: !arquivoUnico }">
            <mat-icon [ngClass]="{ checked: arquivoUnico && anexoList[i].marked }" (click)="arquivoToggle(i)">
              {{
                anexoList[i].erro
                  ? 'error_outline'
                  : !arquivoUnico
                  ? 'description'
                  : anexoList[i].marked
                  ? 'check_box'
                  : 'check_box_outline_blank'
              }}
            </mat-icon>
          </td>
          <td>{{ anexoLI.nome }}</td>
        </tr>
      </table>
    </div>
    <div fxFlex>
      <div *ngIf="fileNotFound" fxLayoutAlign="center center" class="arquivo-nao-encontrado">
        <div>
          <h2>&nbsp;&nbsp;Arquivo não encontrado</h2>
          <img src="assets/img/page-not-found.png" />
        </div>
      </div>
      <iframe #anexoIframe style="height: 100%; width: 100%"></iframe>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <mat-button-toggle-group [formControl]="todosFC" [disabled]="!arquivoUnico">
        <mat-button-toggle value="todos"><mat-icon>check_box</mat-icon> Todos</mat-button-toggle>
        <mat-button-toggle value="nenhum"><mat-icon>check_box_outline_blank</mat-icon> Nenhum</mat-button-toggle>
      </mat-button-toggle-group>
      <div
        fxLayoutAlign.gt-md="end center"
        fxLayoutAlign.lt-md="center stretch"
        fxLayout.lt-md="column"
        fxLayoutGap="20px">
        <mat-button-toggle-group multiple [formControl]="opcoesFC" [disabled]="printDisabled">
          <mat-button-toggle value="rosto">
            <mat-icon [ngClass]="{ checked: folhadeRosto }">
              {{ folhadeRosto ? 'check_box' : 'check_box_outline_blank' }}</mat-icon
            >
            Folha de rosto</mat-button-toggle
          >
          <mat-button-toggle value="unico">
            <mat-icon [ngClass]="{ checked: arquivoUnico }">
              {{ arquivoUnico ? 'check_box' : 'check_box_outline_blank' }}</mat-icon
            >
            Arquivo único</mat-button-toggle
          >
        </mat-button-toggle-group>
        <button mat-raised-button color="primary" (click)="imprimir()" [disabled]="isPrintDisabled()">
          <mat-icon>print</mat-icon>Imprimir
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
