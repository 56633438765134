/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { ListagemPagamento } from './../../../models/listagem-pagamento'
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ListagensDiversasService } from '../../../services/listagens-diversas.service'
import { ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ArquivoViewerDialogComponent } from '../../arquivo-viewer-dialog/arquivo-viewer-dialog.component'
import { DownloadService } from '../../../services/download.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { InscricaoPagamentoService } from '../../../services/inscricao-pagamento.service'

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss'],
})
export class PagamentoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef

  totalElements: number = 0
  colunasGrid: string[] = [
    'id',
    'nome',
    'cpf',
    'protocolo',
    'valor',
    'actionComprovante',
    'pago',
    'autor',
    'data',
    'concluida',
    'action',
  ]
  dataSource: MatTableDataSource<ListagemPagamento> = new MatTableDataSource()
  pagamentosAtualizados: ListagemPagamento[] = []
  pagamentoListado: FormControl = new FormControl('')
  idConcurso?: number
  filtro: string = ''
  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(
    private listagensDiversasService: ListagensDiversasService,
    private route: ActivatedRoute,
    private unespCoreMessageService: UnespCoreMessageService,
    private dialog: MatDialog,
    private downloadService: DownloadService,
    private inscricaoPagamentoService: InscricaoPagamentoService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['id']
      this.getData()
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
  }

  private getData() {
    this.listagensDiversasService
      .getPagamento(
        this.idConcurso!,
        this.input?.nativeElement.value,
        this.filtro,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data['content'])
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
  }

  exportar() {
    this.listagensDiversasService
      .getPagamento(this.idConcurso!, this.input?.nativeElement.value, this.filtro, 0, this.totalElements)
      .subscribe(data => {
        let recursos: ListagemPagamento[] = data['content']
        let content = `"#","NOME","CPF","PROTOCOLO","VALOR","PAGO","AUTOR","DATA","CONCLUIDA"\r\n`

        recursos.forEach(row => {
          content += `"${row.id}","${row.nome}","${row.cpf}","${row.protocolo}","${row.valor}","${
            row.pago ? 'Sim' : 'Não'
          }","${row.autor == null ? '' : row.autor}","${
            row.data == null ? '' : this.datepipe.transform(row.data, 'dd/MM/YYYY HH:mm:ss')
          }","${row.concluida ? 'Sim' : 'Nao'}"\r\n`
        })
        let binaryData = []
        binaryData.push(content)
        let downloadLink = document.createElement('a')
        downloadLink.setAttribute('download', `listagem-pagamento-${this.idConcurso}.csv`)
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csvcharset=utf-8' }))
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      })
  }

  selecionar(): void {
    this.getData()
  }

  salvar(row: ListagemPagamento) {
    this.inscricaoPagamentoService.salvarEdicao(row.id, row.pago).subscribe(value => {
      if (value) {
        row.pago = value.pago
        row.autor = value.pagoUsuario
        row.data = value.pagoData
        this.unespCoreMessageService.showMessageSuccess(`${row.nome}, registrado com sucesso`)
      } else {
        this.unespCoreMessageService.showMessageError(`Falha ao registrar, ${row.nome}`)
      }
    })
  }

  concluir(row: ListagemPagamento) {
    this.inscricaoPagamentoService.concluir(row.id).subscribe(value => {
      if (value) {
        row.concluida = true
        this.unespCoreMessageService.showMessageSuccess(`${row.nome}, concluído com sucesso`)
      } else {
        this.unespCoreMessageService.showMessageError(`Falha ao concluir, ${row.nome}`)
      }
    })
  }

  verAnexo(caminho: string, anexo: string, titulo: string) {
    this.downloadService.arquivoObjectURLPorPath(caminho, anexo).subscribe(url =>
      this.dialog.open(ArquivoViewerDialogComponent, {
        data: { tituloDoArquivo: titulo, urlDoArquivo: url },
        width: '80%',
        height: '90%',
      })
    )
  }
}
