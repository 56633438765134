/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { TipoProva, TipoProvaVersao } from 'src/app/enums/tipo-prova'
import { ConcursoProva } from 'src/app/models/concurso-prova'
import { ConcursoProvaService } from 'src/app/services/concurso-prova.service'

@Component({
  selector: 'app-provas-gerencia',
  templateUrl: './provas-gerencia.component.html',
  styleUrls: ['./provas-gerencia.component.css'],
})
export class ProvasGerenciaComponent implements OnInit {
  idConcurso?: number
  concursoTitulo?: string
  concursoTipo?: string

  colunasGrid: string[] = ['tipoProva', 'acoes']
  dataSource: MatTableDataSource<ConcursoProva> = new MatTableDataSource()

  tipoProva: Map<string, string> = new Map()
  adicionarProvaFC = new FormControl()
  addProvaLabel: string = ''
  temProvaConcluida: boolean = false

  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(
    private unespCoreMessageService: UnespCoreMessageService,
    private concursoProvaService: ConcursoProvaService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['idConcurso']
      if (this.idConcurso) {
        this.initTable()
        this.adicionarProvaFC.valueChanges.subscribe(value => {
          if (value) {
            this.adicionarProva(value)
            this.adicionarProvaFC.reset()
          }
        })
      }
    })
  }

  public initTable() {
    this.concursoProvaService.listar(this.idConcurso!).subscribe(concurso => {
      this.dataSource = new MatTableDataSource(concurso.provas)
      this.atualizaTemProvaConcluida()
      this.concursoTitulo = concurso.titulo
      this.concursoTipo = (TipoConcurso as any)[concurso.tipo]
      this.initTipoProva(concurso.provas)
    })
  }

  private initTipoProva(provas: ConcursoProva[]) {
    for (const tipo in TipoProva) this.tipoProvaAddOpcao(tipo)

    for (const prova of provas) this.tipoProvaRemoveOpcao(prova.tipoProva)
  }

  private tipoProvaAddOpcao(tipo: string) {
    for (const versao in TipoProvaVersao)
      if (versao.startsWith(tipo)) this.tipoProva.set(versao, (TipoProvaVersao as any)[versao].desc)

    //this.tipoProva.set(tipo, (TipoProva as any)[tipo])
    if (this.tipoProva.size > 0) {
      this.addProvaLabel = 'Adicionar prova disponível'
      this.adicionarProvaFC.enable()
    }
  }

  private tipoProvaRemoveOpcao(tipo: string) {
    for (const versao in TipoProvaVersao) if (versao.startsWith(tipo)) this.tipoProva.delete(versao)

    //this.tipoProva.delete(tipo)
    if (this.tipoProva.size == 0) {
      this.addProvaLabel = 'Não há mais provas disponíveis'
      this.adicionarProvaFC.disable()
    }
  }

  private adicionarProva(versaoStr: string) {
    const versaoObj = (TipoProvaVersao as any)[versaoStr]

    this.concursoProvaService
      .gravar({ idConcurso: this.idConcurso!, tipoProva: versaoObj.tipo, tipoProvaVersao: versaoStr })
      .subscribe(prova => {
        let tmp = this.dataSource.data
        tmp.push(prova)
        this.dataSource.data = tmp
        this.tipoProvaRemoveOpcao(prova.tipoProva)
      })
  }

  excluir(prova: ConcursoProva) {
    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `Confirma a exclusão da Prova ${this.tipoProvaStr(prova.tipoProva)}`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.concursoProvaService.excluir(prova.idConcurso, prova.tipoProva).subscribe(result => {
          if (result) {
            let tmp = this.dataSource.data
            const idx = tmp.indexOf(prova)
            tmp.splice(idx, 1)
            this.dataSource.data = tmp
            this.tipoProvaAddOpcao(prova.tipoProva)
          }
        })
    })
  }

  divulgar(prova: ConcursoProva) {
    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `Confirma a divulgação da Prova ${this.tipoProvaStr(prova.tipoProva)}`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.concursoProvaService.publicar(prova.idConcurso, prova.tipoProva).subscribe(result => {
          if (result) {
            let tmp = this.dataSource.data
            const idx = tmp.indexOf(prova)
            tmp[idx].publicado = true
            this.dataSource.data = tmp
          }
        })
    })
  }

  concluir(prova: ConcursoProva) {
    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `Confirma a conclusão da Prova ${this.tipoProvaStr(prova.tipoProva)}`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.concursoProvaService.concluir(prova.idConcurso, prova.tipoProva).subscribe(result => {
          if (result) {
            let tmp = this.dataSource.data
            const idx = tmp.indexOf(prova)
            tmp[idx].concluido = true
            this.dataSource.data = tmp
            this.atualizaTemProvaConcluida()
          }
        })
    })
  }

  tipoProvaStr(tipo: string): string {
    return (TipoProva as any)[tipo].tipo
  }

  private atualizaTemProvaConcluida() {
    let provaConcluidaBool = false

    for (let prova of this.dataSource.data) if (prova.concluido) provaConcluidaBool = true

    this.temProvaConcluida = provaConcluidaBool
  }

  gerarResultadoFinal() {
    this.concursoProvaService.gerarResultadoFinal(this.idConcurso!)
  }
}
