<div>
  <div>
    <div *ngIf="complemento?.explicacao" class="input-explicacao">
      <mat-icon style="cursor: default">error_outline</mat-icon>
      <p>{{ complemento?.explicacao }}</p>
    </div>

    <div class="input-row">
      <app-arquivo-upload
        name="fileInput"
        [autoUpload]="true"
        [fc]="formControl"
        accept="application/pdf"
        uploadPath="{{ 'inscricaocomplemento/' + complemento?.idConcurso }}"
        [usarArquivoViewer]="true"
        label="{{ complemento?.requerido ? '(Obrigatório)' : '(Opcional)' }}"></app-arquivo-upload>
    </div>
  </div>
</div>
