/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { UnespCoreAuthService } from '../../services';
import { UnespCoreMenuNavigationService } from '../../services';
import { UnespCoreMenuItem } from '../../interfaces';
import { UnespCoreLoaderService } from '../../services';
import { UnespCoreUsuario } from '../../interfaces';
import { UnespCoreUserService } from '../../services';
import { delay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaChange, MediaObserver } from "@ngbracket/ngx-layout";

/**
 * @description
 *
 * Este componente representa o menu da aplicação.
 *
 */
@Component({
  selector: 'unesp-core-sidenav',
  templateUrl: './unesp-core-sidenav.component.html',
  styleUrls: ['./unesp-core-sidenav.component.scss'],
})
export class UnespCoreSidenavComponent implements OnInit, AfterViewInit {
  @ViewChild('appDrawer')
  appDrawer!: MatSidenav;

  /**
   * @description
   *
   * Exibe username (se existir) no topo do menu lateral.
   *
   */
  @Input() usernameOnSidenav: boolean = false;

  /**
   * @description
   *
   * Exibe spinner customizado.
   *
   */
   @Input() spinnerCustom: string | undefined;

  /**
   * @description
   *
   * Exibe botão de login/logout em baixo do menu lateral.
   *
   */
  @Input() loginOnSidenav: boolean = true;

  /**
   * @description
   *
   * Define se o menu lateral irá ser responsivo ao tamanho da tela.
   * Em resoluções maiores, ficará aberto e fixado ao lado.
   * Em resoluções menores, não ficará fixado.
   */
  @Input() fixedSidenavStyle: boolean = true;

  loading: boolean = false;

  public itensMenu: UnespCoreMenuItem[] = [];

  private lastMqAlias: string = '';

  constructor(
    public unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
    private unespCoreUserService: UnespCoreUserService,
    private unespCoreAuthService: UnespCoreAuthService,
    public unespCoreLoaderService: UnespCoreLoaderService,
    private cd: ChangeDetectorRef,
    private media: MediaObserver,
  ) {
    this.media.asObservable().subscribe((change: MediaChange[]) => {
      if(change && change.length > 0 && this.fixedSidenavStyle) {
        this.lastMqAlias = change[0].mqAlias;
        this.setUnespDrawerStyle();
      }
    });
  }

  ngOnInit(): void {
    this.unespCoreMenuNavigationService.currentItensMenu.subscribe(
      (itensMenu) => {
        this.itensMenu = itensMenu;
        if(this.itensMenu.length > 0) {
          this.setUnespDrawerStyle();
        }
      }
    );
    this.unespCoreLoaderService.isLoading.pipe(delay(1)).subscribe((l) => {
      return (this.loading = l);
    });
  }

  ngAfterViewInit(): void {
    this.unespCoreMenuNavigationService.appDrawer = this.appDrawer;
    this.cd.detectChanges();
  }

  logout(): void {
    this.appDrawer.close();
    this.unespCoreAuthService.logout('Usuário desconectado com sucesso');
  }

  login(): void {
    window.location.href = this.unespCoreAuthService.getLoginUrl();
  }

  get user$(): Observable<UnespCoreUsuario | null> {
    return this.unespCoreUserService.user$;
  }

  private setUnespDrawerStyle() :void {
    if(this.appDrawer) {
      if(!this.unespCoreUserService.getUser() && !this.unespCoreMenuNavigationService.publicMenu) {
        return;
      }
      if(this.lastMqAlias === 'lg' || this.lastMqAlias === 'xl') {
        this.appDrawer.open();
        this.appDrawer.disableClose = true;
        this.appDrawer.mode = 'side';
      } else {
        this.appDrawer.disableClose = false;
        this.appDrawer.mode = 'over';
        if(this.lastMqAlias === 'sm' || this.lastMqAlias === 'xs')
          this.appDrawer.close();
      }
    }
  }

}
