/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core';
import { UnespCoreMessageService } from '../unesp-core-message/unesp-core-message.service';

/**
 * @description
 *
 * Serviço responsável por interceptar as mensagens de erros do backend
 *
 * - Erro 500: Emite mensagem de problema interno no servidor
 * - Outros erros tratados pelo backend Spring Boot são exibidos por este serviço de forma integrada,
 * - Indisponibilidade do backend: Exibe mensagem para usuário tentar novamente em alguns segundos
 *
 */
@Injectable({
  providedIn: 'root'
})
export class UnespCoreApiErrorHandlerService {

  constructor(private unespCoreMessageService: UnespCoreMessageService) { }

  handle(errorResponse: any): void {

    // Erro interno no backend
    if (errorResponse.status === '500') {
      this.unespCoreMessageService.showMessageError('Ocorreu um problema interno do servidor');
      return;
    }

    // Captura mensagem de erro retornado pela API
    if (errorResponse.error && errorResponse.error[0] !== undefined){
      this.unespCoreMessageService.showMessageError(errorResponse.error[0].mensagemUsuario);
      console.error(errorResponse.error[0].mensagemDesenvolvedor);
      return;
    }

    // Erro genérico - Backend indisponível
    this.unespCoreMessageService.showMessageError('Ocorreu um problema, aguarde alguns instantes e tente novamente.');

  }
}
