<input
  type="file"
  #fileInput
  (change)="selecionaArquivo($event)"
  style="display: none"
  [accept]="accept"
  [disabled]="disabled" />

<mat-form-field appearance="outline" fxFlex>
  <mat-label>{{ label }}</mat-label>
  <mat-icon
    matPrefix
    (click)="download()"
    *ngIf="fc.value.status === 4 || fc.value.status === 3 || fc.value.status === 2"
    >download</mat-icon
  >
  <input matInput [formControl]="fc" readonly />
  <mat-icon matSuffix [ngClass]="{ disabledIcon: disabled }" (click)="fileInput.click()">
    {{ fc.value.status !== 0 ? 'refresh' : 'folder_open' }}
  </mat-icon>
  <mat-hint>{{
    fc.value.status === 0
      ? tip
      : fc.value.size > 0
      ? 'Tamanho do arquivo: ' + (fc.value.size / (1024 * 1024) | number : '1.2-2') + ' MB'
      : ''
  }}</mat-hint>
</mat-form-field>
