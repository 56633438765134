/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, map, Observable } from 'rxjs'
import { UsuarioCandidato } from '../models/usuario-candidato'
import { ErrorHandlerService } from './error-handler.service'
import { Inscricao } from '../models/inscricao'
import { ContasVinculadas } from '../models/contas-vinculadas'

@Injectable({
  providedIn: 'root',
})
export class UsuarioCandidatoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  get(id: number): Observable<UsuarioCandidato> {
    return this.http
      .get<UsuarioCandidato>(`${this.baseUrl}/usuario-candidato/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listar(search = '', page = 0, size = 10): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/usuario-candidato`, {
        params: new HttpParams().set('search', search).set('page', page.toString()).set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  senha(id: number, jsonData: any) {
    return this.http.post(`${this.baseUrl}/usuario-candidato/${id}/senha`, jsonData, { responseType: 'text' }).pipe(
      map(resultado => {
        return resultado
      })
    )
  }

  email(id: number, jsonData: any) {
    return this.http.post(`${this.baseUrl}/usuario-candidato/${id}/email`, jsonData, { responseType: 'text' }).pipe(
      map(resultado => {
        return resultado
      })
    )
  }

  vincularEmail(id: number, jsonData: any) {
    return this.http
      .put(`${this.baseUrl}/usuario-candidato/${id}/vincular-emails`, jsonData, { responseType: 'text' })
      .pipe(
        map(resultado => {
          return resultado
        })
      )
  }

  listarInscricoesPorEmail(email: any): Observable<any> {
    return this.http
      .get<ContasVinculadas[]>(`${this.baseUrl}/usuario-candidato/lista-inscricoes-email`, {
        params: new HttpParams().set('email', email),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
