/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { map, Observable, of, startWith } from 'rxjs'
import { Campus } from 'src/app/models/campus'
import { ConsultaAndamentoService } from 'src/app/services/consulta-andamento.service'
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips'
import { DatePipe } from '@angular/common'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { InscricaoConsultaGerencial } from 'src/app/models/inscricao-consulta-gerencial'
import { ConsultaGerencialService } from 'src/app/services/consulta-gerencial.service'
import { FiltrosInscricaoForm } from 'src/app/models/filtros-inscricao-form'

@Component({
  selector: 'app-consulta-gerencial',
  templateUrl: './consulta-gerencial.component.html',
  styleUrls: ['./consulta-gerencial.component.css'],
})
export class ConsultaGerencialComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('unidadesChipList') unidadesChipList: MatChipGrid | undefined
  @ViewChild('unidadeInput') unidadeInput: ElementRef | undefined

  todasUnidades: Campus = { nome: 'Todas Unidades', uuid: -1 }
  showTodasUnidadesMC: boolean = true
  listaDeUnidades: Campus[] = []
  unidadesSelecionadas: Campus[] = []
  unidadesFiltradas: Observable<Campus[]> = of(this.listaDeUnidades)

  unidadeFC: FormControl = new FormControl('')
  dataInscricaoFC: FormControl = new FormControl()
  dataMaxima: Date = new Date()
  candidatoCpfFC: FormControl = new FormControl('')
  candidatoEmailFC: FormControl = new FormControl('', [Validators.email])

  colunasGrid: string[] = ['id', 'inscricao', 'concurso', 'candidato0', 'candidato1', 'documentos', 'contato']
  dataSource: MatTableDataSource<InscricaoConsultaGerencial> = new MatTableDataSource()

  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(
    private consultaAndamentoService: ConsultaAndamentoService,
    private consultaGerencialService: ConsultaGerencialService,
    private unespCoreMessageService: UnespCoreMessageService
  ) {}

  ngOnInit(): void {
    this.consultaAndamentoService.getUnidades().subscribe(arr => {
      this.listaDeUnidades = arr
      this.listaDeUnidades.push(this.todasUnidades)
    })

    this.dataInscricaoFC.setValue(new Date())

    this.unidadesFiltradas = this.unidadeFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        const nome = typeof value === 'string' ? value : this.addChip(value)
        return nome ? this._filtroUnidade(nome as string) : this.listaDeUnidades.slice()
      })
    )
  }

  focusChipIput(): void {
    this.showTodasUnidadesMC = false
  }

  blurChipIput(event: MatChipInputEvent): void {
    event.chipInput!.clear()
    this.showTodasUnidadesMC = true
  }

  addChip(unidade: Campus): void {
    if (unidade.uuid == -1) this.unidadesSelecionadas = []
    else if (this.unidadesSelecionadas.indexOf(unidade) == -1) this.unidadesSelecionadas.push(unidade)
  }

  removeChip(unidade: Campus): void {
    const index = this.unidadesSelecionadas.indexOf(unidade)
    if (index >= 0) this.unidadesSelecionadas.splice(index, 1)
    if (unidade.uuid == -1) this.unidadeInput?.nativeElement.focus()
  }

  private _filtroUnidade(nome: string) {
    const filterValue = nome.toLowerCase()
    return this.listaDeUnidades.filter(
      opt => this.unidadesSelecionadas.indexOf(opt) == -1 && opt.nome.toLowerCase().includes(filterValue)
    )
  }

  nomeUnidade(unidade: Campus): string {
    return unidade && unidade.nome ? unidade.nome : ''
  }

  consultar() {
    if (this.candidatoCpfFC.errors || this.candidatoEmailFC.errors) {
      this.unespCoreMessageService.showMessageError('Valor inválido para CPF e/ou Email')
      return
    }

    let cpf: string | undefined
    if (this.candidatoCpfFC.value != '') {
      cpf = this.candidatoCpfFC.value.trim()
      cpf = `${cpf!.substring(0, 3)}.${cpf!.substring(3, 6)}.${cpf!.substring(6, 9)}-${cpf!.substring(9, 11)}`
    }

    let email: string | undefined
    if (this.candidatoEmailFC.value != '') email = this.candidatoEmailFC.value.trim()

    let filtros: FiltrosInscricaoForm = {
      unidades: this.unidadesSelecionadas.map(uni => {
        return uni.uuid
      }),
      data: this.dataInscricaoFC.value,
      cpf,
      email,
    }
    this.consultaGerencialService.listar(filtros).subscribe(inscricoes => {
      this.dataSource = new MatTableDataSource(inscricoes)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
    })
  }

  exportar() {
    let iscricoes: InscricaoConsultaGerencial[] = this.dataSource.data
    if (iscricoes.length == 0) {
      this.unespCoreMessageService.showMessageError('Nenhum registro disponível.')
      return
    }
    let content = `"#","DATA","PROTOCOLO","CÂMPUS","CONCURSO","NOME","NOME-SOCIAL","DATA-NASCIMENTO","SEXO","CANHOTO","DEFICIENCIAS","NOME-MÃE","NOME-PAI","PROFISSÃO","ESCOLARIDADE","ESTADO-CIVIL","CPF","ESTRANGEIRO","PASSAPORTE","RNE","RG","EXPEDIÇÃO","ORGÃO","PAÍS","ESTADO","CIDADE","EMAIL","CELULAR","TELEFONE","CEP","END-RUA","END-NUMERO","END-COMPLEMENTO","END-BAIRRO","END-CIDADE","END-ESTADO"\r\n`
    iscricoes.forEach(row => {
      content += `"${row.id}","${row.data}","${row.protocolo}","${row.campus}","${row.tituloConcurso}","${row.nome}","${
        row.nomeSocial
      }","${row.dataDeNascimento}","${row.sexo}","${row.canhoto ? 'Sim' : 'Não'}","${row.deficiencia}","${
        row.nomeMae
      }","${row.nomePai}","${row.profissao}","${row.escolaridade}","${row.estadoCivil}","${row.cpf}","${
        row.estrangeiro
      }","${row.passaporte}","${row.rne}","${row.rg}","${row.expedicao}","${row.orgao}","${row.pais}","${
        row.estado
      }","${row.cidade}","${row.email}","${row.celular}","${row.telefone}","${row.cep}","${row.ruaEnd}","${
        row.numEnd
      }","${row.complementoEnd}","${row.bairroEnd}","${row.cidadeEnd}","${row.estadoEnd}"\r\n`
    })
    content = content.replace(/null|undefined/g, '')
    let binaryData = []
    binaryData.push(content)
    let downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', 'inscricoes.csv')
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csv;charset=utf-8;' }))
    document.body.appendChild(downloadLink)
    downloadLink.click()
    downloadLink.remove()
  }

  clearDate(): void {
    this.dataInscricaoFC.setValue(null)
  }
}
