/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { UsuarioExterno } from 'src/app/models/usuario-externo'
import { UsuarioExternoService } from 'src/app/services/usuario-externo.service'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'

@Component({
  selector: 'app-lista-usuario-externo',
  templateUrl: './lista-usuario-externo.component.html',
  styleUrls: ['./lista-usuario-externo.component.css'],
})
export class ListaUsuarioExternoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  colunasGrid: string[] = ['idUsuario', 'nome', 'cpf', 'email', 'identificacao', 'action']
  dataSource: MatTableDataSource<UsuarioExterno> = new MatTableDataSource()

  constructor(private usuarioExternoService: UsuarioExternoService) {}

  ngOnInit(): void {
    this.usuarioExternoService.listar().subscribe(usuariosExterno => {
      this.dataSource = new MatTableDataSource(usuariosExterno)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
    })
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
}
