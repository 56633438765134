/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { EMPTY, catchError as catchErrorRxJS, Observable, OperatorFunction } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private unespCoreMessageService: UnespCoreMessageService) {}

  catchError<T>(): OperatorFunction<T, T> {
    return catchErrorRxJS<T, Observable<T>>(err => {
      if (err.status == 404) {
        this.unespCoreMessageService.showMessageError('Atenção', 'O recurso solicitado não está disponível')
      } else {
        err.error.message
          ? this.unespCoreMessageService.showMessageError('Atenção', err.error.message)
          : this.unespCoreMessageService.showMessageError('Atenção', err.message)
      }
      return EMPTY
    })
  }
}
