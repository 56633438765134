/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { UsuarioCandidato } from 'src/app/models/usuario-candidato'
import { UsuarioCandidatoService } from 'src/app/services/usuario-candidato.service'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-lista-usuario-candidato',
  templateUrl: './lista-usuario-candidato.component.html',
  styleUrls: ['./lista-usuario-candidato.component.css'],
})
export class ListaUsuarioCandidatoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef
  totalElements: number = 0

  colunasGrid: string[] = ['id', 'email', 'action']
  dataSource: MatTableDataSource<UsuarioCandidato> = new MatTableDataSource()

  constructor(private usuarioCandidatoService: UsuarioCandidatoService) {}

  ngOnInit(): void {
    this.getData()
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
  }

  private getData() {
    this.usuarioCandidatoService
      .listar(this.input?.nativeElement.value, this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data['content'])
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
  }
}
