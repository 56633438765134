/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InscricaoPagamentoService } from 'src/app/services/inscricao-pagamento.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute } from '@angular/router'
import { InscricaoPagamento } from 'src/app/models/inscricao-pagamento'
import { PermitirAlteracaoService } from 'src/app/services/permitir-alteracao.service'
import { ArquivoSelecionado, arquivoUploadValidator } from '../../arquivo-upload/arquivo-upload.component'
import { UnespCoreAuthService } from 'src/libs/unesp-core'
@Component({
  selector: 'app-inscricao-pagamento',
  templateUrl: './inscricao-pagamento.component.html',
  styleUrls: ['./inscricao-pagamento.component.scss'],
})
export class InscricaoPagamentoComponent implements OnInit {
  isPermitirAlteracao: boolean
  isAtualizado: boolean
  id?: number
  inscricaoPagamento?: InscricaoPagamento

  anexoFormControl: FormControl<ArquivoSelecionado> = new FormControl()

  form = new FormGroup({
    pago: new FormControl('', Validators.required),
  })

  isPermiteArquivoComprovantePagamento: boolean = false

  constructor(
    private inscricaoPagamentoService: InscricaoPagamentoService,
    private permitirAlteracaoService: PermitirAlteracaoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private unespCoreAuthService: UnespCoreAuthService,
    private route: ActivatedRoute
  ) {
    this.isPermitirAlteracao = this.route.snapshot.url[0].toString() == 'permitir-alteracao'
    this.isAtualizado = false
  }

  ngOnInit(): void {
    this.isPermiteArquivoComprovantePagamento = this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA'])

    const anexo: ArquivoSelecionado = new ArquivoSelecionado()
    this.anexoFormControl.setValue(anexo)
    this.anexoFormControl.disable()
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.inscricaoPagamentoService.get(this.id).subscribe(obj => {
          this.inscricaoPagamento = obj
          this.form.get('pago')?.setValue(obj.pago ? 'true' : 'false')
          this.isAtualizado = true

          anexo.setNameAndSize(obj.depositoAnexo, obj.depositoAnexoTamanho)
          this.anexoFormControl.setValue(anexo)
          this.anexoFormControl.addValidators(arquivoUploadValidator(false))

          this.form
            .get('pago')
            ?.valueChanges.subscribe(value => (this.isAtualizado = this.inscricaoPagamento?.pago.toString() == value))

          if (this.inscricaoPagamento.pagoConcluida || this.isPermitirAlteracao) {
            this.form.disable()
          }

          if (this.isPermiteArquivoComprovantePagamento) {
            if (!this.inscricaoPagamento.pagoConcluida) {
              this.anexoFormControl.enable()
            }
          }
        })
      }
    })
  }

  gravar(): void {
    if (this.id) {
      let opcao = this.form.controls['pago'].value == 'true' ? true : false
      if (!this.inscricaoPagamento?.pagoConcluida) {
        if (this.inscricaoPagamento?.pago != opcao || this.inscricaoPagamento?.pagoData == null) {
          if (opcao) {
            this.inscricaoPagamentoService.confirmar(this.id).subscribe(inscricaoPagamento => {
              this.inscricaoPagamento = inscricaoPagamento
              this.unespCoreMessageService.showMessageSuccess(`Pagamento #${this.id} confirmado com sucesso`)
              this.isAtualizado = this.inscricaoPagamento?.pago as boolean
            })
          } else {
            this.inscricaoPagamentoService.cancelar(this.id).subscribe(inscricaoPagamento => {
              this.inscricaoPagamento = inscricaoPagamento
              this.unespCoreMessageService.showMessageSuccess(`Pagamento #${this.id} cancelado com sucesso`)
              this.isAtualizado = !(this.inscricaoPagamento?.pago as boolean)
            })
          }
        }
      }
    }
  }

  concluir(): void {
    if (this.id) {
      if (!this.inscricaoPagamento?.pagoConcluida) {
        this.inscricaoPagamentoService.concluir(this.id).subscribe(inscricaoPagamento => {
          this.inscricaoPagamento = inscricaoPagamento
          this.unespCoreMessageService.showMessageSuccess(`Pagamento #${this.id} concluído com sucesso`)
          this.form.disable()
          this.anexoFormControl.disable()
        })
      }
    }
  }

  permitirAlteracao() {
    if (this.id && this.isPermitirAlteracao && this.inscricaoPagamento) {
      this.permitirAlteracaoService.liberarRegistro('pagamento', this.id).subscribe(registro => {
        this.inscricaoPagamento!.pagoConcluida = registro.pagamento.concluido
        this.unespCoreMessageService.showMessageSuccess(`Pagamento #${this.id} revertido para não concluído`)
      })
    }
  }
}
