<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div fxFlex="0 0 auto" *ngIf="unespCoreMenuNavigationService.appDrawerEnabled || publicMenu">
      <button class="menu" mat-icon-button id="menu" (click)="unespCoreMenuNavigationService.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="logo" fxFlex="0 0 auto" fxLayoutAlign="center">
      <img *ngIf="!logoCustom" [src]="logo">
      <img *ngIf="logoCustom" [src]="logoCustom">
    </div>
    <div fxFlex="1 1 auto" class="app-title">
      <span class="app-title-full">{{ fullTitle }}</span>
      <span class="app-title-short">{{ shortTitle }}</span>
    </div>
    <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="0 0 auto" fxLayout="row" *ngIf="showCountdownTimer && (user$ | async)">
        <unesp-core-header-countdown></unesp-core-header-countdown>
      </div>
      <div fxFlex="0 0 auto" *ngIf="usernameOnHeader">
        <span class="usernameHeader">{{(user$ | async)?.identificacao}}</span>
      </div>
    </div>
    <div fxFlex="0 0 auto">
      <button class="app-login-logout" *ngIf="logoutOnHeader && (user$ | async)" mat-icon-button (click)="logout()" fxFlex="1 1 0%">
        <mat-icon>cancel</mat-icon>
      </button>
      <button class="app-login-logout" *ngIf="loginOnHeader && !(user$ | async)" mat-icon-button (click)="login()" fxFlex="1 1 0%">
        <mat-icon>login</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>