/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input } from '@angular/core'
import { InscricaoDocumento } from 'src/app/models/inscricao-documento'
import { TipoDocumento } from 'src/app/enums/tipo-documento'
import { DownloadService } from 'src/app/services/download.service'
import { ArquivoViewerDialogComponent } from '../../arquivo-viewer-dialog/arquivo-viewer-dialog.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-inscricao-documentos',
  templateUrl: './inscricao-documentos.component.html',
  styleUrls: ['./inscricao-documentos.component.scss'],
})
export class InscricaoDocumentosComponent {
  @Input() documentos: InscricaoDocumento[] = []
  id?: number

  constructor(private downloadService: DownloadService, private dialog: MatDialog) {}

  tipoDocumentoStr(tipo: string): string {
    return (TipoDocumento as any)[tipo]
  }

  visualizarDocumento(doc: InscricaoDocumento) {
    const downloadPath = `inscricaodocumento/${doc.id}`
    const titulo = `Inscrição #${doc.idInscricao}: ${this.tipoDocumentoStr(doc.tipoDocumento)}`
    this.downloadService.arquivoObjectURLPorPath(downloadPath, doc.arquivo).subscribe(url =>
      this.dialog.open(ArquivoViewerDialogComponent, {
        data: { tituloDoArquivo: titulo, urlDoArquivo: url },
        width: '80%',
        height: '90%',
      })
    )
  }
}
