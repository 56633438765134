/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UnespCoreAuthService } from '../unesp-core-auth';
import { UnespCoreRolesRoute } from '../../interfaces';

/**
 * @description
 *
 * Serviço responsável por proteger rotas baseado em roles específicas
 *
 */
@Injectable({
  providedIn: 'root'
})
export class UnespCoreAuthGuardService {
  constructor(
    private unespCoreAuthService: UnespCoreAuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean> | Promise<boolean> | boolean {

    const unespCoreRolesRoute: UnespCoreRolesRoute = {
      allowedRoles: route.data['allowedRoles']
    };

    const isAuthorized = this.unespCoreAuthService.checkAuthorizedRole(unespCoreRolesRoute);

    if (!isAuthorized) {
      this.router.navigate(['access-denied']);
    }

    return isAuthorized;

  }

}
