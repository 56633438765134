<mat-card>
  <mat-card-content>
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
      <fieldset fxFlex="100%">
        <legend>Devolução</legend>

        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Câmpus</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.concursoCampus" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concurso</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.concursoTitulo" [disabled]="true" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.candidatoCpf" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Nome</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.nome" [disabled]="true" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Valor</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.valor" name="valor" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Pago</mat-label>
              <input
                matInput
                [value]="inscricaoPagamentoDevolucao?.pago ? 'Sim' : 'Não'"
                name="pago"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Código</mat-label>
              <input
                matInput
                [value]="inscricaoPagamentoDevolucao?.depositoCodigo"
                name="depositoCodigo"
                [disabled]="true" />
            </mat-form-field>

            <app-arquivo-upload
              name="fileInput"
              [autoUpload]="true"
              [fc]="anexoFormControl"
              accept="application/pdf"
              uploadPath="{{ 'inscricao/' + inscricaoPagamentoDevolucao?.id + '/pagamento' }}"
              [usarArquivoViewer]="true"
              label="Comprovante"></app-arquivo-upload>
          </div>

          <mat-divider></mat-divider>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Data</mat-label>
              <input
                matInput
                [value]="inscricaoPagamentoDevolucao?.pagoData | date : 'dd/MM/yyyy HH:mm:ss'"
                name="pagoData"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.pagoUsuario" name="pagoUsuario" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concluído</mat-label>
              <input
                matInput
                [value]="inscricaoPagamentoDevolucao?.pagoConcluida ? 'Sim' : 'Não'"
                name="pagoConcluida"
                [disabled]="true" />
            </mat-form-field>
          </div>
        </div>
      </fieldset>

      <fieldset fxFlex="100%">
        <legend>Dados da devolução</legend>

        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Banco</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.devolucaoBanco" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Agência</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.devolucaoAgencia" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Conta</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.devolucaoConta" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Variação</mat-label>
              <input matInput [value]="inscricaoPagamentoDevolucao?.devolucaoVariacao" [disabled]="true" />
            </mat-form-field>
          </div>

          <mat-divider></mat-divider>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Data</mat-label>
              <input
                matInput
                [value]="inscricaoPagamentoDevolucao?.devolucaoData | date : 'dd/MM/yyyy HH:mm:ss'"
                name="devolucaoData"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input
                matInput
                [value]="inscricaoPagamentoDevolucao?.devolucaoUsuario"
                name="devolucaoUsuario"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concluído</mat-label>
              <mat-select
                name="devolucaoConcluida"
                formControlName="devolucaoConcluida"
                [disabled]="!inscricaoPagamentoDevolucao?.devolucaoHabilitada || !inscricaoPagamentoDevolucao?.pago">
                <mat-option value="true">Sim</mat-option>
                <mat-option value="false">Não</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <button
        mat-raised-button
        color="primary"
        (click)="gravar()"
        [disabled]="!inscricaoPagamentoDevolucao?.devolucaoHabilitada || !inscricaoPagamentoDevolucao?.pago">
        <mat-icon>edit</mat-icon> Atualizar
      </button>
      <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>close</mat-icon> Cancelar</button>
    </div>
  </mat-card-actions>
</mat-card>
