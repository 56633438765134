<mat-divider *ngIf="!primeiroGrupo"></mat-divider>
<div class="grupoComplemento">
  <h3 class="tituloGrupoComplemento">{{ grupo }}</h3>
  <div *ngFor="let complemento of complementos" [ngSwitch]="complemento.tipoComplemento">
    <app-informacao-complementar-file *ngSwitchCase="'FILE'" [formGroup]="formGroup!" [complemento]="complemento">
    </app-informacao-complementar-file>
    <app-informacao-complementar-checkbox
      *ngSwitchCase="'CHECKBOX'"
      [complemento]="complemento"
      [formGroup]="formGroup!">
    </app-informacao-complementar-checkbox>
    <app-informacao-complementar-text *ngSwitchCase="'INPUT'" [complemento]="complemento" [formGroup]="formGroup!">
    </app-informacao-complementar-text>
  </div>
</div>
