<h1>Usuário externo</h1>
<mat-card>
  <mat-card-content>
    <fieldset fxFlex="100%">
      <legend>Dados</legend>
      <form [formGroup]="form" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput formControlName="nome" name="nome" required />
        </mat-form-field>

        <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
          <mat-form-field appearance="outline">
            <mat-label>CPF</mat-label>
            <input
              matInput
              mask="000.000.000-00"
              [dropSpecialCharacters]="false"
              formControlName="cpf"
              name="cpf"
              required />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" name="email" type="email" [email]="true" required />
          </mat-form-field>
        </div>
      </form>
    </fieldset>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <button mat-raised-button color="primary" (click)="gravar()"><mat-icon>save</mat-icon> Salvar</button>
      <button mat-stroked-button color="warn" routerLink="/usuario-externo"><mat-icon>close</mat-icon> Cancelar</button>
    </div>
  </mat-card-actions>
</mat-card>
