/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: Storage
  private prefix: string = 'sinsc_'

  constructor() {
    this.storage = window.localStorage
  }

  set(key: string, value: any): boolean {
    if (this.storage) {
      this.storage.setItem(this.prefix + key, JSON.stringify(value))
      return true
    }
    return false
  }

  get(key: string): any {
    if (this.storage) {
      const data = this.storage.getItem(this.prefix + key)
      return data ? JSON.parse(data) : null
    }
    return null
  }

  remove(key: string): boolean {
    if (this.storage) {
      this.storage.removeItem(this.prefix + key)
      return true
    }
    return false
  }
}
