/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { TipoRecurso } from 'src/app/models/tipo-recurso'
import { TipoRecursoService } from 'src/app/services/tipo-recurso.service'
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { UnespCoreMessageService } from 'src/libs/unesp-core'

@Component({
  selector: 'app-tipo-recurso-listar',
  templateUrl: './tipo-recurso-listar.component.html',
  styleUrls: ['./tipo-recurso-listar.component.css'],
})
export class TipoRecursoListarComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef
  totalElements: number = 0
  datepipe: DatePipe = new DatePipe('pt-BR')

  colunasGrid: string[] = [
    'id',
    'tipoAndamento',
    'tipoRecurso',
    'tipoConcurso',
    'prazo',
    'categoria',
    'action',
    'actionExcluir',
  ]
  dataSource: MatTableDataSource<TipoRecurso> = new MatTableDataSource()
  id?: number
  filtroTipoRecurso: string = ''
  search = ''

  constructor(
    private tipoRecursoService: TipoRecursoService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private unespCoreMessageService: UnespCoreMessageService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.getData()
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()
    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
  }

  private getData() {
    this.tipoRecursoService
      .listar(this.input?.nativeElement.value, this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data['content'])
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
  }

  excluirTipoRecurso(recurso: TipoRecurso): void {
    let index = this.dataSource.data.indexOf(recurso)
    const confirmDialog = new ConfirmDialogModel('Atenção', `Confirma a exclusão do Tipo de Recurso #${recurso.id}`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.tipoRecursoService.excluir(recurso.id as number).subscribe(recurso => {
          let tmp: TipoRecurso[] = this.dataSource.data
          tmp.splice(index, 1)
          this.dataSource.data = tmp
          this.unespCoreMessageService.showMessageSuccess(`Tipo de Recurso excluído com sucesso`)
        })
      }
    })
  }
}
