<h3 mat-dialog-title>Adicionar usuário ao perfil</h3>

<mat-form-field appearance="outline" fxFill>
  <mat-label>Identificação</mat-label>
  <input matInput [formControl]="identificacaoFC" name="identificacaoFC" type="text" required />
</mat-form-field>

<mat-dialog-actions>
  <div fxLayoutAlign="space-between center" fxFill>
    <button mat-raised-button color="primary" (click)="salvar()"><mat-icon>add</mat-icon> Adicionar</button>
    <button mat-dialog-close mat-stroked-button color="warn" routerLink="/perfil">
      <mat-icon>close</mat-icon> Cancelar
    </button>
  </div>
</mat-dialog-actions>
