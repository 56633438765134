<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign.gt-md="space-between center">
    <h1>Classificação inscrição</h1>
    <div>
      <button mat-raised-button color="primary" (click)="exportar()"><mat-icon>save_alt</mat-icon> Exportar</button>
    </div>
  </div>

  <mat-form-field appearance="outline" display="inline-block">
    <mat-label>Filtrar</mat-label>
    <mat-select [(value)]="filtro" (valueChange)="selecionar()">
      <mat-option value="TODOS">TODOS</mat-option>
      <mat-option value="NAO_AVALIADO">Não avaliado</mat-option>
      <mat-option value="NAO_HABILITADO">Não habilitado</mat-option>
      <mat-option value="HABILITADO">Habilitado</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
      </ng-container>
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let row">{{ row.cpf }}</td>
      </ng-container>
      <ng-container matColumnDef="situacao">
        <th mat-header-cell *matHeaderCellDef>Inscrição</th>
        <td mat-cell *matCellDef="let row">{{ row.situacao }}</td>
      </ng-container>
      <ng-container matColumnDef="pago">
        <th mat-header-cell *matHeaderCellDef>Pago</th>
        <td mat-cell *matCellDef="let row">{{ row.pago ? 'Sim' : 'Não' }}</td>
      </ng-container>
      <ng-container matColumnDef="classificacao">
        <th mat-header-cell *matHeaderCellDef>Classificação</th>
        <td mat-cell *matCellDef="let row">
          <mat-select [(value)]="row.classificacao" [disabled]="row.concluida">
            <mat-option *ngFor="let row of optClassificacao | keyvalue" [value]="row.key">
              {{ row.value }}
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <ng-container matColumnDef="posicao">
        <th mat-header-cell *matHeaderCellDef>Posição</th>
        <td mat-cell *matCellDef="let row">
          <div>
            <input
              matInput
              class="bordered"
              [(ngModel)]="row.posicao"
              [required]="row.classificacao === 'HABILITADO'"
              [disabled]="row.classificacao !== 'HABILITADO' || row.concluida"
              size="3"
              maxlength="3"
              mask="0*" />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="autor">
        <th mat-header-cell *matHeaderCellDef>Autor</th>
        <td mat-cell *matCellDef="let row">{{ row.autor }}</td>
      </ng-container>
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let row">{{ row.data | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="min-width: 140px"></th>
        <td mat-cell *matCellDef="let row">
          <div fxLayoutAlign="space-between center">
            <button mat-icon-button color="primary" *ngIf="!row.concluida" title="Salvar" (click)="salvar(row)">
              <mat-icon>save</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              *ngIf="row.data && !row.concluida"
              title="Concluir"
              (click)="concluir(row)">
              <mat-icon>check</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
