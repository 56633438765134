/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { StatusDocumentacao } from '../models/status-documentacao'
import { StatusDocumentacaoPendencia } from '../models/status-documentacao-pendencia'
import { DownloadService } from './download.service'

@Injectable({
  providedIn: 'root',
})
export class StatusDocumentacaoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private downloadService: DownloadService,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  listar(idConcurso: number, search = '', page = 0, size = 10, filtroPago = 'TODOS'): Observable<any> {
    let url = `${this.baseUrl}/status-documentacao/${idConcurso}`
    return this.http
      .get<StatusDocumentacao>(url, {
        params: new HttpParams()
          .set('idConcurso', idConcurso)
          .set('search', search)
          .set('page', page.toString())
          .set('size', size.toString())
          .set('filtroPago', filtroPago),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  gerarArquivoPdf(idConcurso: number | null = null, search = '') {
    let headers = new HttpHeaders()
    headers = headers.set('Accept', 'text/pdf')
    if (idConcurso == null) {
      this.http
        .get<any>(`${this.baseUrl}/listagem-status-documentacao/gerar-pdf`, {
          headers: headers,
          responseType: 'blob' as 'json',
          observe: 'response',
          params: new HttpParams().set('search', search),
        })
        .pipe(identity, this.errorHandlerService.catchError())
        .subscribe(res => {
          let url = window.URL.createObjectURL(res.body)
          let downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.download = `inscricoes.pdf`
          downloadLink.click()
          window.URL.revokeObjectURL(url)
          downloadLink.remove()
        })
    } else {
      this.http
        .get<any>(`${this.baseUrl}/listagem-status-documentacao/${idConcurso}/gerar-pdf`, {
          headers: headers,
          responseType: 'blob' as 'json',
          observe: 'response',
          params: new HttpParams().set('search', search),
        })
        .pipe(identity, this.errorHandlerService.catchError())
        .subscribe(res => {
          let url = window.URL.createObjectURL(res.body)
          let downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.download = `inscricoes-${idConcurso}.pdf`
          downloadLink.click()
          window.URL.revokeObjectURL(url)
          downloadLink.remove()
        })
    }
  }

  getStatusDocumentacao(id: number): Observable<StatusDocumentacaoPendencia> {
    return this.http
      .get<StatusDocumentacaoPendencia>(`${this.baseUrl}/status-documentacao/${id}/situacao-documentacao`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
