/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Andamento } from 'src/app/models/andamento'
import { PermitirAlteracaoService } from 'src/app/services/permitir-alteracao.service'

@Component({
  selector: 'app-permitir-alterar-andamento',
  templateUrl: './permitir-alterar-andamento.component.html',
  styleUrls: ['./permitir-alterar-andamento.component.css'],
})
export class PermitirAlterarAndamentoComponent implements OnInit {
  idConcurso?: number
  id?: number
  andamento?: Andamento

  constructor(private permitirAlteracaoService: PermitirAlteracaoService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      if (this.id)
        this.permitirAlteracaoService.getAndamento(this.id!).subscribe(andamento => (this.andamento = andamento))
    })
  }
}
