<mat-card fxLayout="column" fxLayoutGap="20px">
  <mat-card-content>
    <fieldset fxFlex="100%">
      <legend>Documentos</legend>
      <div *ngFor="let doc of documentos; let first = first">
        <mat-divider *ngIf="!first"></mat-divider>
        <h3 class="nomeDocumento">{{ tipoDocumentoStr(doc.tipoDocumento) }}</h3>
        <div>
          <mat-form-field appearance="outline" fxFlex>
            <input matInput readonly [value]="doc.arquivo" [disabled]="true" />
            <div class="fileOpener" (click)="visualizarDocumento(doc)"></div>
            <mat-icon matSuffix (click)="visualizarDocumento(doc)">file_open</mat-icon>
            <mat-hint>Tamanho do arquivo: {{ doc.tamanho / (1024 * 1024) | number : '1.2-2' }} MB</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </mat-card-content>
</mat-card>
