/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpErrorResponse } from '@angular/common/http'
import { Component, ViewChild, Input, OnInit, ElementRef } from '@angular/core'
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ArquivoUploadStatus } from 'src/app/models/arquivo-upload-status.model'
import { ArquivoUploadService } from 'src/app/services/arquivo-upload.service'
import { DownloadService } from 'src/app/services/download.service'
import { ArquivoViewerDialogComponent } from '../arquivo-viewer-dialog/arquivo-viewer-dialog.component'

export enum ArquivoStatus {
  VAZIO,
  ENVIANDO,
  SELECIONADO,
  ENVIADO,
  SALVO,
  ERRO,
}

export class ArquivoSelecionado {
  name: string = ''
  uuid?: string
  size: number = 0
  status: ArquivoStatus = ArquivoStatus.VAZIO
  file: File | null = null

  setNameAndSize(name: string, size: number) {
    if (!name) return
    this.name = name.toLowerCase()
    this.uuid = name.split('.', 2)[0]
    this.size = size
    this.file = null
    this.status = ArquivoStatus.SALVO
  }

  setFile(file: File | null) {
    if (file == null) return
    this.file = file
    this.size = file.size
    this.name = file.name
    this.status = ArquivoStatus.SELECIONADO
  }

  toString(): string {
    return this.name
  }
}

export function arquivoUploadValidator(required: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const arquivo = control.value as ArquivoSelecionado

    if (!arquivo) return null

    if (required && arquivo.status == ArquivoStatus.VAZIO) {
      return { required: true }
    }

    switch (arquivo.status) {
      case ArquivoStatus.ERRO:
        return { upload_error: true }
      case ArquivoStatus.VAZIO:
      case ArquivoStatus.SALVO:
      case ArquivoStatus.ENVIADO:
        return null
      default:
        return { uploading: true }
    }
  }
}

@Component({
  selector: 'app-arquivo-upload',
  templateUrl: './arquivo-upload.component.html',
  styleUrls: ['./arquivo-upload.component.scss'],
})
export class ArquivoUploadComponent implements OnInit {
  @Input() fc: FormControl<ArquivoSelecionado> = new FormControl()
  @Input() label: string = 'Arquivo'
  @Input() tip: string = 'Selecione um arquivo para Upload'
  @Input() accept: string = '*'
  @Input() autoUpload: boolean = false
  @Input() usarArquivoViewer: boolean = false
  @Input() uploadPath?: string
  @Input() appendData?: Map<string, any>
  @ViewChild('fileInput') fileInput: ElementRef | undefined

  disabled: boolean = false
  required: boolean = false

  constructor(
    private arquivoUploadService: ArquivoUploadService,
    private downloadService: DownloadService,
    private unespCoreMessageService: UnespCoreMessageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.fc.disabled) this.disabled = true
    this.fc.registerOnDisabledChange((isDisabled: boolean) => (this.disabled = isDisabled))

    const validator = this.fc.validator ? this.fc.validator({} as AbstractControl) : null
    if (validator && validator['required']) this.required = true
  }

  upload(): Promise<ArquivoUploadStatus> | null {
    if (
      this.fc.value.file &&
      this.fc.value.status == ArquivoStatus.SELECIONADO &&
      typeof this.uploadPath != 'undefined'
    )
      return this.upload_async()

    return null
  }
  /**
   * Carrega arquivo em backend
   */
  private async upload_async(): Promise<ArquivoUploadStatus> {
    let formData = new FormData()

    if (this.appendData) for (let [key, value] of this.appendData) formData.append(key, value)

    formData.append('arquivo', this.fc.value.file!)
    this.fc.value.status = ArquivoStatus.ENVIANDO

    var promise = new Promise<ArquivoUploadStatus>((resolve, reject) => {
      this.arquivoUploadService.arquivoUpload(this.uploadPath!, formData).subscribe({
        next: (r: ArquivoUploadStatus) => {
          Object.assign(this.fc.value, {
            status: ArquivoStatus.ENVIADO,
            name: r.arquivoNome,
            uuid: r.uuid!,
            size: r.size,
          })
          if (r.uploadPath) this.uploadPath = r.uploadPath
          this.fc.setValue(this.fc.value)
          resolve(r)
        },
        error: err => {
          this.fc.value.status = ArquivoStatus.ERRO
          this.fc.setValue(this.fc.value)
          if (err instanceof HttpErrorResponse) {
            if (err.status == 413) {
              let erro: { mensagemUsuario: string; mensagemDesenvolvedor: string } = err.error
              this.unespCoreMessageService.showMessageError(erro.mensagemUsuario)
            } else {
              this.unespCoreMessageService.showMessageError(err.message)
            }
          } else {
            console.error(err)
            reject()
          }
        },
      })
    })

    const v = await promise
    return v
  }

  selecionaArquivo(e: Event) {
    const target = e.target as HTMLInputElement
    const files = target.files as FileList

    const file = files.item(0)

    if (file == null) return

    if (this.accept != '*' && !this.accept.split(',').includes(file.type)) {
      this.unespCoreMessageService.showMessageError(`Formato de arquivo invalido, use: ${this.accept}`)
      return
    }

    this.fc.value.setFile(file)
    this.fc.setValue(this.fc.value)
    this.fc.markAsDirty()

    if (this.autoUpload && this.fc.value.status == ArquivoStatus.SELECIONADO && typeof this.uploadPath != 'undefined')
      this.upload_async()
  }

  fileSelectorClick() {
    if (this.fc.value.status == ArquivoStatus.VAZIO) {
      this.fileInput?.nativeElement.click()
    } else if (this.fc.value.status == ArquivoStatus.ENVIADO || this.fc.value.status == ArquivoStatus.SALVO) {
      if (!this.uploadPath) return

      if (this.usarArquivoViewer) this.abrirArquivoViewer()
      else this.downloadService.arquivoDownload(this.uploadPath, this.fileName())
    }
  }

  download() {
    if (this.fc.value.status == ArquivoStatus.ENVIADO || this.fc.value.status == ArquivoStatus.SALVO) {
      if (!this.uploadPath) return
      if (this.usarArquivoViewer) this.abrirArquivoViewer()
      else this.downloadService.arquivoDownload(this.uploadPath, this.fileName())
    }
  }

  private fileName(): string {
    if (this.fc.value.file == null && this.fc.value.uuid) return this.fc.value.name
    else return this.fc.value.uuid + '.' + this.fc.value.name.split('.').pop()
  }

  private abrirArquivoViewer() {
    this.downloadService.arquivoObjectURLPorPath(this.uploadPath!, this.fileName()).subscribe(url =>
      this.dialog.open(ArquivoViewerDialogComponent, {
        data: { tituloDoArquivo: this.label, urlDoArquivo: url },
        width: '80%',
        height: '90%',
      })
    )
  }
}
