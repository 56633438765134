<h1>Tipo de Recurso</h1>
<mat-card>
  <mat-card-content>
    <form fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
      <fieldset fxFlex="100%">
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column" fxLayoutGap="5px">
            <mat-form-field appearance="outline">
              <mat-label>Andamento</mat-label>
              <mat-select name="tipo" [formControl]="tipoAndamentoFC">
                <mat-option *ngFor="let type of tipoAndamento | keyvalue" [value]="type.key">
                  {{ type.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Recurso</mat-label>
              <input matInput type="text" [formControl]="tipoRecursoFC" />
            </mat-form-field>
          </div>
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline" display="inline-block">
              <mat-label>Categoria</mat-label>
              <mat-select [formControl]="categoriaFC" (value)="(recurso?.categoria)">
                <mat-option value="RH">RH</mat-option>
                <mat-option value="Diretor">Diretor</mat-option>
                <mat-option value="Departamento">Departamento</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Prazo</mat-label>
              <input matInput [formControl]="prazoFC" (value)="(recurso?.prazo)" mask="099" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill></div>
          <div fxLayout="column" fxLayoutGap="5px">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Tipo de Concurso</mat-label>
              <mat-chip-grid #tipoConcursoChipList>
                <mat-chip-option
                  *ngIf="showTodosTiposConcursosMC && tiposDeConcursosSelecionados.length === 0"
                  (removed)="removeChip(todosTiposConcursos)">
                  {{ todosTiposConcursos.description }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>

                <mat-chip-option
                  *ngFor="let selecionado of tiposDeConcursosSelecionados"
                  (removed)="removeChip(selecionado)">
                  {{ selecionado.description }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>

                <input
                  #tipoConcursoInput
                  placeholder="Tipo de Concurso"
                  [matChipInputFor]="tipoConcursoChipList"
                  [matChipInputAddOnBlur]="true"
                  (focus)="focusChip()"
                  (matChipInputTokenEnd)="blurChip($event)"
                  [formControl]="tipoConcursoFC"
                  [matAutocomplete]="autoTipoConcurso" />
              </mat-chip-grid>

              <mat-autocomplete autoActiveFirstOption #autoTipoConcurso="matAutocomplete">
                <mat-option *ngFor="let opt of tiposConcursosFiltrados | async" [value]="opt">
                  {{ opt.description }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayoutAlign="space-between center">
      <button mat-raised-button color="primary" (click)="incluir()">
        <mat-icon>check_circle_outline</mat-icon> Incluir
      </button>
      <button mat-stroked-button color="warn" routerLink="/tipo-recurso"><mat-icon>close</mat-icon> Cancelar</button>
    </div>
  </mat-card-actions>
</mat-card>
