/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

export const environment = {
  localStoragePrefix: 'sinsc_',
  production: false,
  clientId: 'ffc.unesp.intranet',
  name: 'Coordenadoria de Gestão de Pessoas',
  baseUrlApi: 'https://gestao.cluster01.nuvem.unicamp.br/sinsc-api',
  baseUrlRedirect: 'https://gestao.cluster01.nuvem.unicamp.br/auth/',
  oauthAuthorize: 'https://centralhomolog.unesp.br/oauth/authorize',
  centralRolePrefix: 'ROLE_FFC.UNESP.INTRANET_',
  refreshTokenExpirationInSeconds: 3600,
  refreshTokenExpirationActivityNotificationsTime: [1800, 600, 300, 180, 120],
  refreshTokenExpirationLastWarningTime: 61,
  roles: {
    GERENTE: {
      name: 'ROLE_FFC.UNESP.INTRANET_GERENTE',
      desc: 'Gerente',
    },
    MULTIUNIDADES: {
      name: 'ROLE_FFC.UNESP.INTRANET_MULTIUNIDADES',
      desc: 'MultiUnidades',
    },
    RH: {
      name: 'ROLE_FFC.UNESP.INTRANET_RH',
      desc: 'RH',
    },
    DTA: {
      name: 'ROLE_FFC.UNESP.INTRANET_DTA',
      desc: 'DTA',
    },
    CCPT: {
      name: 'ROLE_FFC.UNESP.INTRANET_CCPT',
      desc: 'CCPT',
    },
    CACP: {
      name: 'ROLE_FFC.UNESP.INTRANET_CACP',
      desc: 'CACP',
    },
    FINANCAS: {
      name: 'ROLE_FFC.UNESP.INTRANET_FINANCAS',
      desc: 'Finanças',
    },
    COMUNICACOES: {
      name: 'ROLE_FFC.UNESP.INTRANET_COMUNICACOES',
      desc: 'Comunicações',
    },
    DIRETOR: {
      name: 'ROLE_FFC.UNESP.INTRANET_DIRETOR',
      desc: 'Diretor',
    },
    DEPARTAMENTO: {
      name: 'ROLE_FFC.UNESP.INTRANET_DEPARTAMENTO',
      desc: 'Departamento',
    },
    'USUARIO-EXTERNO': {
      name: 'ROLE_FFC.UNESP.INTRANET_USUARIO-EXTERNO',
      desc: 'Usuário externo',
    },
  },
  servicedesk: 'https://servicedesk.homolog.unesp.br/portfolio/runesp/sistema-de-inscricao-em-concurso',
  centralAcessos: 'https://centralhomolog.unesp.br/central/',
}
