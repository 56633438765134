<div fxLayoutAlign.gt-md="space-between center">
  <h1>Inscrição #{{ id }}</h1>

  <div fxLayout="row" fxLayoutAlign="end start">
    <button mat-raised-button color="primary" (click)="voltar()"><mat-icon>arrow_back</mat-icon> Voltar</button>
  </div>
</div>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Ficha de Inscrição">
    <mat-card>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="end center" *ngIf="showModoEdicao()">
          <mat-slide-toggle color="warn" [disabled]="!inscricaoEditavel" [checked]="edicaoFicha" (change)="modoEdicao()"
            >Modo edição</mat-slide-toggle
          >
        </div>

        <form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
          <fieldset fxFlex="100%">
            <legend>Dados do inscrição</legend>

            <div fxLayout="column" fxLayoutGap="5px">
              <mat-form-field appearance="outline">
                <mat-label>Concurso</mat-label>
                <input matInput formControlName="tituloConcurso" name="tituloConcurso" [readonly]="true" />
              </mat-form-field>

              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Data</mat-label>
                  <input
                    matInput
                    formControlName="data"
                    name="data"
                    value="{{ form.controls['data'].value | date : 'dd/MM/yyyy HH:mm:ss' }}"
                    [readonly]="true" />
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="!isDepartamento">
                  <mat-label>Protocolo</mat-label>
                  <input matInput formControlName="protocolo" name="protocolo" [readonly]="true" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Pagamento</mat-label>
                  <input matInput formControlName="pago" name="pago" [readonly]="true" />
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset fxFlex="100%">
            <legend>Dados do candidato</legend>

            <div fxLayout="column" fxLayoutGap="5px">
              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="nome" name="nome" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Nome social</mat-label>
                  <input matInput formControlName="nomeSocial" name="nomeSocial" />
                </mat-form-field>
              </div>

              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Data de nascimento</mat-label>
                  <input
                    matInput
                    readonly
                    [matDatepicker]="pickerDataNascimento"
                    formControlName="dataNascimento"
                    name="dataNascimento" />
                  <mat-datepicker-toggle matSuffix [for]="pickerDataNascimento"></mat-datepicker-toggle>
                  <mat-datepicker #pickerDataNascimento></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Sexo</mat-label>
                  <mat-select formControlName="sexo" name="sexo">
                    <mat-option *ngFor="let type of sexo | keyvalue" [value]="type.key">
                      {{ type.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Estado Civil</mat-label>
                  <mat-select formControlName="estadoCivil" name="estadoCivil">
                    <mat-option *ngFor="let type of estadoCivil | keyvalue" [value]="type.key">
                      {{ type.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Nome da mãe</mat-label>
                  <input matInput formControlName="nomeMae" name="nomeMae" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Nome do pai</mat-label>
                  <input matInput formControlName="nomePai" name="nomePai" />
                </mat-form-field>
              </div>

              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Profissão</mat-label>
                  <input matInput formControlName="profissao" name="profissao" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Grau de escolaridade</mat-label>
                  <mat-select formControlName="escolaridade" name="escolaridade">
                    <mat-option *ngFor="let type of grauEscolaridade | keyvalue" [value]="type.key">{{
                      type.value
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Estrangeiro</mat-label>
                  <mat-select formControlName="estrangeiro" name="estrangeiro" (selectionChange)="validaEstrangeiro()">
                    <mat-option *ngFor="let type of estrangeiro | keyvalue" [value]="type.key">
                      {{ type.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>CPF</mat-label>
                  <input matInput formControlName="cpf" name="cpf" [readonly]="true" mask="000.000.000-00" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Passaporte</mat-label>
                  <input matInput formControlName="passaporte" name="passaporte" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>RNE</mat-label>
                  <input matInput formControlName="rne" name="rne" mask="AAAAAAA-A" />
                </mat-form-field>
              </div>

              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>RG</mat-label>
                  <input
                    class="form-control"
                    maxlength="13"
                    type="text"
                    matInput
                    formControlName="rg"
                    name="rg"
                    mask="A{13}" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>UF</mat-label>
                  <mat-select formControlName="uf" name="uf">
                    <mat-option *ngFor="let type of estado | keyvalue" [value]="type.key">
                      {{ type.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Data de expedição</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerExpedicao"
                    placeholder="dd/mm/aaaa"
                    class="datepicker form-control"
                    type="text"
                    formControlName="dataExpedicao"
                    name="dataExpedicao" />
                  <mat-datepicker-toggle matSuffix [for]="pickerExpedicao"></mat-datepicker-toggle>
                  <mat-datepicker #pickerExpedicao></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Orgão</mat-label>
                  <mat-select formControlName="orgaoExpedicao" name="orgaoExpedicao">
                    <mat-option *ngFor="let type of orgaoExpedicaoRg | keyvalue" [value]="type.key">
                      {{ type.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset fxFlex="100%">
            <legend>Local do nascimento</legend>

            <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
              <mat-form-field appearance="outline">
                <mat-label>País</mat-label>
                <input matInput formControlName="pais" name="pais" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <input matInput formControlName="estado" name="estado" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Cidade</mat-label>
                <input matInput formControlName="cidade" name="cidade" />
              </mat-form-field>
            </div>
          </fieldset>

          <fieldset fxFlex="100%">
            <legend>Informações de contato</legend>

            <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
              <mat-form-field appearance="outline">
                <mat-label>Celular</mat-label>
                <input
                  matInput
                  mask="(00) 00000-0000"
                  [dropSpecialCharacters]="false"
                  formControlName="celular"
                  name="celular"
                  type="text"
                  maxlength="16" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Telefone</mat-label>
                <input
                  matInput
                  mask="(00) 0000-0000"
                  [dropSpecialCharacters]="false"
                  formControlName="telefone"
                  name="telefone"
                  type="text"
                  maxlength="14" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput formControlName="email" name="email" />
              </mat-form-field>
            </div>
          </fieldset>

          <fieldset fxFlex="100%">
            <mat-form-field appearance="outline">
              <mat-label>Endereço no Brasil?</mat-label>
              <mat-select
                (selectionChange)="alteraTipoEndereco()"
                formControlName="enderecoNacional"
                name="enderecoNacional"
                required>
                <mat-option value="false">Não</mat-option>
                <mat-option value="true">Sim</mat-option>
              </mat-select>
            </mat-form-field>
            <legend>Endereço</legend>

            <div fxLayout="column" fxLayoutGap="5px">
              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <ng-container
                  *ngIf="
                    form.controls.enderecoNacional.value === 'true';
                    then contextoNacional;
                    else contextoInternacional
                  ">
                </ng-container>
                <ng-template #contextoNacional>
                  <mat-form-field appearance="outline">
                    <mat-label>CEP</mat-label>
                    <input
                      matInput
                      formControlName="enderecoCep"
                      name="enderecoCep"
                      (blur)="validaCEP(enderecoNacional)"
                      mask="00.000-000"
                      [dropSpecialCharacters]="false" />
                  </mat-form-field>
                </ng-template>
                <ng-template #contextoInternacional>
                  <mat-form-field appearance="outline">
                    <mat-label>CEP</mat-label>
                    <input
                      matInput
                      formControlName="enderecoCep"
                      name="enderecoCep"
                      (blur)="validaCEP(enderecoNacional)" />
                  </mat-form-field>
                </ng-template>
              </div>
              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Logradouro</mat-label>
                  <input matInput formControlName="enderecoLogradouro" name="enderecoLogradouro" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Número</mat-label>
                  <input matInput formControlName="enderecoNumero" name="enderecoNumero" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Complemento</mat-label>
                  <input matInput formControlName="enderecoComplemento" name="enderecoComplemento" />
                </mat-form-field>
              </div>

              <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
                <mat-form-field appearance="outline">
                  <mat-label>Bairro</mat-label>
                  <input matInput formControlName="enderecoBairro" name="enderecoBairro" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Cidade</mat-label>
                  <input matInput formControlName="enderecoCidade" name="enderecoCidade" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Estado</mat-label>
                  <input matInput formControlName="enderecoEstado" name="enderecoEstado" />
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset fxFlex="100%">
            <legend>Outros</legend>

            <mat-form-field appearance="outline">
              <mat-label>Canhoto</mat-label>
              <mat-select formControlName="canhoto" name="canhoto">
                <mat-option value="false">Não</mat-option>
                <mat-option value="true">Sim</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
              <mat-label
                >Se autodeclara pessoa preta, parda ou indígena de acordo com a Resolução UNESP nº 86 de
                25/08/2023?</mat-label
              >
              <mat-select formControlName="autodeclaraRaca" name="autodeclaraRaca">
                <mat-option value="false">Não</mat-option>
                <mat-option value="true">Sim</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
              <mat-label
                >Deseja utilizar o sistema de pontuação diferenciada nas notas finais em todas as provas escritas,
                aplicada à pessoa preta, parda ou indígena?</mat-label
              >
              <mat-select formControlName="pontuacaoDiferenciada" name="pontuacaoDiferenciada">
                <mat-option value="false">Não</mat-option>
                <mat-option value="true">Sim</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
              <mat-label>Me autodeclaro pessoa: </mat-label>
              <mat-select formControlName="autodeclaracao" name="autodeclaracao">
                <mat-option *ngFor="let type of autodeclaracao | keyvalue" [value]="type.key">{{
                  type.value
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
              <mat-label>Confirmação de veracidade</mat-label>
              <mat-select formControlName="declaracaoVeracidade" name="declaracaoVeracidade">
                <mat-option value="false">Não</mat-option>
                <mat-option value="true">Sim</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Deficiências</mat-label>
              <mat-select formControlName="deficiencia" name="deficiencia" multiple="true">
                <mat-option *ngFor="let type of deficiencia | keyvalue" [value]="type.key">{{ type.value }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Recursos especiais necessários para a realização das provas</mat-label>
              <textarea
                matInput
                formControlName="deficienciaNecessidadesProva"
                name="deficienciaNecessidadesProva"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"></textarea>
            </mat-form-field>

            <!--<mat-form-field appearance="outline">
              <mat-label>Laudo médico</mat-label>
              <input matInput [value]="inscricaoFicha?.deficienciaLaudoMedico" name="deficienciaLaudoMedico" [disabled]="true"/>
            </mat-form-field>-->

            <app-arquivo-upload
              label="Laudo médico"
              uploadPath="{{ 'inscricao/' + inscricaoFicha?.id + '/laudomedico' }}"
              accept="application/pdf"
              [fc]="laudoMedicoFc"
              tip="Nenhum arquivo enviado."
              [usarArquivoViewer]="true"></app-arquivo-upload>
          </fieldset>

          <fieldset fxFlex="100%" *ngIf="temas.length > 0">
            <legend>Pontos</legend>

            <mat-form-field appearance="outline">
              <mat-label>Pontos</mat-label>
              <mat-select formControlName="temas" name="temas" multiple="true">
                <mat-option *ngFor="let value of temas" [value]="value">{{ value }}</mat-option>
              </mat-select>
            </mat-form-field>
          </fieldset>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <div
          fxLayoutAlign.gt-md="space-between center"
          fxLayoutAlign.lt-md="center stretch"
          fxLayout.lt-md="column"
          fxLayoutGap="20px">
          <button mat-raised-button color="primary" (click)="gravar()" [disabled]="!edicaoFicha">
            <mat-icon>edit</mat-icon> Atualizar
          </button>
          <button mat-raised-button color="primary" (click)="imprimir()"><mat-icon>print</mat-icon> Imprimir</button>
          <button mat-stroked-button color="warn" (click)="voltar()"><mat-icon>close</mat-icon> Cancelar</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </mat-tab>

  <mat-tab *ngIf="mapGruposComplementos.size > 0 && showTabAnexos()" label="Complementos">
    <mat-card>
      <mat-card-content fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="end center">
          <mat-slide-toggle
            color="warn"
            [disabled]="!inscricaoEditavel && !isPeriodoSegundaFase"
            [checked]="edicaoComplementos"
            (change)="modoEditarComplementos()"
            >Modo edição</mat-slide-toggle
          >
        </div>
        <span class="complementos-status" [ngClass]="complementosInscricaoStatus">{{
          complementosInscricaoStatusMsg
        }}</span>
        <span *ngIf="temSegundaFase" class="complementos-status" [ngClass]="complementosSegundaFaseStatus">{{
          complementosSegundaFaseStatusMsg
        }}</span>
        <fieldset fxFlex="100%">
          <legend>Informações Complementares</legend>
          <app-informacao-complementar-grupo
            *ngFor="let g of mapGruposComplementos | keyvalue : asIsOrder; let first = first"
            [formGroup]="formComplementar"
            [grupo]="g.key"
            [complementos]="g.value"
            [primeiroGrupo]="first">
          </app-informacao-complementar-grupo>
        </fieldset>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab *ngIf="showTabReducao()" label="Redução">
    <ng-template matTabContent>
      <app-inscricao-reducao></app-inscricao-reducao>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="showTabPagamento()" label="Pagamento">
    <ng-template matTabContent>
      <app-inscricao-pagamento></app-inscricao-pagamento>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="showTabDevolucao()" label="Devolução">
    <ng-template matTabContent>
      <app-inscricao-pagamento-devolucao></app-inscricao-pagamento-devolucao>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="showTabSituacao()" label="Situação">
    <ng-template matTabContent>
      <app-inscricao-situacao></app-inscricao-situacao>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="documentos.length > 0 && showTabDocumentos()" label="Documentos">
    <ng-template matTabContent>
      <app-inscricao-documentos [documentos]="documentos"></app-inscricao-documentos>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="anexos.length > 0 && showTabAnexos()" label="Anexos">
    <ng-template matTabContent>
      <app-inscricao-anexos [anexos]="anexos"></app-inscricao-anexos>
    </ng-template>
  </mat-tab>
</mat-tab-group>
