/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { MatPaginatorIntl } from '@angular/material/paginator'

const RangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 de ${length}`
  }

  length = Math.max(length, 0)

  const startIndex = page * pageSize

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize

  return `${startIndex + 1} - ${endIndex} de ${length}`
}

export function PtBrMatPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl()

  paginatorIntl.itemsPerPageLabel = 'Itens por página:'
  paginatorIntl.nextPageLabel = 'Página seguinte'
  paginatorIntl.previousPageLabel = 'Página anterior'
  paginatorIntl.firstPageLabel = 'Primeira página'
  paginatorIntl.lastPageLabel = 'Primeira página'
  paginatorIntl.getRangeLabel = RangeLabel

  return paginatorIntl
}
