/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { RecursoService } from 'src/app/services/recurso.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute } from '@angular/router'
import { Recurso } from 'src/app/models/recurso'
import { PermitirAlteracaoService } from 'src/app/services/permitir-alteracao.service'
import { DatePipe } from '@angular/common'
import {
  ArquivoSelecionado,
  ArquivoStatus,
  ArquivoUploadComponent,
  arquivoUploadValidator,
} from '../../arquivo-upload/arquivo-upload.component'
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-recurso-analise',
  templateUrl: './recurso-analise.component.html',
  styleUrls: ['./recurso-analise.component.scss'],
})
export class RecursoAnaliseComponent implements OnInit {
  isPermitirAlteracao: boolean
  id?: number
  recurso?: Recurso
  salvarBtn: boolean = false
  parecerFC: FormControl = new FormControl('')
  datepipe: DatePipe = new DatePipe('pt-BR')

  anexoFormControl: FormControl<ArquivoSelecionado> = new FormControl()

  @ViewChild('upload') upload: ArquivoUploadComponent | undefined
  parecerUploadPath: string = 'parecer/'
  parecerUploadFc: FormControl<ArquivoSelecionado>

  constructor(
    private recursoService: RecursoService,
    private permitirAlteracaoService: PermitirAlteracaoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.isPermitirAlteracao = this.route.snapshot.url[0].toString() == 'permitir-alteracao'
    this.parecerUploadFc = new FormControl(new ArquivoSelecionado(), {
      validators: [arquivoUploadValidator(false)],
      updateOn: 'submit',
    }) as FormControl<ArquivoSelecionado>
  }

  ngOnInit(): void {
    const anexo: ArquivoSelecionado = new ArquivoSelecionado()
    this.anexoFormControl.setValue(anexo)
    this.anexoFormControl.disable()
    this.route.params.subscribe(params => {
      this.id = params['id']
      if (this.id) {
        this.parecerUploadPath += this.id
        this.recursoService.get(this.id).subscribe(obj => {
          this.recurso = obj
          anexo.setNameAndSize(obj.anexo, obj.anexoSize)
          this.anexoFormControl.setValue(anexo)

          this.parecerFC.setValue(obj.parecerConcluido.toString())
          this.parecerUploadFc.value.setNameAndSize(obj.parecerAnexo, obj.parecerAnexoSize as number)
          this.parecerUploadFc.setValue(this.parecerUploadFc.value)

          if (obj.parecerConcluido || this.isPermitirAlteracao) {
            this.parecerFC.disable()
            this.parecerUploadFc.disable()
          } else {
            this.parecerFC.valueChanges.subscribe(value => (this.salvarBtn = value == 'true'))
          }
        })
      }
    })
  }

  salvar(): void {
    if (this.parecerFC.value == 'true' && this.recurso) {
      this.recurso.parecerConcluido = true
      this.recursoService.analizar(this.recurso).subscribe(obj => {
        this.recurso = obj
        this.uploadRecurso()
      })
      this.parecerFC.disable()
      this.parecerUploadFc.disable()
      this.salvarBtn = false
      this.unespCoreMessageService.showMessageSuccess(`Recurso #${this.id} concluído com sucesso`)
    }
  }

  imprimir(): void {
    let content = `
    <style>
    h2 { text-transform: uppercase; margin: 10px 0 5px 0; padding: 0; font-size: 15px; }
    p { margin: 2px 0; padding: 0; font-size: 13px; }
    </style>
    <h2>Dados da recurso</h2>
    <p><b>Câmpus:</b> ${this.recurso?.concursoCampus}</p>
    <p><b>Concurso:</b> ${this.recurso?.tituloConcurso}</p>
    <p><b>Protocolo:</b> ${this.recurso?.protocolo}</p>
    <p><b>Data:</b> ${this.datepipe.transform(this.recurso?.data, 'dd/MM/YYYY HH:mm:ss')}</p>
    <p><b>Nome:</b> ${this.recurso?.nome}</p>
    <p><b>Tipo do recurso:</b> ${this.recurso?.tipoRecurso}</p>
    <p><b>Texto:</b></p>
    ${this.recurso?.texto}
    <hr>
    <p><b>Parecer:</b> ${this.recurso?.parecer}</p>
    <p><b>Parecer data:</b> ${
      this.recurso?.parecerData != null ? this.datepipe.transform(this.recurso?.parecerData, 'dd/MM/YYYY HH:mm:ss') : ''
    }</p>
    <p><b>Parecer responsável:</b> ${this.recurso?.parecerUsuario != null ? this.recurso?.parecerUsuario : ''}</p>
    `
    const windowPrint = window.open()
    windowPrint?.document.write(content)
    windowPrint?.document.close()
    windowPrint?.focus()
    windowPrint?.print()
    windowPrint?.close()
  }

  permitirAlteracao() {
    if (this.id && this.isPermitirAlteracao && this.recurso) {
      this.permitirAlteracaoService.liberarRegistro('recurso', this.id).subscribe(registro => {
        this.recurso!.parecerConcluido = registro.recurso.concluido as boolean
        this.parecerFC.setValue(registro.recurso.concluido.toString())
        this.unespCoreMessageService.showMessageSuccess(`Recurso #${this.id} revertido para "Em análise"`)
      })
    }
  }

  apagarDocumento(id: number | undefined) {
    if (this.parecerUploadFc.value.status === 2) {
      this.parecerUploadFc.setValue(new ArquivoSelecionado())
      return
    }
    const confirmDialog = new ConfirmDialogModel('Atenção', `Confirma a exclusão do Anexo?`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.recursoService.apagarArquivoAnexo(id, this.recurso?.parecerAnexo).subscribe(retorno => {
          if (retorno) {
            this.parecerUploadFc.setValue(new ArquivoSelecionado())
          }
        })
      }
    })
  }

  private uploadRecurso() {
    this.upload!.uploadPath = 'recurso/' + this.id
    if (this.parecerUploadFc.value.status == ArquivoStatus.SELECIONADO) {
      this.upload?.upload()
    }
  }
}
