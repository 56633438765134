<h1>Candidato</h1>
<mat-card>
  <mat-card-content>
    <fieldset fxFlex="100%">
      <legend>Alterar senha</legend>
      <form [formGroup]="form" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input
            matInput
            formControlName="email"
            name="email"
            type="email"
            [email]="true"
            required
            readonly="readonly" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Nova senha</mat-label>
          <input matInput formControlName="novaSenha" name="novaSenha" type="password" required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Confirmação</mat-label>
          <input matInput formControlName="novaSenhaConfirmacao" name="novaSenhaConfirmacao" type="password" required />
        </mat-form-field>

        <p>* Sua senha deve conter, pelo menos: 1 Letra maiúscula, 1 Letra minúscula, 1 Número e 8 Caracteres.</p>
      </form>
    </fieldset>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <button mat-raised-button color="primary" (click)="gravar()"><mat-icon>edit</mat-icon> Atualizar</button>
      <button mat-stroked-button color="warn" routerLink="/usuario-candidato">
        <mat-icon>close</mat-icon> Cancelar
      </button>
    </div>
  </mat-card-actions>
</mat-card>
