/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { UnespCoreAuthService, UnespCoreMessageService, UnespCoreUserService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { Sexo } from 'src/app/enums/sexo'
import { EstadoCivil } from 'src/app/enums/estado-civil'
import { Estado } from 'src/app/enums/estado'
import { OrgaoExpedicaoRg } from 'src/app/enums/orgao-expedicao-rg'
import { InscricaoFicha } from 'src/app/models/inscricao-ficha'
import { Estrangeiro } from 'src/app/enums/estrangeiro'
import { Deficiencia } from 'src/app/enums/deficiencia'
import { GrauEscolaridade } from 'src/app/enums/grau-escolaridade'
import { InscricaoComplemento } from 'src/app/models/inscricao-complemento'
import {
  ArquivoSelecionado,
  ArquivoStatus,
  arquivoUploadValidator,
} from '../../arquivo-upload/arquivo-upload.component'
import { Anexo } from 'src/app/models/anexo'
import { DatePipe } from '@angular/common'
import { InscricaoDocumento } from 'src/app/models/inscricao-documento'
import { ValidacaoService } from 'src/app/services/validacao.service'
import { Autodeclaracao } from 'src/app/enums/autodeclaracao'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'

@Component({
  selector: 'app-inscricao-ficha',
  templateUrl: './inscricao-ficha.component.html',
  styleUrls: ['./inscricao-ficha.component.scss'],
})
export class InscricaoFichaComponent implements OnInit {
  parametroPagina = this.route.snapshot.queryParamMap.get('pagina')
  parametroTamanho = this.route.snapshot.queryParamMap.get('tamanho')
  inscricaoFicha?: InscricaoFicha
  tipoConcurso?: TipoConcurso
  datepipe: DatePipe = new DatePipe('pt-BR')
  laudoMedicoFc: FormControl<ArquivoSelecionado>
  isPeriodoDeInscricao: boolean = false
  isPeriodoSegundaFase: boolean = false
  inscricaoEditavel: boolean = false
  edicaoFicha: boolean = false
  edicaoComplementos: boolean = false
  sexo = Sexo
  estadoCivil = EstadoCivil
  estado = Estado
  orgaoExpedicaoRg = OrgaoExpedicaoRg
  estrangeiro = Estrangeiro
  grauEscolaridade = GrauEscolaridade
  deficiencia = Deficiencia
  autodeclaraRaca: boolean | string = false
  pontuacaoDiferenciada: boolean | string = false
  autodeclaracao = Autodeclaracao
  declaracaoVeracidade: boolean = false
  temas: string[] = []
  complementos: InscricaoComplemento[] = []
  mapGruposComplementos: Map<string, InscricaoComplemento[]> = new Map()
  complementosInscricaoStatus: 'Completo' | 'Falta' | 'Faltou' = 'Faltou'
  complementosInscricaoStatusMsg: string = ''
  temSegundaFase: boolean = false
  complementosSegundaFaseStatus: 'Completo' | 'Falta' | 'Faltou' = 'Faltou'
  complementosSegundaFaseStatusMsg: string = ''
  anexos: Anexo[] = []
  documentos: InscricaoDocumento[] = []
  id?: number
  concursoId?: number
  isDepartamento: boolean = false
  formComplementar = new FormGroup({
    inscricao: new FormGroup({}),
    segundaFase: new FormGroup({}),
  })

  idConcurso: number = 0
  enderecoNacional: boolean = false

  form = new FormGroup({
    idConcurso: new FormControl('', Validators.required),
    tituloConcurso: new FormControl('', Validators.required),
    campus: new FormControl('', Validators.required),
    pago: new FormControl('', Validators.required),
    data: new FormControl('', Validators.required),
    protocolo: new FormControl('', Validators.required),
    cpf: new FormControl('', Validators.required),
    nome: new FormControl('', Validators.required),
    dataNascimento: new FormControl(new Date(), Validators.required),
    nomeSocial: new FormControl(''),
    nomeMae: new FormControl(''),
    nomePai: new FormControl(''),
    profissao: new FormControl('', Validators.required),
    escolaridade: new FormControl('', Validators.required),
    sexo: new FormControl('', Validators.required),
    estadoCivil: new FormControl('', Validators.required),
    estrangeiro: new FormControl('', Validators.required),
    passaporte: new FormControl(''),
    rne: new FormControl(''),
    rg: new FormControl({ value: '', disabled: true }),
    uf: new FormControl({ value: '', disabled: true }),
    dataExpedicao: new FormControl(new Date()),
    orgaoExpedicao: new FormControl({ value: '', disabled: true }),
    pais: new FormControl('', Validators.required),
    estado: new FormControl('', Validators.required),
    cidade: new FormControl('', Validators.required),
    celular: new FormControl('', Validators.required),
    telefone: new FormControl(''),
    email: new FormControl('', Validators.required),
    enderecoNacional: new FormControl('', Validators.required),
    enderecoCep: new FormControl('', Validators.required),
    enderecoLogradouro: new FormControl('', Validators.required),
    enderecoNumero: new FormControl('', Validators.required),
    enderecoBairro: new FormControl('', Validators.required),
    enderecoCidade: new FormControl('', Validators.required),
    enderecoEstado: new FormControl('', Validators.required),
    enderecoComplemento: new FormControl(''),
    canhoto: new FormControl('', Validators.required),
    autodeclaraRaca: new FormControl(''),
    pontuacaoDiferenciada: new FormControl(''),
    autodeclaracao: new FormControl(''),
    declaracaoVeracidade: new FormControl(''),
    deficiencia: new FormControl(),
    deficienciaNecessidadesProva: new FormControl(),
    temas: new FormControl(),
  })

  constructor(
    private inscricaoService: InscricaoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private unespCoreUserService: UnespCoreUserService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private unespCoreAuthService: UnespCoreAuthService,
    private router: Router,
    private validacaoService: ValidacaoService
  ) {
    this.laudoMedicoFc = new FormControl(new ArquivoSelecionado(), {
      validators: [arquivoUploadValidator(false)],
      updateOn: 'submit',
    }) as FormControl<ArquivoSelecionado>
    this.laudoMedicoFc.disable()
  }

  ngOnInit(): void {
    let tzOffset = new Date().getTimezoneOffset() * 60000
    this.isDepartamento = this.unespCoreAuthService.hasPermissionBasedRoles(['DEPARTAMENTO'])
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.concursoId = params['concursoId']
      this.form.reset()
      this.cd.detectChanges()

      if (this.id) {
        this.inscricaoService.get(this.id).subscribe(obj => {
          this.inscricaoFicha = obj
          this.tipoConcurso = obj.tipoConcurso
          this.documentos = obj.inscricaoDocumento
          this.inscricaoService.temas(Number(obj.id)).subscribe(temas => {
            this.temas = temas
          })
          this.form.get('idConcurso')?.setValue(obj.idConcurso.toString())
          this.idConcurso = Number(obj.idConcurso!)
          if (obj.periodoInscricao as boolean) {
            this.isPeriodoDeInscricao = true
            this.inscricaoEditavel = true
          }
          if (obj.periodoSegundaFase as boolean) {
            this.isPeriodoSegundaFase = true
          }

          if (
            this.unespCoreUserService.getUser()?.roles.includes('GERENTE') ||
            this.unespCoreUserService.getUser()?.roles.includes('RH')
          )
            this.inscricaoEditavel = true

          this.form.get('tituloConcurso')?.setValue(obj.tituloConcurso)
          this.form.get('campus')?.setValue(obj.campus)
          this.form.get('pago')?.setValue(obj.pago ? 'Confirmado' : 'Pendente')
          this.form.get('data')?.setValue(obj.data)
          this.form.get('protocolo')?.setValue(obj.protocolo)
          this.form.get('cpf')?.setValue(obj.cpf)
          this.form.get('nome')?.setValue(obj.nome)
          this.form.get('dataNascimento')?.setValue(new Date(Date.parse(obj.dataNascimento.toString()) + tzOffset))
          this.form.get('nomeSocial')?.setValue(obj.nomeSocial)
          this.form.get('nomeMae')?.setValue(obj.nomeMae)
          this.form.get('nomePai')?.setValue(obj.nomePai)
          this.form.get('profissao')?.setValue(obj.profissao)
          this.form.get('escolaridade')?.setValue(obj.escolaridade)
          this.form.get('sexo')?.setValue(obj.sexo)
          this.form.get('estadoCivil')?.setValue(obj.estadoCivil)
          this.form.get('estrangeiro')?.setValue(obj.estrangeiro)
          this.form.get('passaporte')?.setValue(obj.passaporte)
          this.form.get('rne')?.setValue(obj.rne)
          this.form.get('rg')?.setValue(obj.rg)
          this.form.get('uf')?.setValue(obj.uf)
          this.form
            .get('dataExpedicao')
            ?.setValue(
              obj.dataExpedicao !== null && obj.dataExpedicao !== ''
                ? new Date(Date.parse(obj.dataExpedicao.toString()) + tzOffset)
                : null
            )
          this.form.get('orgaoExpedicao')?.setValue(obj.orgaoExpedicao)
          this.form.get('pais')?.setValue(obj.pais)
          this.form.get('estado')?.setValue(obj.estado)
          this.form.get('cidade')?.setValue(obj.cidade)
          this.form.get('celular')?.setValue(obj.celular)
          this.form.get('telefone')?.setValue(obj.telefone)
          this.form.get('email')?.setValue(obj.email)
          this.form.get('enderecoNacional')?.setValue('false')
          this.form.get('enderecoCep')?.setValue(obj.enderecoCep)
          this.form.get('enderecoLogradouro')?.setValue(obj.enderecoLogradouro)
          this.form.get('enderecoNumero')?.setValue(obj.enderecoNumero)
          this.form.get('enderecoBairro')?.setValue(obj.enderecoBairro)
          this.form.get('enderecoCidade')?.setValue(obj.enderecoCidade)
          this.form.get('enderecoEstado')?.setValue(obj.enderecoEstado)
          this.form.get('enderecoComplemento')?.setValue(obj.enderecoComplemento)
          this.form.get('canhoto')?.setValue(obj.canhoto ? 'true' : 'false')

          if (this.tipoConcurso.match('TECADM')) {
            this.form.get('autodeclaraRaca')?.setValue(obj.autodeclaraRaca ? 'true' : 'false')
            this.form.get('pontuacaoDiferenciada')?.setValue(obj.pontuacaoDiferenciada ? 'true' : 'false')
            this.form
              .get('autodeclaracao')
              ?.setValue(obj.autodeclaracao == null || '' ? 'NAO_INFORMADO' : obj.autodeclaracao)
            this.form.get('declaracaoVeracidade')?.setValue(obj.declaracaoVeracidade ? 'true' : 'false')
          } else {
            this.form.get('autodeclaraRaca')?.setValue('false')
            this.form.get('pontuacaoDiferenciada')?.setValue('false')
            this.form.get('autodeclaracao')?.setValue('NAO_INFORMADO')
            this.form.get('declaracaoVeracidade')?.setValue('false')
            this.form.get('autodeclaraRaca')?.disable()
            this.form.get('pontuacaoDiferenciada')?.disable()
            this.form.get('autodeclaracao')?.disable()
            this.form.get('declaracaoVeracidade')?.disable()
          }

          this.form.get('deficiencia')?.setValue(obj.deficiencia)
          this.form.get('deficienciaNecessidadesProva')?.setValue(obj.deficienciaNecessidadesProva)
          this.form.get('temas')?.setValue(obj.temas)

          this.laudoMedicoFc.value.setNameAndSize(obj.deficienciaLaudoMedico, obj.deficienciaLaudoMedicoTamanho)
          this.laudoMedicoFc.setValue(this.laudoMedicoFc.value)
          this.initComplementos(obj.inscricaoComplemento)
          this.initAnexos(
            obj.depositoAnexo,
            obj.reducaoComprovanteEstudante,
            obj.reducaoComprovanteRenda,
            obj.deficienciaLaudoMedico
          )

          this.form.disable()
          this.formComplementar.get('inscricao')?.disable()
          this.formComplementar.get('segundaFase')?.disable()
          this.cd.detectChanges()
        })
      }
    })
  }

  private initComplementos(complementos: InscricaoComplemento[]) {
    this.complementos = complementos
    let itensFaltandoInscricaoCount = 0
    let itensFaltandoSegundaFaseCount = 0
    for (let complemento of complementos) {
      if (complemento.etapa === 'SEGUNDA FASE') this.temSegundaFase = true

      let grupoNome = '[' + complemento.etapa + '] ' + complemento.grupo
      if ((complemento.requerido as boolean) && complemento.valor.length == 0) {
        if (complemento.etapa === 'SEGUNDA FASE') itensFaltandoSegundaFaseCount++
        else itensFaltandoInscricaoCount++
      }
      let arr = this.mapGruposComplementos.get(grupoNome)
      arr ? arr.push(complemento) : this.mapGruposComplementos.set(grupoNome, [complemento])
    }
    this.setStatusComplemento(itensFaltandoInscricaoCount, itensFaltandoSegundaFaseCount)
    this.formComplementar.valueChanges.subscribe(() => this.updateComplementoStatus())
  }

  // usado para exibir na tela seguindo a ordem que os elementos foram adicionado no Map
  asIsOrder(a: any, b: any) {
    return 0
  }

  updateComplementoStatus() {
    let itensFaltandoInscricaoCount = 0
    let itensFaltandoSegundaFaseCount = 0
    for (let complemento of this.complementos) {
      if (!(complemento.requerido as boolean)) continue

      let fase = complemento.etapa == 'SEGUNDA FASE' ? 'segundaFase' : 'inscricao'
      let fc = this.formComplementar.get(fase)?.get(complemento.id.toString())
      if (fc == null) continue

      if (complemento.tipoComplemento == 'FILE') {
        const fstatus = fc.value.status
        if (fstatus == ArquivoStatus.ENVIADO || fstatus == ArquivoStatus.SALVO) continue
      } else if (fc.value) continue

      if (complemento.etapa === 'SEGUNDA FASE') itensFaltandoSegundaFaseCount++
      else itensFaltandoInscricaoCount++
    }
    this.setStatusComplemento(itensFaltandoInscricaoCount, itensFaltandoSegundaFaseCount)
  }

  private setStatusComplemento(faltandoPrimeiraFase: number, faltandoSegundaFase: number) {
    {
      let { status, msg } = this.statusComplementoFase(faltandoPrimeiraFase, 'inscrição', this.isPeriodoDeInscricao)
      this.complementosInscricaoStatus = status
      this.complementosInscricaoStatusMsg = msg
    }
    {
      let { status, msg } = this.statusComplementoFase(
        faltandoSegundaFase,
        'segunda fase',
        this.isPeriodoDeInscricao || this.isPeriodoSegundaFase
      )
      this.complementosSegundaFaseStatus = status
      this.complementosSegundaFaseStatusMsg = msg
    }
  }

  private statusComplementoFase(faltando: number, fase: string, isPeriodo: boolean): any {
    let status: 'Completo' | 'Falta' | 'Faltou'
    let msg: string

    if (faltando == 0) {
      status = 'Completo'
      msg = `Todos os itens necessários da ${fase} foram enviados.`
    } else {
      if (isPeriodo) status = 'Falta'
      else status = 'Faltou'

      if (faltando == 1) msg = `${status} o envio/preenchimento de 1 item obrigatório da ${fase}`
      else msg = `${status} o envio/preenchimento de ${faltando} itens obrigatórios da ${fase}`
    }

    return { status, msg }
  }

  private initAnexos(deposito: string, estudante: string, renda: string, laudoMedico: string) {
    let anexos: Anexo[] = []

    if (laudoMedico)
      anexos.push({
        nome: 'Laudo médico de deficiências',
        path: `inscricao/${this.id}/laudomedico`,
        fileName: laudoMedico,
      })

    if (deposito && this.showDepositoEmAnexos())
      anexos.push({ nome: 'Comprovante de depósito', path: `inscricao/${this.id}/pagamento`, fileName: deposito })

    if (estudante && this.showTabReducao())
      anexos.push({
        nome: 'Comprovante estudante',
        path: `inscricao/${this.id}/comprovanteestudante`,
        fileName: estudante,
      })

    if (renda && this.showTabReducao())
      anexos.push({ nome: 'Comprovante de renda', path: `inscricao/${this.id}/comprovanterenda`, fileName: renda })

    for (const arq of this.complementos) {
      if (arq.tipoComplemento == 'FILE' && arq.valor) {
        let nome = '[' + arq.etapa + '] ' + arq.grupo
        if (arq.explicacao) nome += ': ' + arq.explicacao
        anexos.push({
          nome,
          // path: 'inscricaocomplemento/' + arq.id, // download pelo id do complemento
          path: 'inscricaocomplemento/' + this.idConcurso, // download pelo id do concurso
          obrigatorio: (arq.requerido as boolean) ? 'Obrigatório' : 'Opcional',
          fileName: arq.valor,
        })
      }
    }

    this.anexos = anexos
  }

  modoEdicao() {
    this.edicaoFicha = !this.edicaoFicha
    if (this.edicaoFicha) {
      this.form.enable()
      this.validaEstrangeiro()
    } else {
      this.form.disable()
      this.formComplementar.disable()
    }
  }

  modoEditarComplementos() {
    this.edicaoComplementos = !this.edicaoComplementos
    if (this.edicaoComplementos) {
      if (this.inscricaoEditavel) this.formComplementar.get('inscricao')?.enable()
      this.formComplementar.get('segundaFase')?.enable()
    } else {
      this.formComplementar.disable()
    }
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  voltar(): void {
    if (this.concursoId) {
      this.router.navigate([`/inscricao/${this.concursoId}`], {
        queryParams: { pagina: this.parametroPagina, tamanho: this.parametroTamanho },
      })
    } else {
      this.router.navigate(['/inscricao'], {
        queryParams: { pagina: this.parametroPagina, tamanho: this.parametroTamanho },
      })
    }
  }

  gravar(): void {
    if (this.edicaoFicha) {
      this.applyTouchedToInvalidFields()

      if (!this.form.valid) {
        this.unespCoreMessageService.showMessageError('Erro ao preencher os dados do formulário')
        return
      }
      if (this.id) {
        //let formulario = <InscricaoFicha>this.form.getRawValue()
        let formularioTemporario = this.form.getRawValue()
        let { enderecoNacional, ...formularioFinal } = formularioTemporario

        //let formulario = <InscricaoFicha>this.form.value
        let formulario = <InscricaoFicha>formularioFinal

        formulario.pago = formulario.pago == 'Confirmado' ? true : false
        formulario.canhoto = formulario.canhoto == 'true' ? true : false

        formulario.declaracaoVeracidade = formulario.declaracaoVeracidade == 'true' ? 'true' : 'false'
        formulario.pontuacaoDiferenciada = formulario.pontuacaoDiferenciada == 'true' ? 'true' : 'false'

        //formulario.inscricaoComplemento = this.atualizaListaDeComplementos()
        //formulario.dataExpedicao = this.formatToUs(formulario.dataExpedicao.toString())

        this.inscricaoService.alterar(this.id, formulario).subscribe(inscricaoFicha => {
          if (inscricaoFicha.id) {
            this.unespCoreMessageService.showMessageSuccess(`Ficha #${this.id} atualizada com sucesso`)
          } else {
            this.unespCoreMessageService.showMessageError(
              'Não foi possível salvar, verifique os dados e tente novamente'
            )
          }
        })
      }
    }
  }

  showTabReducao(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'FINANCAS'])
  }

  showTabPagamento(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'FINANCAS'])
  }

  showDepositoEmAnexos(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'FINANCAS', 'DEPARTAMENTO'])
  }

  showTabDevolucao(): boolean {
    return (
      this.unespCoreAuthService.hasPermissionBasedRoles(['FINANCAS']) &&
      (this.inscricaoFicha?.devolucaoSolicitada ?? false)
    )
  }

  showTabSituacao(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA'])
  }

  showModoEdicao(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'GERENTE'])
  }

  showTabAnexos(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles([
      'RH',
      'DTA',
      'GERENTE',
      'CCPT',
      'CACP',
      'DEPARTAMENTO',
      'DIRETOR',
    ])
  }

  showTabDocumentos(): boolean {
    return this.unespCoreAuthService.hasPermissionBasedRoles(['RH', 'DTA', 'GERENTE'])
  }

  imprimir(): void {
    let imprimirTipoConcurso = `<p><b>Se autodeclarou racialmente:</b> ${
      this.inscricaoFicha?.autodeclaraRaca ? 'Sim' : 'Não'
    }</p>
    <p><b>Usou pontuação Diferenciada:</b> ${this.inscricaoFicha?.pontuacaoDiferenciada ? 'Sim' : 'Não'}</p>
    <p><b>Autodeclaração:</b> ${this.inscricaoFicha?.autodeclaracao}</p>
    <p><b>Confirmação Veracidade:</b> ${this.inscricaoFicha?.declaracaoVeracidade ? 'Sim' : 'Não'}</p>`

    let content = `
    <style>
    h2 { text-transform: uppercase; margin: 10px 0 5px 0; padding: 0; font-size: 15px; }
    p { margin: 2px 0; padding: 0; font-size: 13px; }
    </style>
    <h2>Dados da inscrição</h2>
    <p><b>Inscrição:</b> ${this.id}</p>
    <p><b>Câmpus:</b> ${this.inscricaoFicha?.campus}</p>
    <p><b>Concurso:</b> ${this.inscricaoFicha?.tituloConcurso}</p>
    <p><b>Data da inscrição:</b> ${this.datepipe.transform(this.inscricaoFicha?.data, 'dd/MM/YYYY HH:mm:ss')}</p>
    ${!this.isDepartamento ? `<p><b>Protocolo:</b> ${this.inscricaoFicha?.protocolo} </p>` : ''}
    <p><b>Pagamento:</b> ${this.inscricaoFicha?.pago ? 'Confirmado' : 'Pendente'}</p>

    <h2>Dados do candidato</h2>
    <p><b>Nome:</b> ${this.inscricaoFicha?.nome}</p>
    <p><b>Nome social:</b> ${this.inscricaoFicha?.nomeSocial != null ? this.inscricaoFicha?.nomeSocial : ''}</p>
    <p><b>Data de nascimento:</b> ${this.datepipe.transform(this.inscricaoFicha?.dataNascimento, 'dd/MM/YYYY')}</p>
    <p><b>Sexo:</b> ${this.inscricaoFicha?.sexo}</p>
    <p><b>Estado civil:</b> ${this.inscricaoFicha?.estadoCivil}</p>
    <p><b>Nome da mãe:</b> ${this.inscricaoFicha?.nomeMae}</p>
    <p><b>Nome do pai:</b> ${this.inscricaoFicha?.nomePai}</p>
    <p><b>Profissão:</b> ${this.inscricaoFicha?.profissao}</p>
    <p><b>Grau de escolaridade:</b> ${this.inscricaoFicha?.escolaridade}</p>
    <p><b>Estrangeiro:</b> ${this.inscricaoFicha?.estrangeiro}</p>
    <p><b>CPF:</b> ${this.inscricaoFicha?.cpf}</p>
    <p><b>Passaporte:</b> ${this.inscricaoFicha?.passaporte != null ? this.inscricaoFicha?.passaporte : ''}</p>
    <p><b>RNE:</b> ${this.inscricaoFicha?.rne != null ? this.inscricaoFicha?.rne : ''}</p>
    <p><b>RG:</b> ${this.inscricaoFicha?.rg != null ? this.inscricaoFicha?.rg : ''}</p>
    <p><b>UF:</b> ${this.inscricaoFicha?.uf != null ? this.inscricaoFicha?.uf : ''}</p>
    <p><b>Data de expedição:</b> ${
      this.inscricaoFicha?.dataExpedicao != null
        ? this.datepipe.transform(this.inscricaoFicha?.dataExpedicao, 'dd/MM/YYYY')
        : ''
    }</p>
    <p><b>Orgão:</b> ${this.inscricaoFicha?.orgaoExpedicao != null ? this.inscricaoFicha?.orgaoExpedicao : ''}</p>
    <h2>Local de nascimento</h2>
    <p><b>País:</b> ${this.inscricaoFicha?.pais}</p>
    <p><b>Estado:</b> ${this.inscricaoFicha?.estado}</p>
    <p><b>Cidade:</b> ${this.inscricaoFicha?.cidade}</p>

    <h2>Informações de contato</h2>
    <p><b>Celular:</b> ${this.inscricaoFicha?.celular}</p>
    <p><b>Telefone:</b> ${this.inscricaoFicha?.telefone}</p>
    <p><b>E-mail:</b> ${this.inscricaoFicha?.email}</p>

    <h2>Endereço</h2>
    <p><b>CEP:</b> ${this.inscricaoFicha?.enderecoCep}</p>
    <p><b>Logradouro:</b> ${this.inscricaoFicha?.enderecoLogradouro}</p>
    <p><b>Número:</b> ${this.inscricaoFicha?.enderecoNumero}</p>
    <p><b>Complemento:</b> ${this.inscricaoFicha?.enderecoComplemento}</p>
    <p><b>Bairro:</b> ${this.inscricaoFicha?.enderecoBairro}</p>
    <p><b>Cidade:</b> ${this.inscricaoFicha?.enderecoCidade}</p>
    <p><b>Estado:</b> ${this.inscricaoFicha?.enderecoEstado}</p>

    <h2>Outros</h2>
    <p><b>Canhoto:</b> ${this.inscricaoFicha?.canhoto ? 'Sim' : 'Não'}</p>

    ${this.tipoConcurso?.match('TECADM') ? imprimirTipoConcurso : ''}
    
    <p><b>Deficiências:</b> ${this.inscricaoFicha?.deficiencia}</p>
    <p><b>Recursos especiais necessários para a realização das provas:</b> ${
      this.inscricaoFicha?.deficienciaNecessidadesProva != null ? this.inscricaoFicha?.deficienciaNecessidadesProva : ''
    }</p>

    ${this.inscricaoFicha?.temas != '' ? `<h2>Pontos</h2><p>${this.inscricaoFicha?.temas}</p>` : ''}
    `
    const windowPrint = window.open()
    windowPrint?.document.write(content)
    windowPrint?.document.close()
    windowPrint?.focus()
    windowPrint?.print()
    windowPrint?.close()
  }

  validaCEP(enderecoNacional: boolean): void {
    this.validacaoService.validaCEP(this.form, enderecoNacional)
  }

  validaDataNascimento(): void {
    this.validacaoService.validaDataNascimento(this.form)
  }

  validaEstrangeiro(): void {
    this.validacaoService.validaEstrangeiro(this.form)
  }

  alteraTipoEndereco(): void {
    //this.enderecoNacional = this.form.controls['enderecoNacional'].value === 'true' ? true : false
    this.enderecoNacional = this.form.get('enderecoNacional')?.value === 'true' ? true : false
  }
}
