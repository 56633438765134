/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { LogEntry } from '../models/log-entry'
import { ProvasArr, TipoProva } from './tipo-prova'

interface TipoAcao {
  acao: string
  nome: string
  descricao: string
}

export const LogAcoes: TipoAcao[] = [
  { acao: 'LOGIN', nome: 'Login', descricao: 'Login' },
  { acao: 'INSERT', nome: 'Inserção', descricao: 'Cria' },
  { acao: 'UPDATE', nome: 'Atualização', descricao: 'Atualiza' },
  { acao: 'DELETE', nome: 'Remoção', descricao: 'Exclui' },
]

export const LogAcaoMap = new Map<string, TipoAcao>(LogAcoes.map(r => [r.acao, r]))

interface TipoRegistro {
  registro: string
  nome: string
  regPai?: string
  txtAsReg: string
  txtAsPai?: string
  geraTxtLink?: (id: number) => string
  linkPara?: string
  calcIdPai?: (id: number) => number
}

const geraTxtLinkTipoProva = (id: number) => {
  let provaIdx = id % 10
  if (provaIdx < ProvasArr.length) {
    let prova = (TipoProva as any)[ProvasArr[provaIdx]]
    return prova.nome
  }

  return 'Prova #' + id
}

const idProvaParaIdConcurso = (id: number) => Math.floor(id / 10)

export const LogRegistros: TipoRegistro[] = [
  { registro: 'INSCRICAO', nome: 'Inscrição', txtAsReg: 'inscrição', txtAsPai: 'da inscrição' },

  { registro: 'INSCRICAO_COMPLEMENTO', nome: 'Complemento da Inscrição', txtAsReg: 'complemento', regPai: 'INSCRICAO' },

  { registro: 'INSCRICAO_DOCUMENTO', nome: 'Documento da Inscrição', txtAsReg: 'documento', regPai: 'INSCRICAO' },

  { registro: 'CONCURSO', nome: 'Concurso', txtAsReg: 'concurso', txtAsPai: 'do concurso' },

  { registro: 'CONCURSO_TEMA', nome: 'Tema', txtAsReg: 'tema', regPai: 'CONCURSO' },

  { registro: 'CONCURSO_ANDAMENTO', nome: 'Andamento', txtAsReg: 'andamento', regPai: 'CONCURSO' },

  { registro: 'FORMULARIO', nome: 'Formulário Complementar', txtAsReg: 'formulário complementar' },

  { registro: 'RECURSO', nome: 'Recurso', txtAsReg: 'recurso' },

  { registro: 'TIPO_RECURSO', nome: 'Tipo Recurso', txtAsReg: 'tipo recurso' },

  { registro: 'CONCURSO_PROVA', nome: 'Prova', txtAsReg: '', regPai: 'CONCURSO', geraTxtLink: geraTxtLinkTipoProva },

  {
    registro: 'CONCURSO_PROVA_CRITERIO',
    nome: 'Critérios de Prova',
    txtAsReg: 'critério da',
    regPai: 'CONCURSO',
    geraTxtLink: geraTxtLinkTipoProva,
    linkPara: 'CONCURSO_PROVA',
    calcIdPai: idProvaParaIdConcurso,
  },

  {
    registro: 'CONCURSO_PROVA_SUBCRITERIO',
    nome: 'Subcritérios de Prova',
    txtAsReg: 'subritério da',
    regPai: 'CONCURSO',
    geraTxtLink: geraTxtLinkTipoProva,
    linkPara: 'CONCURSO_PROVA',
    calcIdPai: idProvaParaIdConcurso,
  },

  {
    registro: 'INSCRICAO_NOTA_PROVA',
    nome: 'Nota de prova',
    txtAsReg: 'nota na',
    regPai: 'INSCRICAO',
    geraTxtLink: geraTxtLinkTipoProva,
  },
]

export const LogRegistrosMap = new Map<string, TipoRegistro>(LogRegistros.map(reg => [reg.registro, reg]))

export function getAcaoTipoRegistro(acao: string): string {
  const refTo = acao.slice(acao.indexOf('_') + 1) || ''
  const registro = LogRegistrosMap.get(refTo)?.registro

  return registro ? registro : 'TODOS'
}

export function getAcaoTipoPai(acao: string): string {
  const refTo = acao.slice(acao.indexOf('_') + 1) || ''
  const pai = LogRegistrosMap.get(refTo)?.regPai

  return pai ? pai : 'TODOS'
}

export function geraTextoAcao(entry: LogEntry) {
  const [operacao] = entry.acao.split('_', 2)
  const refTo = entry.acao.slice(entry.acao.indexOf('_') + 1) || ''

  let op

  if (entry.nivel != 'Erro' && operacao == 'UPDATE' && !entry.detalhes?.diffArr) {
    op = entry.detalhes?.descricaoDaAcao
    entry.temMaisDetalhes = false
  } else op = LogAcaoMap.get(operacao)?.descricao

  let reg = LogRegistrosMap.get(refTo)

  let acao = `${op} ${reg ? reg.txtAsReg : ''}`

  let registro = ''
  if (entry.registroId) {
    registro = '#' + entry.registroId
    if (reg?.geraTxtLink) {
      registro = reg.geraTxtLink(entry.registroId)
    }
  }

  let tipoPai = reg?.regPai ? LogRegistrosMap.get(reg.regPai)?.txtAsPai : ''
  tipoPai = tipoPai ? tipoPai : ''

  let pai
  if (!entry.registroPai) pai = ''
  else if (reg?.calcIdPai) pai = '#' + reg?.calcIdPai(entry.registroPai)
  else pai = '#' + entry.registroPai

  entry.textoAcao = { acao, registro, tipoPai, pai }
}
