/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { Configuracao } from '../models/configuracao'
import { ItemListaConfiguracao } from '../models/item-lista-configuracao'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class ConfiguracaoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  get(id: number): Observable<Configuracao> {
    return this.http
      .get<Configuracao>(`${this.baseUrl}/configuracao/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listarGerencia(): Observable<ItemListaConfiguracao[]> {
    return this.http
      .get<ItemListaConfiguracao[]>(`${this.baseUrl}/configuracao/gerencia`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listarRHeFinancas(): Observable<ItemListaConfiguracao[]> {
    return this.http
      .get<ItemListaConfiguracao[]>(`${this.baseUrl}/configuracao`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  atualizar(conf: Configuracao): Observable<Configuracao> {
    return this.http
      .put<Configuracao>(`${this.baseUrl}/configuracao/${conf.id}`, conf)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
