/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface ArquivoData {
  tituloDoArquivo: string
  urlDoArquivo: string
}

@Component({
  selector: 'app-arquivo-viewer-dialog',
  templateUrl: 'arquivo-viewer-dialog.component.html',
  styleUrls: ['./arquivo-viewer-dialog.component.css'],
})
export class ArquivoViewerDialogComponent implements AfterViewInit {
  @ViewChild('arquivoIframe') arquivoIframe?: ElementRef

  constructor(
    public dialogRef: MatDialogRef<ArquivoViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArquivoData
  ) {}

  ngAfterViewInit(): void {
    this.arquivoIframe!.nativeElement.setAttribute!('src', this.data.urlDoArquivo)
  }
}
