/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { Observable, throwError, map } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
  constructor(private unespCoreMessageService: UnespCoreMessageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('https://viacep.com.br/ws')) {
      return next.handle(request.clone({ headers: new HttpHeaders(), withCredentials: false }))
    }
    return next.handle(request).pipe(
      map(res => {
        if (res instanceof HttpResponse) {
          if (res.status === 205 && request.url.includes('auth')) {
            this.unespCoreMessageService.showMessageInfo(
              'Ajustamos alguns dados de acesso. Tente logar novamente.',
              undefined,
              5000
            )
          }
        }
        return res
      }),
      catchError(err => {
        return throwError(() => err)
      })
    )
  }
}
