/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TipoRecursoService } from 'src/app/services/tipo-recurso.service'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute, Router } from '@angular/router'
import { DatePipe } from '@angular/common'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { Observable, map, of, startWith } from 'rxjs'
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips'
import { TipoRecurso } from 'src/app/models/tipo-recurso'
import { TipoAndamento } from 'src/app/enums/tipo-andamento'

interface TipoConcursoOpt {
  key: string
  description: string
}

interface TipoCategoria {
  RH: string
  Diretor: string
  Departamento: string
}

@Component({
  selector: 'app-tipo-recurso-incluir',
  templateUrl: './tipo-recurso-incluir.component.html',
  styleUrls: ['./tipo-recurso-incluir.component.css'],
})
export class TipoRecursoIncluirComponent implements OnInit {
  id?: number
  tipoAndamento = TipoAndamento
  camposVazios = false
  tipoAndamentoFC: FormControl = new FormControl('', Validators.required)
  tipoAndamentoValorFC: FormControl = new FormControl('', Validators.required)
  tipoRecursoFC: FormControl = new FormControl('', Validators.required)
  categoriaFC: FormControl = new FormControl('', Validators.required)
  prazoFC: FormControl = new FormControl('', Validators.required)
  tipoConcursoFC: FormControl = new FormControl('', Validators.required)

  formularioFC: FormGroup = new FormGroup({
    tipoRecurso: this.tipoRecursoFC,
    prazo: this.prazoFC,
    tipoAndamento: this.tipoAndamentoFC,
    tipoAndamentoValor: this.tipoAndamentoValorFC,
    categoria: this.categoriaFC,
    tipoConcurso: this.tipoConcursoFC,
  })

  recurso?: TipoRecurso
  datepipe: DatePipe = new DatePipe('pt-BR')

  @ViewChild('tipoConcursoChipList') tipoConcursoChipList: MatChipGrid | undefined
  @ViewChild('tipoConcursoInput') tipoConcursoInput: ElementRef | undefined

  todosTiposConcursos: TipoConcursoOpt = { key: 'TODOS', description: 'Nenhum Tipo de Concurso Selecionado' }
  showTodosTiposConcursosMC: boolean = true
  listaDeConcursos: TipoConcursoOpt[] = []
  tiposDeConcursosSelecionados: TipoConcursoOpt[] = []
  tiposConcursosFiltrados: Observable<TipoConcursoOpt[]> = of(this.listaDeConcursos)

  constructor(
    private tipoRecursoService: TipoRecursoService,
    private route: ActivatedRoute,
    private router: Router,
    private unespCoreMessageService: UnespCoreMessageService
  ) {}

  ngOnInit(): void {
    for (let elemento in TipoConcurso) {
      this.listaDeConcursos.push({
        key: elemento,
        description: (TipoConcurso as any)[elemento],
      })
    }

    this.listaDeConcursos.push(this.todosTiposConcursos)

    this.tiposConcursosFiltrados = this.tipoConcursoFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        const str = typeof value === 'string' ? value : this.addChip(value)
        return str ? this._filtroTipoConcurso(str) : this.listaDeConcursos
      })
    )

    this.tipoConcursoFC.valueChanges.subscribe(
      () => (this.tipoConcursoChipList!.errorState = this.tipoConcursoFC.invalid)
    )
  }

  incluir(): void {
    this.applyTouchedToInvalidFields()
    let campos: TipoRecurso = {
      id: this.id,
      tipoRecurso: this.tipoRecursoFC.value,
      categoria: this.categoriaFC.value,
      prazo: this.prazoFC.value,
      tipoAndamento: this.tipoAndamentoFC.value,
      tipoAndamentoValor: this.tipoAndamentoFC.value,
      tipoConcurso: this.tiposDeConcursosSelecionados
        .map(tipConc => {
          return tipConc.key
        })
        .join(','),
    }

    if (this.verificaCamposVazios()) {
      this.tipoRecursoService.listar().subscribe(obj => {
        let listaTipos = obj.content as TipoRecurso[]
        let incluirRegistro = true
        for (const item in listaTipos) {
          if (
            listaTipos[item].tipoAndamentoValor.toUpperCase().match(campos.tipoAndamentoValor.toUpperCase()) &&
            listaTipos[item].tipoRecurso.toUpperCase().match(campos.tipoRecurso.toUpperCase())
          ) {
            incluirRegistro = false
            return this.unespCoreMessageService.showMessageError(`Tipo de Recurso já existente: `)
          }
        }

        if (incluirRegistro) {
          this.tipoRecursoService.incluir(campos).subscribe(obj => {
            this.recurso = obj
            this.unespCoreMessageService.showMessageSuccess(`Tipo de Recurso incluído com sucesso`)
            this.router.navigate(['/tipo-recurso'])
          })
        }
      })
    } else {
      return this.unespCoreMessageService.showMessageError(`Preencha todos os campos`)
    }
  }

  private _filtroTipoConcurso(tipo: string) {
    const filterValue = tipo.toLowerCase()
    return this.listaDeConcursos.filter(
      opt =>
        this.tiposDeConcursosSelecionados.indexOf(opt) == -1 &&
        (opt.key.toLowerCase().includes(filterValue) || opt.description.toLowerCase().includes(filterValue))
    )
  }

  addChip(tipo: TipoConcursoOpt): void {
    if (tipo === this.todosTiposConcursos) this.tiposDeConcursosSelecionados = []
    else if (this.tiposDeConcursosSelecionados.indexOf(tipo) == -1) this.tiposDeConcursosSelecionados.push(tipo)
  }

  removeChip(tipo: TipoConcursoOpt): void {
    const index = this.tiposDeConcursosSelecionados.indexOf(tipo)
    if (index >= 0) this.tiposDeConcursosSelecionados.splice(index, 1)
    if (tipo === this.todosTiposConcursos) this.tipoConcursoInput?.nativeElement.focus()
  }

  focusChip(): void {
    this.showTodosTiposConcursosMC = false
  }

  blurChip(event: MatChipInputEvent): void {
    event.chipInput!.clear()
    this.showTodosTiposConcursosMC = true
  }

  verificaCamposVazios(): boolean {
    if (
      this.tipoAndamentoFC.value != '' &&
      this.tipoAndamentoFC.value != null &&
      this.tipoAndamentoFC.valid &&
      this.tipoRecursoFC.value !== '' &&
      this.tipoRecursoFC.value !== null &&
      this.tipoRecursoFC.valid &&
      this.categoriaFC.value !== '' &&
      this.categoriaFC.value !== null &&
      this.categoriaFC.valid &&
      this.prazoFC.value !== '' &&
      this.prazoFC.value !== null &&
      this.prazoFC.valid &&
      this.tipoConcursoFC.value.toString() !== '' &&
      this.tipoConcursoFC !== null &&
      this.tipoConcursoFC.valid
    ) {
      this.camposVazios = true
    }
    return this.camposVazios
  }

  applyTouchedToInvalidFields() {
    for (const name in this.formularioFC.controls) {
      if (this.formularioFC.get(name)?.invalid) {
        this.formularioFC.get(name)?.markAsTouched()
      }
    }
  }
}
