/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { SituacaoInscricao } from '../enums/situacao-inscricao'
import { Inscricao } from '../models/inscricao'
import { ListagemSituacao } from '../models/listagem-situacao'
import { ListagemClassificacao } from '../models/listagem-classificacao'

@Injectable({
  providedIn: 'root',
})
export class ListagensDiversasService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  getSituacaoInscricao(idConcurso: number, search = '', filtro = 'TODOS', page = 0, size = 10): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/listagem/situacao/${idConcurso}`, {
        params: new HttpParams()
          .set('search', search)
          .set('filtro', filtro)
          .set('page', page.toString())
          .set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  getReducao(
    idConcurso: number,
    search = '',
    filtro = 'TODOS',
    filtroDeferido = 'TODOS',
    page = 0,
    size = 10
  ): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/listagem/reducao-taxa/${idConcurso}`, {
        params: new HttpParams()
          .set('search', search)
          .set('filtro', filtro)
          .set('filtroDeferido', filtroDeferido)
          .set('page', page.toString())
          .set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  getPagamento(idConcurso: number, search = '', filtro = 'TODOS', page = 0, size = 10): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/listagem/pagamento/${idConcurso}`, {
        params: new HttpParams()
          .set('search', search)
          .set('filtro', filtro)
          .set('page', page.toString())
          .set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  salvarSituacaoInscricao(
    idConcurso: number | undefined,
    idInscricao: number,
    situacao: string,
    motivo: string
  ): Observable<ListagemSituacao> {
    let formData = new FormData()
    formData.append('situacao', situacao)
    formData.append('motivo', motivo)
    return this.http
      .put<ListagemSituacao>(
        `${this.baseUrl}/listagem/situacao-inscricao/${idConcurso}/${idInscricao}/salvar`,
        formData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  concluirSituacaoInscricao(idConcurso: number | undefined, idInscricao: number): Observable<ListagemSituacao> {
    return this.http
      .put<ListagemSituacao>(`${this.baseUrl}/listagem/situacao-inscricao/${idConcurso}/${idInscricao}/concluir`, null)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  putNovoMotivo(idConcurso: number | undefined, inscricao: Inscricao, motivo: string): Observable<SituacaoInscricao> {
    return this.http
      .put<SituacaoInscricao>(
        `${this.baseUrl}/listagem/situacao-inscricao/${idConcurso}/${inscricao.id}/atualiza-motivo`,
        null
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  getClassificacaoInscricao(idConcurso: number, search = '', filtro = 'TODOS', page = 0, size = 10): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/listagem/classificacao/${idConcurso}`, {
        params: new HttpParams()
          .set('search', search)
          .set('filtro', filtro)
          .set('page', page.toString())
          .set('size', size.toString()),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  salvarClassificacaoInscricao(
    idConcurso: number | undefined,
    idInscricao: number,
    classificacao: string,
    posicao: string
  ): Observable<ListagemClassificacao> {
    let formData = new FormData()
    formData.append('classificacao', classificacao)
    formData.append('posicao', posicao)
    return this.http
      .put<ListagemClassificacao>(
        `${this.baseUrl}/listagem/classificacao-inscricao/${idConcurso}/${idInscricao}/salvar`,
        formData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  concluirClassificacaoInscricao(
    idConcurso: number | undefined,
    idInscricao: number
  ): Observable<ListagemClassificacao> {
    return this.http
      .put<ListagemClassificacao>(
        `${this.baseUrl}/listagem/classificacao-inscricao/${idConcurso}/${idInscricao}/concluir`,
        null
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
