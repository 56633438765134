/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { ProvaComCriterios } from '../models/prova-com-criterios'
import { ProvaCriterio, ProvaSubCriterio } from '../models/prova-criterio'
import { Concurso } from '../models/concurso'

@Injectable({
  providedIn: 'root',
})
export class ProvaCriterioService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = `${env.baseUrlApi}/prova-criterio`
  }

  listar(tipoProva: string, idConcurso: number | string): Observable<ProvaComCriterios> {
    return this.http
      .get<ProvaComCriterios>(`${this.baseUrl}/${tipoProva}/${idConcurso}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  gravar(tipoProva: string, idConcurso: number | string, criterios: ProvaCriterio[]): Observable<ProvaCriterio[]> {
    return this.http
      .post<ProvaCriterio[]>(`${this.baseUrl}/${tipoProva}/${idConcurso}`, criterios)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  adicionarCriterio(
    tipoProva: string,
    idConcurso: number | string,
    criterio: ProvaCriterio
  ): Observable<ProvaCriterio[]> {
    return this.http
      .post<ProvaCriterio[]>(`${this.baseUrl}/${tipoProva}/${idConcurso}/criterio`, criterio)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  adicionarSubCriterio(
    tipoProva: string,
    idConcurso: number | string,
    idCriterio: number | string,
    subCriterio: ProvaSubCriterio
  ): Observable<ProvaCriterio[]> {
    return this.http
      .post<ProvaCriterio[]>(
        `${this.baseUrl}/${tipoProva}/${idConcurso}/criterio/${idCriterio}/subcriterio`,
        subCriterio
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  atualizarCriterio(
    tipoProva: string,
    idConcurso: number | string,
    criterio: ProvaCriterio
  ): Observable<ProvaCriterio[]> {
    return this.http
      .patch<ProvaCriterio[]>(`${this.baseUrl}/${tipoProva}/${idConcurso}/criterio`, criterio)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  atualizarSubCriterio(
    tipoProva: string,
    idConcurso: number | string,
    idCriterio: number | string,
    subCriterio: ProvaSubCriterio
  ): Observable<ProvaCriterio[]> {
    return this.http
      .patch<ProvaCriterio[]>(
        `${this.baseUrl}/${tipoProva}/${idConcurso}/criterio/${idCriterio}/subcriterio`,
        subCriterio
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  reposicionarCriterio(
    tipoProva: string,
    idConcurso: number | string,
    previousIndex: number,
    currentIndex: number
  ): Observable<ProvaCriterio[]> {
    return this.http
      .put<ProvaCriterio[]>(
        `${this.baseUrl}/${tipoProva}/${idConcurso}/criterio/reposicionar/${previousIndex}/${currentIndex}`,
        {}
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  reposicionarSubCriterio(
    tipoProva: string,
    idConcurso: number | string,
    idCriterio: number | string,
    previousIndex: number,
    currentIndex: number
  ): Observable<ProvaCriterio[]> {
    return this.http
      .put<ProvaCriterio[]>(
        `${this.baseUrl}/${tipoProva}/${idConcurso}/criterio/${idCriterio}/subcriterio/${previousIndex}/${currentIndex}`,
        {}
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  excluirCriterio(
    tipoProva: string,
    idConcurso: number | string,
    idCriterio: number | string
  ): Observable<ProvaCriterio[]> {
    return this.http
      .delete<ProvaCriterio[]>(`${this.baseUrl}/${tipoProva}/${idConcurso}/criterio/${idCriterio}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  excluirSubCriterio(
    tipoProva: string,
    idConcurso: number | string,
    idSubCriterio: number | string
  ): Observable<ProvaCriterio[]> {
    return this.http
      .delete<ProvaCriterio[]>(`${this.baseUrl}/${tipoProva}/${idConcurso}/subcriterio/${idSubCriterio}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  clonar(tipoProva: string, idConcurso: number | string, idConcursoRef: number | string): Observable<ProvaCriterio[]> {
    return this.http
      .post<ProvaCriterio[]>(`${this.baseUrl}/${tipoProva}/${idConcurso}/${idConcursoRef}/copiar-requisitos`, null)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listaDeConcursosPorProvaVersao(tipoProva: string): Observable<Concurso[]> {
    return this.http.get<Concurso[]>(`${this.baseUrl}/${tipoProva}/concursos`)
  }

  gerarFichaDeAvaliacao(tipoProva: string, idConcurso: number): void {
    let headers = new HttpHeaders()
    headers = headers.set('Accept', 'application/pdf')
    this.http
      .get<any>(`${this.baseUrl}/${tipoProva}/${idConcurso}/ficha-de-avaliacao`, {
        headers: headers,
        responseType: 'blob' as 'json',
        observe: 'response',
      })
      .pipe(identity, this.errorHandlerService.catchError())
      .subscribe(res => {
        let url = window.URL.createObjectURL(res.body)
        let downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.download = `${idConcurso}_prova_${tipoProva.toLowerCase()}`
        downloadLink.click()
        window.URL.revokeObjectURL(url)
        downloadLink.remove()
      })
  }
}
