/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { CanDeactivate } from '@angular/router'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'

export interface IPendingChanges {
  hasPendingChanges: () => boolean | Observable<boolean>
  pendingChangesMsg: () => string
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<IPendingChanges> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: IPendingChanges): boolean | Observable<boolean> {
    return component.hasPendingChanges()
      ? this.dialog
          .open(ConfirmDialogComponent, {
            data: new ConfirmDialogModel('Alterações Pendentes', component.pendingChangesMsg()),
          })
          .afterClosed()
      : true
  }
}
