/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-ajuda-suporte',
  templateUrl: './ajuda-suporte.component.html',
  styleUrls: ['./ajuda-suporte.component.css'],
})
export class AjudaSuporteComponent implements OnInit {
  @Input() trustedUrl!: SafeUrl
  iframeHeight: number = 200

  constructor(private sanitizer: DomSanitizer) {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.servicedesk)
  }

  ngOnInit(): void {
    this.iframeHeight = window.innerHeight - 180
  }
}
