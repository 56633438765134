<h1>Formulário Complementar</h1>
<mat-card>
  <mat-card-content>
    <form fxLayout="column" fxLayoutGap="20px">
      <fieldset fxFlex="100%">
        <legend>{{ adicionar ? 'Novo Formulário' : 'Formulário #' + id }}</legend>
        <div fxLayout="column" fxLayoutGap="5px">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Tipos de Concursos</mat-label>
            <mat-chip-grid #tipoConcursoChipList>
              <mat-chip-option *ngFor="let tipo of tiposDeConcursoSelecionados" (removed)="removeChip(tipo.key)">
                {{ tipo.nome }} <button matChipRemove><mat-icon>cancel</mat-icon></button>
              </mat-chip-option>
              <input
                #tipoInput
                placeholder="Tipos"
                [matChipInputFor]="tipoConcursoChipList"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="blurChipIput($event)"
                [formControl]="matChipInputFC"
                [matAutocomplete]="autoUnidade" />
            </mat-chip-grid>
            <mat-autocomplete autoActiveFirstOption #autoUnidade="matAutocomplete">
              <mat-option *ngFor="let opt of tiposDeConcursoFiltrados | async | keyvalue" [value]="opt.key">
                {{ opt.value }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Título</mat-label>
            <input matInput [formControl]="tituloFC" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Subtítulo</mat-label>
            <input matInput [formControl]="subtituloFC" />
          </mat-form-field>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Etapa</mat-label>
              <mat-select [formControl]="etapaFC">
                <mat-option *ngFor="let enum of formularioEtapa | keyvalue" [value]="enum.key">
                  {{ enum.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Tipo de Campo</mat-label>
              <mat-select [formControl]="tipoDeCampoFC">
                <mat-option *ngFor="let enum of tipoComplemento | keyvalue" [value]="enum.key">
                  {{ enum.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Obrigatório</mat-label>
              <mat-select [formControl]="obrigatorioFC">
                <mat-option value="true">Sim</mat-option>
                <mat-option value="false">Não</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Ativo</mat-label>
              <mat-select [formControl]="ativoFC">
                <mat-option value="true">Sim</mat-option>
                <mat-option value="false">Não</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <button mat-raised-button color="primary" (click)="salvar()">
        <mat-icon>check_circle_outline</mat-icon> Salvar
      </button>
      <button mat-stroked-button color="warn" routerLink="/formulario-complementar">
        <mat-icon>close</mat-icon>Cancelar
      </button>
    </div>
  </mat-card-actions>
</mat-card>
