/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LogEntry } from 'src/app/models/log-entry'

@Component({
  selector: 'app-log-detalhes',
  templateUrl: 'log-detalhes.component.html',
  styleUrls: ['./log-detalhes.component.css'],
})
export class LogDetalhesComponent implements OnInit {
  tipoLog?: 'ERRO' | 'LOGIN' | 'INSERT' | 'UPDATE'
  detalhes: any = {}

  constructor(public dialogRef: MatDialogRef<LogDetalhesComponent>, @Inject(MAT_DIALOG_DATA) public data: LogEntry) {}

  ngOnInit(): void {
    if (this.data.nivel == 'Erro') this.tipoLog = 'ERRO'
    else if (this.data.acao == 'LOGIN') this.tipoLog = 'LOGIN'
    else if (this.data.acao.startsWith('INSERT')) this.tipoLog = 'INSERT'
    else if (this.data.acao.startsWith('DELETE')) this.tipoLog = 'INSERT'
    else if (this.data.acao.startsWith('UPDATE')) this.tipoLog = 'UPDATE'

    this.detalhes = this.data.detalhes
  }

  detalhesJSONIdented(): string {
    return JSON.stringify(this.detalhes, null, 4)
  }
}
