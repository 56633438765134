<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign.gt-md="space-between center">
    <h1>Tipos de Recursos</h1>
    <button mat-raised-button color="primary" routerLink="/tipo-recurso/incluir">
      <mat-icon>add</mat-icon> Adicionar
    </button>
  </div>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="tipoAndamento">
        <th mat-header-cell *matHeaderCellDef>Andamento</th>
        <td mat-cell *matCellDef="let row">{{ row.tipoAndamento }}</td>
      </ng-container>
      <ng-container matColumnDef="tipoRecurso">
        <th mat-header-cell *matHeaderCellDef>Recurso</th>
        <td mat-cell *matCellDef="let row">{{ row.tipoRecurso }}</td>
      </ng-container>
      <ng-container matColumnDef="tipoConcurso">
        <th mat-header-cell *matHeaderCellDef>Tipo Concurso</th>
        <td mat-cell *matCellDef="let row">{{ row.tipoConcurso }}</td>
      </ng-container>
      <ng-container matColumnDef="prazo">
        <th mat-header-cell *matHeaderCellDef>Prazo</th>
        <td mat-cell *matCellDef="let row">{{ row.prazo }}</td>
      </ng-container>
      <ng-container matColumnDef="categoria">
        <th mat-header-cell *matHeaderCellDef>Responsável</th>
        <td mat-cell *matCellDef="let row">{{ row.categoria }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button color="basic" routerLink="/tipo-recurso/editar/{{ row.id }}" title="Editar">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionExcluir">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button color="warn" (click)="excluirTipoRecurso(row)" title="Excluir">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="20"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
