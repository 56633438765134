/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { OnInit, Component, ViewChild } from '@angular/core'
import { PerfilService } from 'src/app/services/perfil.service'
import { MatTableDataSource } from '@angular/material/table'
import { Perfil } from 'src/app/models/lista-perfil'
import { FormControl } from '@angular/forms'
import { PerfilPorUsuario, PerfisDoUsuario } from 'src/app/models/perfil-por-usuario'
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { AdicionarPerfilComponent } from '../adicionar-perfil/adicionar-perfil.component'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'

@Component({
  selector: 'app-lista-perfil',
  templateUrl: './lista-perfil.component.html',
  styleUrls: ['./lista-perfil.component.scss'],
})
export class ListaPerfilComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  dataSource: MatTableDataSource<any> = new MatTableDataSource()
  perfis: Perfil[] = []
  opcaoPerfilFC: FormControl = new FormControl('')
  identificacao: string = ''

  buscaPerfis = new FormControl('')

  perfisDoUsuario: PerfisDoUsuario[] = []

  colunasGrid: string[] = ['nome', 'email', 'action']
  perfil: string | undefined

  constructor(
    private perfilService: PerfilService,
    public dialog: MatDialog,
    private unespCoreMessageService: UnespCoreMessageService
  ) {}

  ngOnInit() {
    this.listarPerfis()
    this.opcaoPerfilFC.valueChanges.subscribe(value => this.listarUsuarios(value))
  }

  listarUsuarios(value: number) {
    this.perfilService.listaUsuariosDoPerfil(value).subscribe(usuarios => {
      this.dataSource.data = usuarios
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
    })
  }

  excluir(usuarioDoPerfil: PerfilPorUsuario): void {
    let index = this.dataSource.data.indexOf(usuarioDoPerfil)
    const confirmDialog = new ConfirmDialogModel('Atenção', `Confirma a exclusão do usuario #${usuarioDoPerfil.nome}`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.perfilService.excluir(this.opcaoPerfilFC.value as number, usuarioDoPerfil.idUsuario).subscribe(result => {
          let tmp: PerfilPorUsuario[] = this.dataSource.data
          tmp.splice(index, 1)
          this.dataSource.data = tmp
          this.unespCoreMessageService.showMessageSuccess(`Usuário ${usuarioDoPerfil.nome} excluído com sucesso`)
        })
      }
    })
  }

  listarPerfis() {
    this.perfilService.listarPerfil().subscribe(perfil => {
      this.perfis = perfil as Perfil[]
    })
  }

  adicionarUsuarioDialog() {
    const idPerfil = this.opcaoPerfilFC.value

    const dialogRef = this.dialog.open(AdicionarPerfilComponent, { data: { idPerfil } })

    dialogRef.afterClosed().subscribe(() => this.listarUsuarios(idPerfil))
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
}
