/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { PerfilService } from 'src/app/services/perfil.service'
import { ActivatedRoute } from '@angular/router'
import { PerfisDoUsuario } from 'src/app/models/perfil-por-usuario'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'

@Component({
  selector: 'app-perfil-por-usuario',
  templateUrl: './perfil-por-usuario.component.html',
  styleUrls: ['./perfil-por-usuario.component.scss'],
})
export class PerfilPorUsuarioComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  dataSource: MatTableDataSource<PerfisDoUsuario> = new MatTableDataSource()

  colunasGrid: string[] = ['nome', 'descricao']

  identificacao: string = ''

  constructor(private perfilService: PerfilService, private route: ActivatedRoute) {}

  perfisDoUsuario: PerfisDoUsuario[] = []

  listaPerfisDoUsuario(identificacao: string): any {
    this.perfilService.listaPerfisDoUsuario(identificacao).subscribe(perfisDoUsuario => {
      this.dataSource.data = perfisDoUsuario
      return perfisDoUsuario
    })
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.identificacao = params['identificacao']
      if (this.identificacao) {
        this.identificacao = this.identificacao.replace('@unesp.br', '')
        this.perfilService.listaPerfisDoUsuario(this.identificacao).subscribe(obj => {
          this.perfisDoUsuario = obj
          this.dataSource.data = this.perfisDoUsuario
          this.dataSource.sort = this.sort
          this.dataSource.paginator = this.paginator
        })
      }
    })
  }
}
