<div fxLayout="column" fxLayoutGap="20px">
  <h1>Consulta Gerencial</h1>

  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Unidade</mat-label>
    <mat-chip-grid #unidadesChipList>
      <mat-chip-option
        *ngIf="showTodasUnidadesMC && unidadesSelecionadas.length === 0"
        (removed)="removeChip(todasUnidades)">
        {{ todasUnidades.nome }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
      <mat-chip-option *ngFor="let unidade of unidadesSelecionadas" (removed)="removeChip(unidade)">
        {{ unidade.nome }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
      <input
        #unidadeInput
        placeholder="Unidade"
        [matChipInputFor]="unidadesChipList"
        [matChipInputAddOnBlur]="true"
        (focus)="focusChipIput()"
        (matChipInputTokenEnd)="blurChipIput($event)"
        [formControl]="unidadeFC"
        [matAutocomplete]="autoUnidade" />
    </mat-chip-grid>
    <mat-autocomplete autoActiveFirstOption #autoUnidade="matAutocomplete" [displayWith]="nomeUnidade">
      <mat-option *ngFor="let opt of unidadesFiltradas | async" [value]="opt">
        {{ opt.nome }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Data da Inscrição</mat-label>
        <input matInput readonly [matDatepicker]="pickerData" [formControl]="dataInscricaoFC" [max]="dataMaxima" />
        <mat-icon class="danger" matPrefix (click)="clearDate()">close</mat-icon>
        <mat-datepicker-toggle matSuffix [for]="pickerData"></mat-datepicker-toggle>
        <mat-datepicker #pickerData></mat-datepicker>
        <mat-hint>Selecione para filtrar por data.</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>CPF</mat-label>
        <input matInput mask="000.000.000-00" [formControl]="candidatoCpfFC" />
        <mat-hint>CPF somente números.</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" [formControl]="candidatoEmailFC" />
        <mat-hint>Email do candidato.</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
    <button mat-raised-button color="primary" (click)="consultar()">
      <mat-icon>check_circle_outline</mat-icon> Consultar
    </button>
    <button mat-raised-button color="primary" (click)="exportar()"><mat-icon>save_alt</mat-icon> Exportar</button>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="inscricao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="data">Inscrição</th>
        <td mat-cell *matCellDef="let row">{{ row.protocolo }}<br />{{ row.data | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="concurso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Concurso</th>
        <td mat-cell *matCellDef="let row">{{ row.campus }}<br />{{ row.tituloConcurso }}</td>
      </ng-container>
      <ng-container matColumnDef="candidato0">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidato</th>
        <td mat-cell *matCellDef="let row">
          Nome: {{ row.nome }}<br />Nome social: {{ row.nomeSocial }}<br />
          Nascimento: {{ row.dataDeNascimento | date : 'dd/MM/yyyy' }}<br />
          Sexo: {{ row.sexo }}<br />{{ row.canhoto ? 'Canhoto' : 'Destro' }}<br />{{ row.deficiencia }}
        </td>
      </ng-container>
      <ng-container matColumnDef="candidato1">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          Mãe: {{ row.nomeMae }}<br />Pai: {{ row.nomePai }}<br />Profissão: {{ row.profissao }}<br />
          Escolaridade: {{ row.escolaridade }}<br />Estado Civíl: {{ row.estadoCivil }}
        </td>
      </ng-container>
      <ng-container matColumnDef="documentos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="numeroContad">Documentos</th>
        <td mat-cell *matCellDef="let row">
          CPF: {{ row.cpf }}<br />Estrangeiro: {{ row.estrangeiro }}<br />Passaporte: {{ row.passaporte }}<br />
          RNE: {{ row.rne }}<br />RG: {{ row.rg }} {{ row.orgao }}<br />Expedição:
          {{ row.expedicao | date : 'dd/MM/yyyy' }}<br />
          {{ row.cidade }} - {{ row.estado }} - {{ row.pais }}
        </td>
      </ng-container>
      <ng-container matColumnDef="contato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contato</th>
        <td mat-cell *matCellDef="let row">
          Email: {{ row.email }}<br />
          Celular: {{ row.celular }}<br />
          Telefone: {{ row.telefone }}<br />
          Endereço: {{ row.ruaEnd }}, {{ row.numEnd }} {{ row.complementoEnd ? '-' + row.complementoEnd : '' }} -
          {{ row.bairroEnd }} - <br />
          {{ row.cidadeEnd }} - {{ row.estadoEnd }} - {{ row.cep }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">
          <span>Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
